<template>
  <div v-show="listItem.item">
    <div class="row wc-cart-drawer-item-wrapper mr-0">
      <div
        :class="{ 'wc-cart-drawer-item': listItem.item && !listItem.item.image }"
        class="cart-item-image ml-3 col-3"
        v-if="listItem.item"
      >
        <router-link class="wc-item-image d-flex" :to="`/i/${listItem.item.id}`">
          <WCImage
            :imageSrc="getProductImage(listItem.item.image)"
            :imageAlt="productImgAltText(listItem.item.name, listItem.item.brand)"
            imageClass="wc-item-image mx-auto"
            defaultClass="wc-item-image w-100 h-100"
            :defaultTitle="noProductImageAltText(listItem.item.name)"
          />
        </router-link>
      </div>
      <div class="wc-cart-items-details__desktop w-100 col-9 row">
        <div class="  col-9 px-0">
          <router-link
            v-if="listItem.item"
            :to="`/i/${listItem.item.id}`"
            class="text-dark underline-link-from-center"
          >
            {{ listItem.item.name }}
          </router-link>
          <WCLinkedItems
            v-if="listItem.variation && listItem.variation.modifiers"
            :listItem="listItem"
          />
        </div>
        <div class="save-for-list-price col-3 pr-0">
          <WCProductPricing
            class="pr-5"
            v-if="listItem.item && !listItem.variation"
            :item="listItem.item"
          ></WCProductPricing>
          <span class="font-weight-bold" v-else> {{ mtoItemPrice | currency }}</span>
        </div>
      </div>
    </div>

    <div class="save-for-list-btn-wrap d-flex justify-content-end">
      <button
        class="btn btn-link underline-link-from-center wc-remove-item__button link-primary text-body"
        @click="remove(saveForLaterList, listItem.item)"
      >
        <font-awesome-icon class="mr-1" icon="trash" /> {{ $t('removeFromList') }}
      </button>

      <WCBasicAddToCartButton
        :item="listItem.item"
        :variation="variation"
        :showMobileCustomize="true"
        :isListItem="true"
        class="wc-add-to-cart-item__button btn btn-link underline-link-from-center link-primary text-body mr-4"
        @addToCart="itemAddedToCart(saveForLaterList, listItem.item)"
      />
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import WCProductPricing from '@/components/WCProductPricing/WCProductPricing.vue';
import WCSimpleToast from '@/modules/toasts/components/WCSimpleToast/WCSimpleToast.vue';
import ToastService from '@/modules/toasts/services/ToastService';
import WCImage from '@/components/WCImage/WCImage.vue';
import CartItemControls from '@/modules/cart/mixins/CartControls/CartItemControls';
import WCBasicAddToCartButton from '@/modules/cart/components/WCBasicAddToCartButton/WCBasicAddToCartButton.vue';
import ProductImageInfoMixin from '../../../../mixins/ProductImageInfoMixin';
import WCLinkedItems from '../WCLinkedItems/WCLinkedItems.vue';

export default {
  props: {
    listItem: {
      type: Object,
      required: true,
    },
  },
  components: {
    WCProductPricing,
    WCLinkedItems,
    WCImage,
    WCBasicAddToCartButton,
  },
  mixins: [CartItemControls, ProductImageInfoMixin],
  computed: {
    ...mapGetters({
      saveForLaterList: 'lists/getSaveForLaterList',
    }),
    variation() {
      return this.listItem.variation && this.listItem.variation.id
        ? this.listItem.variation.id
        : this.listItem.variation;
    },
    mtoItemPrice() {
      if (this.listItem.item) {
        return this.listItem.variation && this.listItem.variation.priceOfModifiers
          ? this.listItem.variation.priceOfModifiers + this.listItem.item.actualPrice
          : this.listItem.item.actualPrice;
      }
      return 0;
    },
  },
  methods: {
    ...mapActions({
      removeListItem: 'lists/removeListItem',
      loadDetails: 'lists/loadDetails',
    }),

    async remove(list, item) {
      try {
        await this.removeListItem({
          list,
          item,
          variation: this.variation,
        });
        this.loadDetails({ list: this.saveForLaterList });
      } catch (error) {
        ToastService.open(WCSimpleToast, {
          props: {
            variant: 'danger',
            title: 'Error',
            message: error.message,
          },
          timeout: 7000,
        });
      }
    },
    async itemAddedToCart(list, item) {
      try {
        await this.removeListItem({ list, item, variation: this.variation });
        await this.loadDetails({ list: this.saveForLaterList });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

@include media-breakpoint-down(xs) {
  .cart-item-border {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin: $margin-3 0;
  }
}

.wc-cart-items-details__desktop {
  display: flex;
  align-items: center;
}

.wc-cart-drawer-item {
  display: flex;
  gap: 1rem;
  padding: 0 0 $pad-2 0;
  align-items: center;
}

.wc-item-image {
  margin-left: 1rem;
  object-fit: scale-down;
  max-height: 6rem;
  max-width: 6rem;
}

.save-for-list-btn-wrap {
  padding-bottom: 1rem;
}

@include media-breakpoint-between(sm, md) {
  .cart-item-image {
    max-width: 20%;
  }
  .save-for-list-price {
    padding-left: 7rem;
  }
}

@include media-breakpoint-down(sm) {
  .wc-item-image {
    margin-left: 0;
    max-width: 100%;
    height: auto;
  }

  .save-for-list-btn-wrap {
    padding-bottom: 0;
  }

  .wc-cart-drawer-item-wrapper {
    margin-bottom: 1rem;
  }
}
</style>
