<!-- Search Input Group component - for small search boxes -->
<template>
  <div class="wc-search-area" :class="{ 'focusable-area': hasFocusInput }">
    <WCInputGroup>
      <template v-slot:left>
        <button
          class="btn btn-outline search-btn border-dark border-right-0"
          @click="onSearchClickHandler()"
          :aria-label="$t('search')"
        >
          <font-awesome-icon class=" text-primary" :icon="['fa', 'magnifying-glass']" />
        </button>
      </template>
      <template>
        <WCInput
          autocomplete="off"
          class="wc-search-box border-left-0 pr-0 pl-0"
          :id="id"
          :type="type"
          :value="value"
          :class="isClearSearchAvailable && 'border-right-0'"
          ref="searchInputValue"
          :placeholder="placeHolderText"
          :aria-label="ariaLabel"
          v-model="searchValue"
          @focusin="focusInHandler()"
          @focusout="focusOutHandler()"
          @input="onInputHandler()"
          @keydown.enter="onKeyDownHandler()"
        />
      </template>
      <template v-slot:right>
        <button
          class="btn wc-clear-button border border-left-0 border-dark"
          @click="onClickClearIcon"
          v-if="isClearSearchAvailable"
          :aria-label="$t('clear')"
        >
          <font-awesome-icon class=" text-primary" :icon="['fa', 'xmark']" />
        </button>
      </template>
    </WCInputGroup>
  </div>
</template>

<script>
import WCInput from '@/modules/forms/components/WCInput/WCInput.vue';
import WCInputGroup from '@/modules/forms/components/WCInputGroup/WCInputGroup.vue';

export default {
  components: {
    WCInput,
    WCInputGroup,
  },
  props: {
    id: {
      type: String,
    },
    type: {
      type: String,
    },
    value: {
      type: String,
    },
    ariaLabel: {
      type: String,
      default: 'search',
      required: true,
    },
    placeHolderText: {
      type: String,
      default: 'Search',
      required: true,
    },
    hasFocusInput: {
      type: Boolean,
      default: false,
      required: true,
    },
    searchInputValue: {
      type: String,
      default: '',
    },
    updatedSearchInput: {
      type: String,
    },
  },
  emits: ['onSearch', 'updateValue', 'onKeyDown', 'onClear', 'focusIn', 'focusOut'],
  data() {
    return {
      searchValue: this.updatedSearchInput,
    };
  },
  computed: {
    isClearSearchAvailable() {
      if (this.searchValue) return this.searchValue.length > 0;
      return false;
    },
  },
  methods: {
    /**
     * Triggers on clicking search icon
     */
    onSearchClickHandler() {
      this.$emit('onSearch', this.searchValue);
    },
    /**
     * Triggers on input in search box
     */
    onInputHandler() {
      this.$emit('updateValue', this.searchValue);
    },
    /**
     * Triggers on keydown enter event of search box
     */
    onKeyDownHandler() {
      this.$emit('onKeyDown', this.searchValue);
    },
    /**
     * Triggers on clicking clear icon
     */
    onClickClearIcon() {
      this.searchInputValue = '';
      this.searchValue = this.searchInputValue;
      this.$emit('onClear', this.searchValue);
      this.focusInput();
    },
    /**
     * To make the element focus
     */
    focusInput() {
      this.$refs.searchInputValue.$el.focus();
    },
    /**
     * Triggers on focus in
     */
    focusInHandler() {
      this.$emit('focusIn');
    },
    /**
     * Triggers on focus out
     */
    focusOutHandler() {
      this.$emit('focusOut');
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

.wc-search-area {
  .search-btn {
    padding-right: 0.75rem !important;
  }

  .wc-search-box {
    &.form-control:focus {
      box-shadow: none;
    }
  }
}
</style>
