var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('platform-renderer',{scopedSlots:_vm._u([{key:"onMobile",fn:function(){return [_c('WCCarousel',{staticClass:"wc-carousel px-3"},[_c('div',{staticClass:"mobile-category-list nav nav-pills",attrs:{"id":"wc-tab","role":"tablist"}},[_vm._l((_vm.categories),function(category){return _c('WCItemModifierCategory',{key:category.id,staticClass:"wc-category nav-item",class:{
              'wc-category-active': _vm.value.id === category.id,
              'wc-category-inactive': _vm.value.id !== category.id,
            },attrs:{"value":category,"category":category,"selectedAnswers":_vm.getSelectedItemsByCategory(category),"criteriaIconColor":"red"},on:{"categorySelected":function($event){return _vm.categorySelected(category)}}})}),(_vm.itemModifiers.linkedItems)?_c('WCItemModifierLinkedItems',{staticClass:"wc-category nav-item",class:{
              'wc-category-active': _vm.value.id === 1,
              'wc-category-inactive': _vm.value.id !== 1,
            },attrs:{"linkedItems":_vm.itemModifiers.linkedItems},on:{"linkedItemsSelected":_vm.linkedItemsSelected}}):_vm._e()],2)])]},proxy:true},{key:"onDesktop",fn:function(){return [_c('transition-group',{staticClass:"category-list nav nav-pills flex-column p-2 text-white",attrs:{"name":"wc-category-list","tag":"div","id":"wc-tab","role":"tablist","aria-orientation":"vertical","aria-label":_vm.$t('customizeYourItem', { itemName: _vm.itemName })}},[_c('WCItemModifierQuantity',{key:"modifier-quantity",staticClass:"wc-category mb-3 nav-item",class:{
            'wc-category-active': _vm.value.id === -2,
            'wc-category-inactive': _vm.value.id !== -2,
          },attrs:{"item":_vm.item,"aria-selected":_vm.value.id === -2 ? 'true' : 'false',"id":"modifier-quantity-tab","aria-controls":"modifier-quantity-tabpanel"},on:{"modifierQuantitySelected":_vm.modifierQuantitySelected}}),_vm._l((_vm.categories),function(category){return _c('WCItemModifierCategory',{key:category.id,staticClass:"wc-category mb-3 nav-item",class:{
            'wc-category-active': _vm.value.id === category.id,
            'wc-category-inactive': _vm.value.id !== category.id,
          },attrs:{"value":category,"category":category,"selectedAnswers":_vm.getSelectedItemsByCategory(category),"id":_vm.constructCategoryId(category.name, category.id) + 'tab',"aria-selected":_vm.value.id === category.id ? 'true' : 'false',"aria-controls":_vm.constructCategoryId(category.name, category.id) + 'tabpanel'},on:{"categorySelected":function($event){return _vm.categorySelected(category)}}})}),(_vm.itemModifiers.linkedItems)?_c('WCItemModifierLinkedItems',{key:_vm.itemModifiers.linkedItems.id,staticClass:"wc-category mb-3 nav-item",class:{
            'wc-category-active': _vm.value.id === 1,
            'wc-category-inactive': _vm.value.id !== 1,
          },attrs:{"linkedItems":_vm.itemModifiers.linkedItems,"id":_vm.constructCategoryId(_vm.itemModifiers.linkedItems.name, _vm.itemModifiers.linkedItems.id) +
              'tab',"aria-selected":_vm.value.id === 1 ? 'true' : 'false',"aria-controls":_vm.constructCategoryId(_vm.itemModifiers.linkedItems.name, _vm.itemModifiers.linkedItems.id) +
              'tabpanel'},on:{"linkedItemsSelected":_vm.linkedItemsSelected}}):_vm._e(),(_vm.isInstructionsPerItem)?_c('WCItemModifierInstructions',{key:"special-instructions",staticClass:"wc-category nav-item",class:{
            'wc-category-active': _vm.value.id === -1,
            'wc-category-inactive': _vm.value.id !== -1,
          },attrs:{"aria-selected":_vm.value.id === -1 ? 'true' : 'false',"id":"special-instructions-tab","aria-controls":"special-instructions-tabpanel"},on:{"specialInstructionsSelected":_vm.specialInstructionsSelected}}):_vm._e()],2)]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }