<template>
  <div class="modal-root">
    <WCSpinner v-if="loader" position="center" size="xl" />
    <WCModal v-else position="center">
      <WCModalHeader class="text-primary">
        <h3 class="mb-0">
          {{ $t('AcceleratedCheckOutVerifyStore') }}
        </h3>
      </WCModalHeader>
      <WCModalBody class="d-flex flex-column">
        <h3 class="m-0 text-primary">
          {{ $t('selectStore') }}
        </h3>
        <!-- Store Select -->
        <WCSelect v-model="selected" class="mt-2 mb-2 border-primary">
          <option v-for="store in stores" :key="store.id" :selected="store" :value="store">
            {{ store.name }}
          </option>
        </WCSelect>
        <span class="text-small text-center">{{ $t('enterStoreLocation') }}</span>
      </WCModalBody>
      <WCModalFooter class="space-evenly">
        <button class="btn btn-light" @click="exitModal" ref="exitButtonRef">
          {{ $t('cancel') }}
        </button>
        <button class="btn btn-primary" @click="goToScanPage">
          {{ $t('confirm') }}
        </button>
      </WCModalFooter>
    </WCModal>
  </div>
</template>

<script>
import axios from 'axios';
import WCModal from '@/modules/modals/components/WCModal/WCModal.vue';
import WCModalHeader from '@/modules/modals/components/WCModalHeader/WCModalHeader.vue';
import WCModalBody from '@/modules/modals/components/WCModalBody/WCModalBody.vue';
import WCModalFooter from '@/modules/modals/components/WCModalFooter/WCModalFooter.vue';
import WCSpinner from '@/components/WCSpinner/WCSpinner.vue';
import MapService from '@/services/map.service';
import ModalMixin from '@/modules/modals/mixins/ModalMixin';
import WCSelect from '@/modules/forms/components/WCSelect/WCSelect.vue';

export default {
  name: 'ScanConfirmModal',
  mixins: [ModalMixin],
  components: { WCModal, WCModalHeader, WCSelect, WCSpinner, WCModalBody, WCModalFooter },
  async mounted() {
    try {
      this.loader = true;

      // Loading stores.
      const storesResponse = await axios.get('api/stores').catch(err => console.log(err));
      this.stores = storesResponse.data.data;
      this.selected = this.currentStore;

      // Initalizing mapService
      this.mapService = new MapService();

      if (this.mapService && this.stores.some(store => store.latitude && store.longitude)) {
        this.mapService
          .load()
          .then(() => {
            // Finding latitude and longitude of user.
            MapService.geolocate()
              .then(location => {
                const origin = {
                  latitude: location.latitude,
                  longitude: location.longitude,
                };

                // Appending position to each store.
                this.stores = this.stores.map(store => {
                  if (store.latitude && store.longitude) {
                    const position = new google.maps.LatLng({
                      lat: store.latitude,
                      lng: store.longitude,
                    });
                    const marker = new google.maps.Marker({
                      position,
                    });

                    return { ...store, marker };
                  }
                  return store;
                });

                this.stores.forEach(store => {
                  // Appending distance from user to store to distance attribute.
                  if (store.marker) {
                    const distance = MapService.distance(origin, {
                      latitude: store.marker.getPosition().lat(),
                      longitude: store.marker.getPosition().lng(),
                    });
                    this.$set(store, 'distance', distance);
                  } else {
                    // Stores with no location data will appear at end of list.
                    this.$set(store, 'distance', this.MAX_DISTANCE);
                  }
                  // Setting the selected store on the dropdown to be the current store.
                  if (store.id === this.currentStore.id) {
                    this.selected = store;
                  }
                });
                // Sort stores by distance.
                this.stores.sort((a, b) => a.distance - b.distance);
                this.loader = false;
              })
              .catch(err => {
                console.log(err);
                this.loader = false;
              });
          })
          .catch(err => {
            console.log(err);
            this.loader = false;
          });
      }

      this.$refs.exitButtonRef?.focus();
      this.loader = false;
    } catch (err) {
      this.loader = false;
      console.log(err);
    }
  },
  async beforeDestroy() {
    await this.mapService.unload();
  },
  data() {
    return {
      stores: [],
      currentStore: this.$configuration.store,
      selected: {},
      mapService: {},
      map: {},
      loader: true,
      MAX_DISTANCE: 40000000, // Distance greater than the max distance between two latitude and longitude coordinates in meters.
    };
  },
  methods: {
    exitModal() {
      this.close();
    },
    goToScanPage() {
      this.close();

      // If the selection is the current store.
      if (this.currentStore.id === this.selected.id) {
        this.$router.push({
          name: 'Scan Products',
        });
      } else {
        // Go to scan page after reloading new site.
        window.location.href = `/s/${this.selected.id}/scan`;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

.modal-root {
  position: fixed;
  top: 0;
  right: 0;
  z-index: map-get($zindex, 'modals');
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.2);
}

.border-primary {
  border: 2px solid var(--primary, $primary);
}

.space-evenly {
  display: flex;
  justify-content: space-evenly;
}

.btn {
  width: 40%;
}

.text-small {
  font-size: 70%;
  justify-content: center;
}
</style>
