<template>
  <input
    class="form-control"
    :class="classes"
    :value="_model"
    v-updateInput="_model"
    v-on="listeners"
    v-bind="$attrs"
  />
</template>

<script>
import { updateInput } from '@/utils/directives';

export default {
  name: 'WCInput',
  inject: {
    field: { default: undefined },
  },
  props: {
    value: {},
    size: {
      type: String,
      validator: value => ['small', 'default', 'large'].includes(value),
      default: 'default',
    },
    state: {
      type: String,
      validator: value => ['valid', 'invalid'].includes(value),
      default: null,
    },
  },
  directives: {
    updateInput,
  },
  computed: {
    listeners() {
      const { input, ...listeners } = this.$listeners;
      return listeners;
    },
    _model: {
      get() {
        return this.field ? this.field.$model : this.value;
      },
      set(value) {
        if (this.field) {
          this.field.$model = value;
        }

        this.$emit('input', value);
      },
    },
    _state() {
      if (this.field && this.field.$error) {
        return 'invalid';
      }

      return this.state;
    },
    classes() {
      const classes = [];

      switch (this.size) {
        case 'small':
          classes.push('form-control-sm');
          break;
        case 'large':
          classes.push('form-control-lg');
          break;
        default:
          break;
      }

      switch (this._state) {
        case 'valid':
          classes.push('is-valid');
          break;
        case 'invalid':
          classes.push('is-invalid');
          break;
        default:
          break;
      }

      return classes;
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/assets/styles/_tools.scss';
@import '~@/assets/styles/_settings.scss';

input,
.form-control:focus {
  border: 1px solid var(--dark, $dark);
}
</style>
