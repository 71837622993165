<template>
  <div class="card wc-item-mod-item" :class="{ 'wc-item-mod-item--active': selected }">
    <platform-renderer>
      <template v-slot:onMobile>
        <!-- START : Mobile Item Modifier Items -->
        <div class="d-flex flex-column justify-content-between h-100">
          <!-- START : Item Top Section -->
          <div>
            <!-- START : Item Selection Qty Adjustor -->
            <WCItemModifierItemQuantityAdjuster
              class="wc-item-mod-item__qty-adjustor card-text mt-auto"
              v-model="activeQuantityDescriptor"
              :currentAnswer="currentAnswers[item.id]"
              :item="item"
              :isSelected="selected"
              :prohibitAlterations="prohibitAlterations"
              @toggleModifier="toggle"
            />
            <!-- END: Item Selection Qty Adjustor -->

            <!-- START : Item Modifier Image -->
            <div
              class="wc-item-image-wrapper"
              :class="{ 'wc-item-image-wrapper--active': selected }"
            >
              <WCImage
                :imageSrc="getProductImage(item.image)"
                :imageAlt="productImgAltText(item.name, item.brand)"
                imageClass="wc-item-image"
                defaultClass="wc-item-image font-size-6xl px-4"
                :defaultTitle="noProductImageAltText(item.name)"
              />
            </div>
            <!-- END : Item Modifier Image -->
            <!-- START : Item Modifier Name Wrap -->
            <div class="wc-item-name-wrapper clearfix p-1">
              <div class="small card-text font-weight-bold text-break">
                {{ item.name }}
              </div>
              <div class="small text-muted card-text">
                +{{ overQuantity && selected ? extendedDisplayPrice : extendedPrice | currency }}
                {{ !prohibitAlterations ? selectableQtyValue : '' }}
              </div>
            </div>
            <!-- END : Item Modifier Name Wrap -->
          </div>
          <!-- END : Item Top Section -->
          <!-- START : Prohibite Alternate Message -->
          <WCProhibitAlternativeMsg
            v-if="prohibitAlterations"
            class="justify-content-center mb-1"
          />
          <!-- END : Prohibite Alternate Message -->
        </div>
        <!-- END : Mobile Item Modifier Items -->
      </template>
      <template v-slot:onDesktop>
        <!-- START : Desktop Item Modifier Items -->
        <div class="d-flex flex-column justify-content-between h-100">
          <!-- START : Item Selection Btn -->
          <button
            role="checkbox"
            :aria-checked="selected"
            class="unstyled-btn p-0 w-100"
            :disabled="prohibitAlterations"
            @click="toggle"
          >
            <!-- START : Item Selection Indication Checkbox Icon -->
            <font-awesome-icon
              v-if="selected"
              icon="square-check"
              class="wc-check-box fa-lg align-self-start text-primary"
              :title="$t('selected')"
            />
            <!-- END : Item Selection Indication Checkbox Icon -->
            <!-- START : Item Image Wrap -->
            <div
              class="wc-item-image-wrapper p-1 d-flex align-items-center"
              :class="{ 'wc-item-image-wrapper--active': selected }"
            >
              <WCImage
                :imageSrc="getProductImage(item.image)"
                :imageAlt="productImgAltText(item.name, item.brand)"
                imageClass="wc-item-image"
                defaultClass="wc-item-image font-size-6xl"
                :defaultTitle="noProductImageAltText(item.name)"
              />
            </div>
            <!-- END : Item Image Wrap -->
            <!-- START : Item Details Wrap -->
            <div class="mb-3">
              <div class="wc-item-name-wrapper clearfix p-1">
                <span class="small float-left card-text font-weight-bold pr-1 text-break">
                  {{ item.name }}
                </span>
                <span class="small float-right text-muted card-text">
                  +{{ overQuantity && selected ? extendedDisplayPrice : extendedPrice | currency }}
                </span>
              </div>
              <div v-if="selectableQtyValue" class="d-flex justify-content-end mr-1">
                <span class="small float-right text-muted card-text">
                  {{ selectableQtyValue }}
                </span>
              </div>
            </div>
            <!-- END : Item Details Wrap -->
          </button>
          <!-- END : Item Selection Btn -->
          <!-- START : Item Bottom Section -->
          <div>
            <!-- START : Prohibite Alternate Message -->
            <WCProhibitAlternativeMsg
              v-if="prohibitAlterations"
              class="justify-content-center mb-1"
            />
            <!-- END : Prohibite Alternate Message -->
            <!-- START : Item Qty Adjustor -->
            <WCItemModifierItemQuantityAdjuster
              v-model="activeQuantityDescriptor"
              v-if="selected"
              :currentAnswer="currentAnswers[item.id]"
              :item="item"
              :isSelected="selected"
              :prohibitAlterations="prohibitAlterations"
            />
            <!-- END : Item Qty Adjustor -->
          </div>
          <!-- END : Item Bottom Section -->
        </div>
        <!-- END : Desktop Item Modifier Items -->
      </template>
    </platform-renderer>
  </div>
</template>

<script>
import PlatformRenderer from '@/modules/platform/components/PlatformRenderer';
import WCItemModifierItemQuantityAdjuster from '@/modules/itemModifiers/components/WCItemModifierItemQuantityAdjuster/WCItemModifierItemQuantityAdjuster.vue';
import ItemModifierMixin from '@/modules/itemModifiers/mixins/ItemModifierMixin';
import ItemModifierQuantityMixin from '@/modules/itemModifiers/mixins/ItemModifierQuantityMixin';
import WCProhibitAlternativeMsg from '@/modules/itemModifiers/components/WCProhibitAlternativeMsg/WCProhibitAlternativeMsg.vue';
import ProductImageInfoMixin from '@/mixins/ProductImageInfoMixin';
import WCImage from '@/components/WCImage/WCImage.vue';

export default {
  name: 'WCItemModifierItem',
  props: {
    item: {
      required: true,
      type: Object,
    },
    prohibitAlterations: {
      type: Boolean,
    },
    category: {
      type: Object,
      required: true,
    },
  },
  components: {
    WCItemModifierItemQuantityAdjuster,
    PlatformRenderer,
    WCProhibitAlternativeMsg,
    WCImage,
  },
  mixins: [ItemModifierMixin, ItemModifierQuantityMixin, ProductImageInfoMixin],

  methods: {
    toggle() {
      this.toggleModifier(this.category, this.item);
    },
  },
  computed: {
    selected() {
      return this.currentAnswers[this.item.id] && this.currentAnswers[this.item.id].toggled;
    },
    /**
     * Computed property to checck if the item quantity is more than free quantity & minimum quantity
     */
    isItemOverFreeQty() {
      return (
        this.currentAnswers &&
        this.currentAnswers[this.item.id] &&
        this.currentAnswers[this.item.id].quantity &&
        this.currentAnswers[this.item.id].quantity > this.item.freeQuantity &&
        this.currentAnswers[this.item.id].quantity > this.item.minimum
      );
    },
    /**
     * Computed Property to check if the default quantity item is more than free quantity & minimum quantity
     */
    overQuantity() {
      let value = false;
      if (this.isItemOverFreeQty) {
        this.defaultAnswers.forEach(i => {
          if (i.id === this.item.id) {
            value = true;
          }
        });
      }
      return value;
    },
    /**
     * Computed Property to hold price if default quantity item is more than free quantity & minimum quantity
     */
    displayPrice() {
      let value = 0;
      if (this.isItemOverFreeQty) {
        this.defaultAnswers.forEach(i => {
          if (i.id === this.item.id) {
            value = i.priceModifier;
          }
        });
      }
      return value;
    },
    /**
     * Computed Property to get the current price of the item modifier item based on quantity from the vuex state
     */
    price() {
      let value = 0;
      if (this.selected) {
        // Selected item
        value =
          ((typeof this.currentAnswers[this.item.id].quantity === 'object' &&
            !this.currentAnswers[this.item.id].quantity.isZero()) ||
            (typeof this.currentAnswers[this.item.id].quantity === 'number' &&
              this.currentAnswers[this.item.id].quantity > 0)) &&
          this.currentAnswers[this.item.id].priceModifier;
      } else if (this.item.quantities && Array.isArray(this.item.quantities)) {
        // Unselected  item
        const quantity =
          this.item.quantities.find(option => option.defaultItem) || this.item.quantities[0];
        value = quantity.price;
      } else if (this.activeQuantityDescriptor && !this.item.freeQuantity) {
        value = this.activeQuantityDescriptor.price;
      } else if (this.item.price && !this.item.freeQuantity) {
        value = this.item.price;
      }

      return value || 0;
    },

    extendedDisplayPrice() {
      return this.displayPrice * this.addOnPriceMultiplier.toNumber();
    },
    extendedPrice() {
      return this.price * this.addOnPriceMultiplier.toNumber();
    },
    /**
     * Computed Property used to render the qty value if the minimum and maximum quantities are same.
     * It is displayed only if the item is selected.
     */
    selectableQtyValue() {
      return this.selected && this.item.minimum === this.item.maximum && this.item.minimum > 1
        ? `(${this.item.minimum} qty)`
        : '';
    },
    /**
     * Computed Property to get currently selected dropdown quantity value
     */
    activeQuantityDescriptor: {
      get() {
        let dropdownValue = null;
        if (this.item.quantities) {
          if (this.currentAnswers[this.item.id]) {
            // To set selected dropdown qty
            dropdownValue = this.item.quantities.find(
              q => q.id === this.currentAnswers[this.item.id].quantityId,
            );
          }
        }
        return dropdownValue;
      },
      set(value) {
        const { quantity, quantityDescriptor } = value;
        if (this.selected && (quantity <= 0 || quantity < this.item.minimum)) {
          this.toggleModifier(this.category, this.item);
        } else {
          this.updateAnswers(this.item, quantity, quantityDescriptor?.id, this.prohibitAlterations);
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

.wc-item-mod-item {
  min-height: 13rem;
  max-width: 10rem;
  white-space: normal;

  &--active {
    border-color: var(--primary, $primary);
  }
}

.wc-item-image-wrapper {
  height: rem(125px);
  border-bottom: 1px solid lightgray;

  &--active {
    border-color: var(--primary, $primary);
  }
}

.wc-item-image {
  display: block;
  object-fit: scale-down;
  max-height: 100%;
  max-width: 100%;
  margin: auto;
  align-self: center;
}

.wc-item-name-wrapper {
  display: grid;
  grid-template-columns: 2fr 1fr;
}

.wc-check-box {
  position: absolute;
  top: 0;
  left: 0;
}

/*----- START : Mobile Item Modifiers Item -----*/
@include media-breakpoint-down(md) {
  .card-deck .card {
    margin-left: 0;
    margin-right: 0;
  }
  .wc-item-mod-item {
    box-shadow: $default-box-shadow;
    min-height: 11rem;
    max-width: unset;

    &__qty-adjustor {
      position: absolute;
      z-index: 1;
      right: 0;
      top: 0;
    }
    &--active {
      box-shadow: 0px 3px 6px var(--primary-lighter-6, get-color('primary', 'lighter-6'));
    }
  }
  .wc-item-image-wrapper {
    margin-top: 2rem;
    border-bottom: none;
    width: 100%;
    height: 6rem;

    .wc-item-image {
      width: 100%;
      height: 100%;
      max-width: unset;
      min-width: unset;
      max-height: unset;
      min-height: unset;
      object-fit: contain;
    }
  }
  .wc-item-name-wrapper {
    display: block;
    text-align: center;
  }
}
/*----- END : Mobile Item Modifiers Item -----*/
</style>
