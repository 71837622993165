<template>
  <PlatformRenderer>
    <template v-slot:onMobile>
      <button
        class="btn btn-primary d-flex justify-content-between align-items-center"
        @click="customize()"
      >
        <div class="ml-2" />
        {{ $t('customize') }}<font-awesome-icon icon="wand-magic-sparkles" class="mx-2" size="sm" />
      </button>
    </template>
  </PlatformRenderer>
</template>

<script>
import CartControlsMixin from '@/modules/cart/mixins/CartControls/CartControls';
import PlatformRenderer from '@/modules/platform/components/PlatformRenderer';

export default {
  components: { PlatformRenderer },
  name: 'WCCustomizeButtonMobile',
  methods: {
    async customize() {
      this.$router.push({
        name: 'Customize Your Item',
        params: { id: this.item.id, name: this.item.name, orderType: this.orderType?.id },
      });
    },
  },
  mixins: [CartControlsMixin],
};
</script>
