/**
 * Directive to update the model variable in the component whenever there is an input event
 */
// eslint-disable-next-line import/prefer-default-export
export const updateInput = {
  bind(el, binding, vnode) {
    // eslint-disable-next-line no-param-reassign
    el.oninput = () => {
      const modelVariable = binding.expression;
      // eslint-disable-next-line no-param-reassign
      vnode.context[modelVariable] = el.value;
    };
  },
};
