<template>
  <WCModal position="center" @dismiss="dismiss('Dismissed')">
    <WCModalHeader @dismiss="dismiss('Dismissed')" class="d-flex align-items-center">
      <h3 class="mb-0">
        <font-awesome-icon icon="bookmark" class="mr-1 font-size-lg" />
        {{ $t('saveAllItemsForLater') }}
      </h3>
      <button class="btn btn-link underline-link-from-center" @click="dismiss('Dismissed')">
        {{ $t('cancel') }}
      </button>
    </WCModalHeader>
    <WCModalBody>
      <p>{{ $t('saveAllItemsToListText') }}</p>
    </WCModalBody>
    <WCModalFooter class="justify-content-between">
      <button @click="clearCartItems()" class="btn btn-outline-dark w-47 font-size-sm">
        {{ $t('noRemoveMyItems') }}
      </button>
      <button @click="saveForLaterList()" class="btn btn-outline-primary w-47 font-size-sm">
        {{ $t('yesMoveMyItems') }}
      </button>
    </WCModalFooter>
  </WCModal>
</template>
<script>
import WCModal from '@/modules/modals/components/WCModal/WCModal.vue';
import WCModalHeader from '@/modules/modals/components/WCModalHeader/WCModalHeader.vue';
import WCModalBody from '@/modules/modals/components/WCModalBody/WCModalBody.vue';
import WCModalFooter from '@/modules/modals/components/WCModalFooter/WCModalFooter.vue';
import ModalMixin from '@/modules/modals/mixins/ModalMixin';
import { mapActions } from 'vuex';
import ToastService from '@/modules/toasts/services/ToastService';
import WCSimpleToast from '@/modules/toasts/components/WCSimpleToast/WCSimpleToast.vue';
import WCAddToListToast from '@/modules/lists/components/WCAddToListToast/WCAddToListToast.vue';
import ItemAddToListMixin from '@/modules/lists/mixins/ItemAddToListMixin';

export default {
  name: 'WCSaveAllItemsForLaterModal',
  mixins: [ModalMixin, ItemAddToListMixin],
  components: { WCModal, WCModalHeader, WCModalBody, WCModalFooter },
  props: {
    lineItems: {
      type: Array,
    },
    carts: {
      type: Array,
    },
    allCarts: {
      type: Boolean,
    },
  },
  methods: {
    ...mapActions({
      removeAllCartItems: 'cart/removeAllCartItems',
      motRemoveAllCartItems: 'cart/motRemoveAllCartItems',
      loadDetails: 'lists/loadDetails',
      getCarts: 'cart/getCarts',
    }),
    /**
     * Method to clear all cart items
     */
    async clearCartItems() {
      if (!this.$configuration.orderTypesEnabled || !this.allCarts) {
        await this.removeAllCartItems();
      } else if (this.$configuration.orderTypesEnabled) {
        await this.motRemoveAllCartItems();
        await this.getCarts();
      }
      await this.close();
    },
    /**
     * Method to add items to shopping list
     */
    async saveForLaterList() {
      try {
        const toListItems = [];
        this.carts?.forEach(cart =>
          cart.lineItems.forEach(item => {
            toListItems.push(item);
          }),
        );
        const list = await this.addItemsToList({
          list: { name: 'Save for later', type: 'SAVE_FOR_LATER' },
          items: this.allCarts ? toListItems : this.lineItems,
        });
        if (this.$configuration.orderTypesEnabled && this.allCarts) {
          this.motRemoveAllCartItems();
        } else {
          this.removeAllCartItems();
        }
        this.loadDetails({ list });
        ToastService.open(WCAddToListToast, {
          props: {
            variant: 'favorite',
            title: this.$t('itemAddedToSaveForLater'),
            list,
            close: {},
            dismiss: {},
          },
          timeout: 2000,
        });
      } catch (error) {
        ToastService.open(WCSimpleToast, {
          props: {
            variant: 'danger',
            title: this.$t('error'),
            message: error.message,
          },
          timeout: 7000,
        });
      } finally {
        await this.getCarts();
        this.close();
      }
    },
  },
};
</script>
<style scoped lang="scss">
.w-47 {
  width: 47% !important;
}
</style>
