<template>
  <div
    class="badge wc-payment-badge p-2 text-truncate font-size-2xs font-weight-normal"
    :ref="`wc-payment-badge__${paymentOption.id}`"
  >
    {{ paymentOption.name }}

    <WCToolTip :target="`wc-payment-badge__${paymentOption.id}`">
      {{ paymentOption.name }}
    </WCToolTip>
  </div>
</template>

<script>
import WCToolTip from '@/components/WCToolTip/WCToolTip.vue';

export default {
  props: {
    paymentOption: {
      required: true,
      type: Object,
    },
  },
  components: {
    WCToolTip,
  },
};
</script>

<style scoped lang="scss">
.wc-payment-badge {
  background-color: var(--secondary-lighter-7, get-color('secondary', 'lighter-7'));
}
</style>
