import Vue from 'vue';

export default new Vue({
  methods: {
    open(component, options = { props: {}, timeout: 0 }) {
      return new Promise((resolve, reject) => {
        this.$emit('menu:open', { component, options, resolve, reject });
      });
    },
  },
});
