<template>
  <WCModal @dismiss="isLoading ? null : dismiss('Dismissed')">
    <WCModalHeader class="d-flex justify-content-between align-items-center">
      <div class="d-flex flex-column">
        <h1 class="mb-1">{{ $t('relatedCoupons') }}</h1>
        <div class="mb-0 font-size-md">{{ item.name }}</div>
      </div>

      <button @click="dismiss('Dismissed')" class="btn btn-link underline-link-from-center">
        {{ $t('close') }}
      </button>
    </WCModalHeader>

    <!-- START: MODAL Body -->
    <WCModalBody class="coupon-detail-body">
      <div class="d-flex mb-2 justify-content-between">
        <div role="list">
          <WCCouponDetails
            v-for="coupon in item.coupons"
            :key="coupon.id"
            :coupon="coupon"
            :dismiss="closeDialog"
            :close="closeDialog"
            role="listitem"
          />
        </div>
      </div>
    </WCModalBody>
    <!-- END: MODAL Body -->
  </WCModal>
</template>

<script>
import WCModal from '@/modules/modals/components/WCModal/WCModal.vue';
import WCModalHeader from '@/modules/modals/components/WCModalHeader/WCModalHeader.vue';
import WCModalBody from '@/modules/modals/components/WCModalBody/WCModalBody.vue';
import ModalMixin from '@/modules/modals/mixins/ModalMixin';
import WCCouponDetails from '@/modules/coupons/WCCouponsModal/WCCouponDetails.vue';
import CouponMixin from '../mixins/CouponMixin';

export default {
  name: 'WCCouponsModal',
  props: {
    item: {
      type: Object,
    },
  },
  mixins: [ModalMixin, CouponMixin],
  components: {
    WCModal,
    WCModalHeader,
    WCModalBody,
    WCCouponDetails,
  },
  created() {
    this.fetchCustomerCoupons();
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {},
  methods: {
    closeDialog() {
      this.dismiss('Dismissed');
    },
  },
  watch: {
    $route: 'Coupon Detail Page',
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';
@include media-breakpoint-down(sm) {
  .save-btn {
    width: 100%;
  }
}

.modal-body {
  .coupon-detail-body {
    overflow-y: scroll;
  }
}

.wc-coupon-detail-image {
  width: 35%;
  height: 35%;
}

.wc-coupon-detail-clip-button {
  max-width: 55%;
}

.wc-coupon-detail__eligible-product-title {
  font-size: 1rem;
  line-height: 1.2;
  margin-top: 0;
}

.wc-coupon-detail__no-products-found {
  font-size: 1.28rem;
  font-weight: 500;
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 0.5rem;
}
</style>
