<template>
  <ul class="wc-item-group-menu nav">
    <template v-if="circularMenu && circularMenu.length">
      <WCItemGroupDropdown
        :group="circularMenuItems"
        @selected="select"
        :showViewAll="false"
        :wcHeaderHeight="wcHeaderHeight"
      />
    </template>

    <template v-if="orderTypesMenu && orderTypesMenu.children">
      <template v-for="orderTypeGroup in orderTypesMenu.children">
        <WCItemGroupDropdown
          :key="orderTypeGroup.id"
          :group="orderTypeGroup"
          :wcHeaderHeight="wcHeaderHeight"
          @selected="orderTypeSelected"
        />
      </template>
    </template>
    <template v-else-if="menu && menu.children">
      <template v-for="group in menu.children">
        <WCItemGroupDropdown
          :key="group.id"
          :group="group"
          @selected="select"
          :wcHeaderHeight="wcHeaderHeight"
        />
      </template>
    </template>
  </ul>
</template>

<script>
import OrderTypesMixin from '@/modules/orderTypes/mixins/OrderTypesMixin';
import WCItemGroupDropdown from './WCItemGroupDropdown.vue';
import ItemGroupMixin from '../../modules/filters/mixins/ItemGroupMixin';

export default {
  name: 'WCItemGroupsMenu',
  components: { WCItemGroupDropdown },
  mixins: [ItemGroupMixin, OrderTypesMixin],
  props: {
    menu: {
      type: Object,
    },
    circularMenu: {
      type: Array,
    },
    name: {
      type: String,
    },
    wcHeaderHeight: {
      required: false,
      type: String,
      default: '0px',
    },
  },
  computed: {
    /**
     * Construct circular menu items object
     */
    circularMenuItems() {
      return {
        name: this.$t('currentSpecials'),
        children: this.circularMenu,
      };
    },
  },
  methods: {
    async orderTypeSelected(selection) {
      // TODO Refactor the WCItemGroupDropdown select() method to emit different events for array vs strings
      let orderTypeId;
      let group;
      if (typeof selection === 'string') {
        orderTypeId = selection;
      } else {
        [orderTypeId, group] = selection;
      }

      await this.setActiveOrderType(this.orderTypeObjectFromId(orderTypeId));
      if (group) {
        this.select(group);
      } else {
        this.navigateToOrderTypeHome(orderTypeId);
      }
    },
  },
};
</script>

<style scoped lang="scss">
ul li:first-child > ::v-deep a {
  padding-left: 0;
}
</style>
