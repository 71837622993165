<template>
  <div class="wc-layout wc-layout--mobile-default">
    <WCMobileHeader class="mobile-header" />
    <WCWebAds
      class="wc-mobile-header-padding"
      v-if="hasWebAds"
      :webAds="webAds"
      :key="this.webAdKey"
    />
    <!-- Based on employee status classes are added dynamically -->
    <div class="container-fluid px-1" :class="bodyClass">
      <WCAlertRoot />
      <router-view />
    </div>
    <WCEmployeeAssociationBanner v-if="isEmployee && isCustomer" />
    <WCMobileNavigation v-else-if="!isEmployee" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import WCAlertRoot from '@/modules/alerts/components/WCAlertRoot/WCAlertRoot.vue';
import WCMobileNavigation from '@/components/WCMobileNavigation/WCMobileNavigation.vue';
import WCMobileHeader from '@/components/WCMobileHeader/WCMobileHeader.vue';
import EmployeeMixin from '@/modules/employee/mixins/EmployeeMixin';
import WCWebAds from '@/components/WCWebAds/WCWebAds.vue';
import WCEmployeeAssociationBanner from '@/modules/employee/components/WCEmployeeAssociationBanner/WCEmployeeAssociationBanner.vue';
import IneligibleCartCheckMixin from '@/mixins/IneligibleCartCheckMixin';

export default {
  name: 'MobileDefault',
  components: {
    WCMobileHeader,
    WCWebAds,
    WCAlertRoot,
    WCMobileNavigation,
    WCEmployeeAssociationBanner,
  },
  mixins: [EmployeeMixin, IneligibleCartCheckMixin],
  mounted() {
    this.setHeaderHeight();
    window.addEventListener('resize', () => {
      this.setHeaderHeight();
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', () => {
      this.setHeaderHeight();
    });
  },
  updated() {
    this.setHeaderHeight();
  },
  methods: {
    setHeaderHeight() {
      const height = this.getHeightOfClass('mobile-header');
      document.documentElement.style.setProperty('--mobile-header-height', `${height}px`);
    },
    getHeightOfClass(className) {
      const element = document.getElementsByClassName(className)[0];
      const positionInfo = element?.getBoundingClientRect();

      return positionInfo?.height;
    },
  },
  computed: {
    ...mapGetters({
      isCustomerModeScan: 'user/isCustomerModeScan',
      cartOrderType: 'cart/getCartOrderType',
      webAds: 'browse/getWebAds',
    }),
    /**
     * Property to check if multistore
     */
    isMultiStore() {
      return this.$configuration.multistore;
    },
    /**
     * Construct classname for layout body
     */
    bodyClass() {
      if (
        (!this.isEmployee || (this.isEmployee && this.isCustomer)) &&
        ((!this.isMultiStore &&
          this.$configuration.orderTypesEnabled &&
          !this.isCustomerModeScan) ||
          (this.isMultiStore && !this.isCustomerModeScan))
      ) {
        return this.hasWebAds ? 'wc-layout__body__web-ads' : 'wc-layout__body';
      }
      return 'wc-employee-layout__body';
    },
    hasWebAds() {
      return this.webAds && this.webAds.length;
    },
    webAdKey() {
      return JSON.stringify(this.webAds); // Needed to force WebAds to reload if new images are required.
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

.wc-layout--mobile-default {
  .wc-layout__body {
    padding-top: $mobile-header-padding-top + 0.6rem;
    padding-bottom: $mobile-body-padding-bottom;
  }
  .wc-layout__body__web-ads {
    padding-top: 0.6rem;
    padding-bottom: $mobile-body-padding-bottom;
  }
  .wc-mobile-header-padding {
    padding-top: var(--mobile-header-height);
  }

  .wc-employee-layout__body {
    padding-top: $mobile-header-padding-top - 2rem;
    padding-bottom: $mobile-body-padding-bottom;
  }
}
</style>
