import { render, staticRenderFns } from "./WCCartDrawerOrderCard.vue?vue&type=template&id=3ae90819&scoped=true&"
import script from "./WCCartDrawerOrderCard.vue?vue&type=script&lang=js&"
export * from "./WCCartDrawerOrderCard.vue?vue&type=script&lang=js&"
import style0 from "./WCCartDrawerOrderCard.vue?vue&type=style&index=0&id=3ae90819&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ae90819",
  null
  
)

export default component.exports