<!-- Side bar menu list item slot and styles -->
<template>
  <li @click="onClickMenuItem">
    <slot></slot>
  </li>
</template>

<script>
import SideMenuService from '../../services/SideMenuService';

export default {
  name: 'WCSideMenuItem',
  methods: {
    /**
     * Method to close the menu drawer if any menu item is clicked
     */
    onClickMenuItem() {
      SideMenuService.close();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

li {
  padding: $pad-3 * 0.75 $pad-3 * 1.125;
  border-top: 1px solid var(--gray-300, $gray-300);
  a {
    display: block;
    width: 100%;
    color: var(--dark, $dark);
  }
  ::v-deep .underline-link-from-center {
    background-image: linear-gradient(var(--dark, $dark), var(--dark, $dark));
  }
  .btn-link,
  a {
    &:hover {
      text-decoration: none;
      ::v-deep .underline-link-from-center {
        background-size: 100% 1px;
      }
    }
  }
}
</style>
