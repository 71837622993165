import { render, staticRenderFns } from "./WCCartDrawerGiftCard.vue?vue&type=template&id=2341ff2e&scoped=true&"
import script from "./WCCartDrawerGiftCard.vue?vue&type=script&lang=js&"
export * from "./WCCartDrawerGiftCard.vue?vue&type=script&lang=js&"
import style0 from "./WCCartDrawerGiftCard.vue?vue&type=style&index=0&id=2341ff2e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2341ff2e",
  null
  
)

export default component.exports