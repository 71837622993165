import CartControls from '@/modules/cart/mixins/CartControls/CartControls';
import ItemModifiersMixin from '@/modules/itemModifiers/mixins/ItemModifierMixin';
import ItemModifierQuantityMixin from '@/modules/itemModifiers/mixins/ItemModifierQuantityMixin';
import { mapActions } from 'vuex';
import { BTN_VARIENT } from '@/modules/lists/components/WCAddToListButton/WCAddToListButton.vue';

export default {
  props: {
    lineItem: {
      type: Object,
    },
    hasAddToList: {
      // To check if add to list button have to be displayed
      type: Boolean,
      default: false,
    },
    favoriteMTOItem: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [CartControls, ItemModifiersMixin, ItemModifierQuantityMixin],
  data() {
    return {
      updated: false,
      instructions: '',
    };
  },
  async created() {
    await this.clearAll();
    if (this.editingItem) {
      await this.modifyItem({ id: this.item.id, variation: this.variation });
      // In customize MTO, instructions are set using textarea
      // In edit MTO, instructions are passed from cart into the modal as a prop
      this.instructions = this.cartInstructions;
    }
    await this.fetchItemModifiers(this.item.id);
  },
  computed: {
    /**
     * Computed Property to hold the add to list btn type
     * @returns String - btnType
     */
    addToListBtnType() {
      return BTN_VARIENT.SOLID;
    },
  },
  methods: {
    ...mapActions({
      fetchItemModifiers: 'items/fetchItemModifiers',
      clearAll: 'items/clearItemModifiers',
      modifyItem: 'items/modifyItem',
    }),
    /**
     * Method is to clear selected values including special instructions
     */
    clearSelected() {
      this.resetSelected();
      this.instructions = this.editingItem ? this.cartInstructions : '';
      this.resetQuantities();
    },
  },
  watch: {
    fetched: {
      handler() {
        if (this.fetched) {
          if (this.itemModifiers?.categories) {
            this.setCategories(this.itemModifiers.categories);
          }

          this.updateDefaultAnswers();
          this.updated = true;
        }
      },
    },
  },
};
