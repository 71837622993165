<template>
  <div>
    <button
      v-on="$listeners"
      :disabled="!allValid"
      class="btn btn-primary btn-block d-flex justify-content-center align-items-center"
      @click="addToCart()"
    >
      <span><!-- Invisible element for flexing center and right --></span>
      <span class="mr-2"> {{ editingItem ? $t('applyChanges') : $t('addToCart') }}</span>
      <font-awesome-icon icon="cart-shopping" size="sm" />
    </button>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import ItemModifierMixin from '@/modules/itemModifiers/mixins/ItemModifierMixin';
import ItemModifierQuantityMixin from '@/modules/itemModifiers/mixins/ItemModifierQuantityMixin';
import CartControls from '@/modules/cart/mixins/CartControls/CartControls';

export default {
  name: 'WCItemModifierAddToCartButton',
  data() {
    return {
      total: {
        type: Number,
      },
    };
  },
  props: {
    item: {},
    lineItem: {},
    editingItem: { type: Boolean },
    instructions: {},
  },
  mixins: [ItemModifierMixin, ItemModifierQuantityMixin, CartControls],
  computed: {
    ...mapGetters({
      getItemAmount: 'items/getItemAmount',
      getItemVariation: 'items/getItemVariation',
    }),
  },
  methods: {
    ...mapActions({
      createVariation: 'items/createVariation',
      setMtoItem: 'cart/setMtoItem',
    }),
    async addToCart() {
      await this.createVariation(this.item.id);

      const mtoVariation = this.getItemVariation;
      this.total = this.getItemAmount;

      const weightedItemQuantity = this.isSoldByWeight(this.item)
        ? this.modifierWeightedQuantity
        : null;

      const mtoLineItem = this.getItemLineItem(this.item, this.lineItem?.variation);

      this.setMtoItem({
        lineItem: this.lineItem,
        item: this.item,
        variation: mtoVariation,
        amount: this.total,
        instructions: this.instructions,
        mtoLineItem,
        orderType: this.orderType,
        quantity: this.modifierQuantity,
        weightedItemQuantity,
      });
    },
  },
};
</script>
