import Vue from 'vue';
import VueRouter from 'vue-router';
import RecaptchaService from '@/modules/recaptcha/services/recaptcha.service';
import axios from 'axios';
import VueMeta from 'vue-meta';
import AnalyticsService from '@/services/analytics.service';
import App from './App.vue';
import setup from './setup';
import './assets/styles/index.scss';
import router from './router';
import { store, SET_CONFIGURATION } from './store';
import ConfigurationService from './services/configuration.service';
import geti18nFromConfig from './i18n/i18n';
import './registerServiceWorker';
import { GoogleOptimizeService } from './services/googleOptimize.service';

Vue.use(VueMeta);
Vue.use(VueRouter);

setup();

axios.interceptors.response.use(async response => {
  if (response.headers['flag-set-cookie']) {
    await store.dispatch('user/setUserFromCookie');
  }
  return response;
});

axios.interceptors.request.use(
  request => {
    // eslint-disable-next-line no-param-reassign
    request.headers.Accept = 'application/vnd.ecrs.webcart-2.0+json;q=1.0, application/json;q=0.9';
    return request;
  },
  error => Promise.reject(error),
);

/**
 * Method to initialize app with configuration
 * @param {Object} configuration
 */
const initializeApp = async configuration => {
  RecaptchaService.configure();
  if (RecaptchaService.isEnabled()) {
    await RecaptchaService.load();
  }
  Vue.prototype.$configuration = configuration;
  AnalyticsService.load(configuration, router);
  if (configuration.googleOptimizeId) {
    new GoogleOptimizeService(configuration.googleOptimizeId).load();
  }
  const i18n = geti18nFromConfig();
  const app = new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
  }).$mount('#app');
  axios.defaults.baseURL = process.env.NODE_ENV === 'production' ? app.$configuration.siteUrl : '/';
  await store.dispatch('user/setUserFromCookie');
};
/**
 * Method to set app configuration
 */
const setAppConfiguration = async () => {
  ConfigurationService.load().then(async configuration => {
    store.commit(SET_CONFIGURATION, configuration);
    await initializeApp(configuration);
  });
};
setAppConfiguration();
