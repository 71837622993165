<template>
  <WCSimpleToast v-bind="this.$props">
    <div class="mb-3" v-html="getSanitizedContent(message)">{{ message }}</div>
    <!-- START : Button Section -->
    <div class="wc-toast__body__btn-section justify-content-between">
      <button
        v-for="btn in btnList"
        :key="btn.actionName"
        class="btn"
        :class="getBtnClass(btn)"
        v-html="getSanitizedContent(btn.text)"
        @click="clickHandler(btn.actionName)"
      >
        {{ btn.text }}
      </button>
    </div>
    <!-- END : Button Section -->
  </WCSimpleToast>
</template>

<script>
import { sanitize } from 'dompurify';
import SimpleToastMixin from '../../mixins/SimpleToastMixin';
import ToastMixin from '../../mixins/ToastMixin';
import WCSimpleToast from '../WCSimpleToast/WCSimpleToast.vue';

/**
 * Available Button Sizes
 */
const BTN_SIZE = {
  SMALL_BTN: 'small-btn' /* To hold three buttons on a row */,
  MEDIUM_BTN: 'medium-btn' /* To hold two buttons on a row */,
  LARGE_BTN: 'large-btn' /* To hold one button on a row */,
};

export default {
  name: 'WcSimpleActionableToast',
  mixins: [ToastMixin, SimpleToastMixin],
  components: { WCSimpleToast },
  props: {
    btnList: {
      /* Buttons Configurations */
      type: Array,
    },
  },
  data() {
    return {
      btnSize: BTN_SIZE.MEDIUM_BTN,
    };
  },
  methods: {
    /**
     * Method to emit dynamic actions based on the button List Information
     * @param {string} actionName - Action event to emit based on the button configurations
     */
    clickHandler(actionName) {
      this.$emit(actionName);
      this.close(actionName); // To resolve action name
    },

    /**
     * Method to get Button Class to style based on corresponding button configuration
     * @param {object} btn - Individual button configuration
     */
    getBtnClass(btn) {
      let btnTypeClass = `${this.btnSize} btn`;
      btnTypeClass += btn.isOutlineBtn ? `-outline` : '';
      btnTypeClass += `-${this.color}`;
      return btnTypeClass;
    },

    /**
     * Method to compute Button Size (SMALL, MEDIUM or LARGE) based on the button texts
     */
    computeBtnSize() {
      const SMALL_BTN_MAX_TEXT_LENGTH = 9;
      const MEDIUM_BTN_MAX_TEXT_LENGTH = 18;
      const btnCount = this.btnList.length;
      const isCountInThree = btnCount % 3 === 0; // Boolean to indicate if the button count is multiple of 3
      const isCountInTwo = btnCount % 2 === 0; // Boolean to indicate if the button count is multiple of 2
      let btnSize = BTN_SIZE.LARGE_BTN;
      if (isCountInTwo || isCountInThree) {
        if (isCountInThree) {
          btnSize = BTN_SIZE.SMALL_BTN; // Set button size to SMALL, If Button count is multiple of three
        } else {
          btnSize = BTN_SIZE.MEDIUM_BTN; // Set button size to MEDIUM, If Button count is multiple of two
        }

        // Iterate the button List and identify the appropriate button size
        // eslint-disable-next-line no-restricted-syntax
        for (const btn of this.btnList) {
          // Check if any button text overflows SMALL btn
          if (btnSize === BTN_SIZE.SMALL_BTN && btn.text.length >= SMALL_BTN_MAX_TEXT_LENGTH) {
            if (isCountInTwo) {
              btnSize = BTN_SIZE.MEDIUM_BTN; // Set button size to MEDIUM, If Button count is multiple of two
            } else {
              btnSize = BTN_SIZE.LARGE_BTN; // Otherwise, Set button size to LARGE
              break;
            }
          }
          // Check if any button text overflows MEDIUM btn
          if (btnSize === BTN_SIZE.MEDIUM_BTN && btn.text.length >= MEDIUM_BTN_MAX_TEXT_LENGTH) {
            btnSize = BTN_SIZE.LARGE_BTN;
            break;
          }
        }
      }
      this.btnSize = btnSize;
    },
    /**
     * Method to get sanitized content to render html on message and text
     */
    getSanitizedContent(text) {
      return sanitize(text);
    },
  },
  created() {
    this.computeBtnSize();
  },
};
</script>

<style lang="scss" scoped>
/*------- START : Actionable Toast Btn Section ------*/
.wc-toast__body__btn-section {
  display: flex;
  gap: 5%;
  flex-wrap: wrap;

  .btn {
    flex: 1;
    margin-top: 10px;
    white-space: nowrap;

    &.small-btn {
      min-width: 30%;
    }
    &.medium-btn {
      min-width: 45%;
    }
    &.large-btn {
      width: 100%;
      flex-basis: 100%;
    }
  }
}
/*------- END : Actionable Toast Btn Section ------*/
</style>
