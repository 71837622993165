import { helpers } from 'vuelidate/lib/validators';
import { PHONE_REGEXP } from '@/constants/RegExpConstants';

/**
 * Verifies a string resembles a valid phone number.
 */
export default helpers.withParams({ type: 'phone' }, value => {
  if (!value) {
    return false;
  }

  // Ensure no invalid characters are used
  if (RegExp(PHONE_REGEXP.FILTER_VALID_CHAR).test(value)) {
    return false;
  }

  // Validate number of digits
  const digits = value.replace(RegExp(PHONE_REGEXP.FILTER_NUMBERS), '');
  if (digits.length === 11) {
    return RegExp(PHONE_REGEXP.US_11DIGITS).test(digits);
  }
  return digits.length === 10;
});
