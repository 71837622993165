import Vue from 'vue';

export default new Vue({
  methods: {
    load(configuration, sessionId) {
      if (configuration.config.signifydKey) {
        if (document.getElementById('sig-api')) {
          if (sessionId === this.getExistingSessionId()) {
            return;
          }
          this.removeSignifydScript();
        }
        this.loadSignifydScript(sessionId);
      }
    },
    loadSignifydScript(sessionId) {
      const script = document.createElement('script');
      script.defer = true;
      script.type = 'text/javascript';
      script.id = 'sig-api';
      script.setAttribute('data-order-session-id', sessionId);
      script.src = `https://cdn-scripts.signifyd.com/api/script-tag.js`;
      document.head.appendChild(script);
    },
    removeSignifydScript() {
      const script = document.getElementById('sig-api');
      script.parentNode.removeChild(script);
    },
    getExistingSessionId() {
      return document.getElementById('sig-api').getAttribute('data-order-session-id');
    },
  },
});
