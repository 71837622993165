// Default product image placeholder
export const DEFAULT_PRODUCT_IMG = 'https://via.placeholder.com/150';

// Default Debounce Timelimit
export const DEFAULT_DEBOUNCE_TIME_LIMIT = 500;

// Search Filter Category items max limit
export const FILTER_ITEMS_MAX_LIMIT = 20;

// Search Filter Category item name - characters max limit for a long word
export const MAX_CHAR_LENGTH = 18;

// PWA Constants
export const PWA_CONSTANTS = {
  EXPIRY_DATE: 30,
  COOKIE_NAME: 'add-to-home-screen',
};

// Key Code Constants
export const KEYBOARD_CONSTANTS = {
  ARROW_LEFT: 37,
  ARROW_RIGHT: 39,
  ENTER: 13,
  HOME: 36,
  END: 35,
  TABINDEX_FOCUS: '0',
  TABINDEX_NO_FOCUS: '1',
};

// credit card
export const CREDIT_CARDS = [
  'visa',
  'stripe',
  'paypal',
  'mastercard',
  'jcb',
  'discover',
  'dinersclub',
  'applepay',
  'amex',
  'amazonpay',
];

// See Transaction Server TenderType class
export const PAYMENT_METHOD_TYPES = {
  PAY_LATER: -1,
  CASH: 1,
  CHARGE_ACCOUNT: 2,
  CHECK: 3,
  CREDIT: 4,
  SNAP: 5,
  EBT_SNAP: 6,
  COUPON: 7,
  DEBIT: 8,
  ODYSSEY: 9,
  FOREIGN_CURRENCY: 10,
  WIC: 11,
  EBT_CASH_BENEFITS: 12,
  ARAMARK_DECLINING_BALANCE: 13,
  GIFT_CARD: 14,
  FSA_DEBIT: 15,
  ECC_CHECK: 16,
  FSA_CREDIT: 17,
  WIC_CVV: 18,
  LOYALTY: 19,
  WIC_EBT: 20,
  THIRD_PARTY_GIFT_CARD: 21,
  DWOLLA: 22,
  TOKENIZATION: 23,
  PAYPAL: 24,
  GOOGLE_WALLET: 25,
  UNITELLER: 26,
  AMMEX_CHARGE: 27,
  INFO_GENESIS_CHARGE: 28,
  ATRIUM_DEBIT: 29,
  ATRIUM_MEAL: 30,
  EBT_OFFLINE_VOUCHER: 31,
  GIFT_CERTIFICATE: 32,
  THIRD_PARTY_CHARGE_ACCOUNT: 33,
};

// Account settings sections
export const ACCOUNT_SETTINGS_ROUTE_CMP_MAP = {
  profile: {
    name: 'Profile Information',
    route: 'profile',
    component: 'WCProfileInfoSectionForm',
  },
  work: {
    name: 'Work Information',
    route: 'work',
    component: 'WCWorkInfoSectionForm',
  },
  other: {
    name: 'Other Information',
    route: 'other',
    component: 'WCOtherInfoSectionForm',
  },
  email: {
    name: 'Email Information',
    route: 'email',
    component: 'WCEmailInfoSectionForm',
  },
  phone: {
    name: 'Phone Information',
    route: 'phone',
    component: 'WCPhoneInfoSectionForm',
  },
  address: {
    name: 'Address Information',
    route: 'address',
    component: 'WCAddressInfoSectionForm',
  },
};

// Account settings form field options
export const ACCOUNT_SETTINGS_OPTIONS = {
  REQUIRED: 'REQUIRED',
  OPTIONAL: 'OPTIONAL',
};

// Map api link
export const MAP_CONSTANTS = {
  GOOGLE_MAP_API: 'https://maps.google.com',
};

// User roles
export const USER_ROLES = {
  EMPLOYEE: 'employee',
  CUSTOMER: 'customer',
  EMPLOYEE_AS_CUSTOMER: 'employeeAsCustomer',
  GUEST: 'guest',
};
