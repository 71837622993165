import Vue from 'vue';
import { SIDE_MENU_EVENTS } from '../../../constants/EventConstants';
/**
 * SideMenu service for open and close of side drawer
 */
export default new Vue({
  methods: {
    open() {
      this.$emit(SIDE_MENU_EVENTS.OPEN);
    },
    close() {
      this.$emit(SIDE_MENU_EVENTS.CLOSE);
    },
    toggle() {
      this.$emit(SIDE_MENU_EVENTS.TOGGLE);
    },
  },
});
