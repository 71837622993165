<template>
  <div class="d-flex flex-row p-3 justify-content-between align-items-center">
    <div class="d-flex flex-row align-items-center">
      <WCImage
        :imageSrc="coupon.imageUrl || ''"
        :imageAlt="coupon.name"
        imageClass="w-25 h-25 p-1"
        defaultClass="w-25 h-25 p-1"
        :defaultTitle="coupon.name"
      />
      <div class="d-flex flex-column">
        <div
          :aria-label="`Coupon ${coupon.name}`"
          class="btn link-dark underline-link-from-center ml-2 mr-2 font-size-xs"
          @click="handleModalSwap"
        >
          {{ couponTitle }}
        </div>
      </div>
    </div>

    <WCCouponClipButton
      class="wc-coupon-card__bottom-button wc-coupon-card__bottom-button-detail"
      :couponId="coupon.id"
      :availableLabel="$t('clipCoupon')"
      :clippedLabel="$t('clippedCoupon')"
      :redeemedLabel="$t('couponRedeemed')"
      :disabled="false"
      :isClipped="evaluateClipStatus"
      :isRedeemed="evaulateRedeemedStatus"
      :handleClip="handleClip"
    />
  </div>
</template>

<script>
import WCImage from '@/components/WCImage/WCImage.vue';
import WCCouponClipButton from '@/components/WCCouponClipButton/WCCouponClipButton.vue';
import ModalMixin from '@/modules/modals/mixins/ModalMixin';
import CouponMixin from '../mixins/CouponMixin';

export default {
  name: 'WCCouponDetails',
  mixins: [ModalMixin, CouponMixin],
  components: {
    WCImage,
    WCCouponClipButton,
  },
  methods: {
    handleModalSwap() {
      this.close();
      this.openMoreInfoModal();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

.wc-coupon-detail__name {
  font-size: 1rem;
}

.wc-coupon-card__bottom-button-detail {
  max-width: 40%;
}
</style>
