<template>
  <div class="wc-item-quantity" v-click-outside="hide" :disabled="isSyncing">
    <label for="wc-quantity-button" class="small" v-if="showTitle">{{ $t('quantity') }}</label>
    <div class="d-flex align-items-center">
      <div class="btn-group" role="group">
        <button
          v-if="!isEmbeddedItem"
          v-show="editMode"
          @click.stop="decrementCombinedQuantities(item, variation)"
          class="wc-quantity-button--minus btn btn__ouline--grey py-1 px-2"
          :aria-label="$t('decrement')"
        >
          <font-awesome-icon icon="minus" />
        </button>
        <button
          @click="quantityClicked"
          :class="{ rounded: !editMode }"
          class="wc-quantity-button btn bg-white border"
        >
          <!-- Screen reader only visible label for quantity and live region for quantity updates -->
          <span class="sr-only" aria-live="assertive"> {{ $t('quantity') }}</span>
          {{ getDisplayForCombinedQuantitiesInCart(item, variation) }}
        </button>
        <button
          v-if="!isEmbeddedItem"
          v-show="editMode"
          @click.stop="incrementCombinedQuantities(item, variation)"
          class="wc-quantity-button--plus btn btn__ouline--grey py-1 px-2"
          :aria-label="$t('increment')"
        >
          <font-awesome-icon icon="plus" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import CartControlsMixin from '@/modules/cart/mixins/CartControls/CartControls';
import ClickOutside from 'vue-click-outside';

export default {
  name: 'WCCartDrawerQuantityAdjuster',
  mixins: [CartControlsMixin],
  directives: { ClickOutside },
  props: {
    isEmbeddedItem: {
      type: Boolean,
      default: false,
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      editMode: false,
    };
  },
  methods: {
    quantityClicked() {
      this.editMode = !this.editMode;
    },
    hide() {
      this.editMode = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/_tools.scss';
@import '~@/assets/styles/_settings.scss';

.wc-item-quantity {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: $pad-2 $pad-3;
  white-space: nowrap;
  margin: 0 $margin-3 * 2;
  width: 6rem;
  place-items: center;
}

.wc-quantity-button {
  width: 5rem;
}

.wc-quantity-button,
.wc-quantity-button--minus,
.wc-quantity-button--plus {
  height: rem(48px);
}

.wc-quantity-button,
.btn__ouline--grey {
  border-color: var(--gray) !important;
}

@include media-breakpoint-down(xs) {
  .wc-item-quantity {
    width: initial;
    margin: $margin-0;
    padding: $pad-2 $pad-0;
  }
}
</style>
