<template>
  <div>
    <WCMobileHeader />
    <div
      class="wc-layout wc-layout--mobile-account-subpage"
      :class="!isMultiStore && !$configuration.orderTypesEnabled && 'wc-layout-small-top-position'"
    >
      <WCMobileSearchHeaderBar />
      <div class="wc-layout__body container-fluid" role="main">
        <div class="">
          <router-view />
        </div>
      </div>
      <WCEmployeeAssociationBanner v-if="isEmployee && isCustomer" />
      <WCMobileNavigation v-else-if="!isEmployee" />
    </div>
  </div>
</template>

<script>
import WCMobileNavigation from '@/components/WCMobileNavigation/WCMobileNavigation.vue';
import WCMobileSearchHeaderBar from '@/modules/mobileSearch/components/WCMobileSearchHeaderBar/WCMobileSearchHeaderBar.vue';
import WCMobileHeader from '@/components/WCMobileHeader/WCMobileHeader.vue';
import EmployeeMixin from '@/modules/employee/mixins/EmployeeMixin';
import WCEmployeeAssociationBanner from '@/modules/employee/components/WCEmployeeAssociationBanner/WCEmployeeAssociationBanner.vue';

export default {
  name: 'MobileSearchHeader',
  components: {
    WCMobileSearchHeaderBar,
    WCMobileHeader,
    WCMobileNavigation,
    WCEmployeeAssociationBanner,
  },
  mixins: [EmployeeMixin],
  computed: {
    /**
     * Property to check if multistore
     */
    isMultiStore() {
      return this.$configuration.multistore;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

.wc-layout--mobile-account-subpage {
  position: absolute;
  top: $mobile-header-padding-top;
  bottom: rem(10px);
  width: 100%;
  overflow-y: scroll;
  max-height: 100%;
  height: rem(1000px);
  .wc-layout__body {
    padding-bottom: $pad-3 * 3.7;
  }
  &.wc-layout-small-top-position {
    top: $mobile-header-padding-top - 2rem;
  }
}
</style>
