<template>
  <div class="d-block">
    <div class="badge badge-primary badge-pill text-wrap">
      {{ `${$t('currentlyShopping')}: ${orderType.name}` }}
    </div>
    <div
      class="mt-1 w-75 col-lg-auto col-md-5 col-sm-6 d-block badge badge-primary badge-pill text-wrap"
      v-if="showOrderTypeCount"
    >
      {{ `${cartsCount} ${cartsCount !== 1 ? $t('orderTypes') : $t('orderType')}` }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'WCCurrentOrderTypeBadge',
  props: {
    orderType: {
      type: Object,
      required: true,
    },
    cartsCount: {
      type: Number,
      default: null,
    },
  },
  computed: {
    showOrderTypeCount() {
      return this.$configuration.cartEnabled && this.cartsCount !== null;
    },
  },
};
</script>
