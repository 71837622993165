<template>
  <div class="wc-cart-drawer-item-wrapper" v-if="soItem.type === 60">
    <div class="wc-cart-drawer-item">
      <div class="img-thumbnail__wrapper wc-item-image font-size-6xl clearfix">
        <font-awesome-icon
          class="text-muted"
          defaultClass="font-size-6xl"
          imageClass="img-thumbnail"
          :icon="defaultIcon"
        />
      </div>
      <platform-renderer>
        <!-- Start : Cart item view - Desktop -->
        <template v-slot:onDesktop>
          <div class="wc-cart-items-details__desktop row no-gutters w-100">
            <div class="col-5 px-0">
              <h3>{{ $t('specialOrderItem') }}</h3>
              <p :id="`order-lineitem_name_${soItem.id}`">
                {{ soItem.instructions }}
              </p>
            </div>
          </div>
        </template>
        <!-- End : Cart item view - Desktop -->

        <!-- Start : Cart item view - Mobile -->
        <template v-slot:onMobile>
          <div class="d-flex flex-column w-75">
            <h3>{{ $t('specialOrderItem') }}</h3>
            <div class="row no-gutters d-sm-none">
              <p :id="`order-lineitem_name_${soItem.id}`">
                {{ soItem.instructions }}
              </p>
            </div>
          </div>
        </template>
        <!-- End : Cart item view - Mobile -->
      </platform-renderer>
    </div>
    <div class="d-flex justify-content-end">
      <WCEditSpecialOrderItemButton
        class="btn btn-link link-primary underline-link-from-center"
        @click="openEditSpecialOrderItemModal($refs.editSpecialOrderBtnRef)"
        ref="editSpecialOrderBtnRef"
        :lineItem="soItem"
      />
      <button
        :onlyIcon="true"
        :lineItem="soItem"
        @click="removeSpecialOrderItem(soItem)"
        class="btn btn-link underline-link-from-center wc-remove-from-cart link-primary"
        iconSize="lg"
      >
        <font-awesome-icon icon="trash" class="icon fa-md svg-inline--fa" :size="iconSize" />
        <span class="ml-2">{{ $t('remove') }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import PlatformRenderer from '@/modules/platform/components/PlatformRenderer';
import ModalService from '@/modules/modals/services/modal.service';
import WCEditSpecialOrderItemModal from '@/modules/checkout/components/WCFinalizeStage/WCEditSpecialOrderItemModal/WCEditSpecialOrderItemModal.vue';
import WCEditSpecialOrderItemButton from '@/modules/checkout/components/WCFinalizeStage/WCEditSpecialOrderItemButton/WCEditSpecialOrderItemButton.vue';

export default {
  name: 'WCCartDrawerSpecialOrderItem',
  components: {
    PlatformRenderer,
    WCEditSpecialOrderItemButton,
  },
  props: {
    soItem: { type: Object },
    defaultIcon: {
      type: String,
      default: 'camera',
    },
    defaultClass: {
      type: String,
    },
    imageClass: {
      type: String,
    },
    iconSize: {
      type: String,
      default: null,
    },
    onlyIcon: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapActions({
      removeSpecialOrderItem: 'cart/removeSpecialOrderItem',
    }),
    async openEditSpecialOrderItemModal(editSpecialOrderBtnRef) {
      try {
        await ModalService.open(WCEditSpecialOrderItemModal, {
          lineItem: this.lineItem,
        });
      } catch (error) {
        // NOOP
      } finally {
        if (editSpecialOrderBtnRef) editSpecialOrderBtnRef.focus();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

.wc-cart-drawer-item-wrapper {
  display: flex;
  flex-direction: column;
}

.wc-cart-drawer-item {
  display: flex;
  gap: 1rem;
  padding: 0 0 $pad-2 0;
}

.wc-cart-items-details__desktop {
  display: flex;
  align-items: center;
}

.wc-item-image {
  object-fit: scale-down;
  width: 30%;
  min-width: rem(50px);
  max-width: rem(110px);
}

@include media-breakpoint-down(xs) {
  .wc-cart-drawer-item {
    gap: 0.5rem;
  }
}
</style>
