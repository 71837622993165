<template>
  <PlatformProvider class="wc-app h-100" v-if="renderApp && !loading && !restricted">
    <component :is="layout" />
    <WCMobileSideBar v-if="isMobile" />
    <WCModalRoot />
    <WCToastRoot />
    <WCCartDrawerRoot />
    <WCMobileContextMenuRoot v-if="isMobile" />
    <WCSpinnerRoot />
  </PlatformProvider>
  <h1 class="error-container" v-else-if="restricted">
    <FeatureNotAvailable :isMobile="isMobile" />
  </h1>
  <h1 v-else-if="!renderApp">
    {{ $t('operaBlockedVersionWarning') }}
  </h1>
</template>

<script>
import { mapActions } from 'vuex';
import WCModalRoot from '@/modules/modals/components/WCModalRoot/WCModalRoot.vue';
import WCToastRoot from '@/modules/toasts/components/WCToastRoot/WCToastRoot.vue';
import WCMobileContextMenuRoot from '@/modules/mobileContextMenu/components/WCMobileContextMenuRoot/WCMobileContextMenuRoot.vue';
import WCCartDrawerRoot from '@/modules/cart/components/WCCartDrawerRoot/WCCartDrawerRoot.vue';
import WCSpinnerRoot from '@/components/WCSpinnerRoot/WCSpinnerRoot.vue';
import { PlatformMixin } from '@/modules/platform';
import PlatformProvider from '@/modules/platform/components/PlatformProvider.vue';
import EmployeeDashboard from '@/layouts/Employee/EmployeeDashboard.vue';
import FeatureNotAvailable from '@/views/Errors/FeatureNotAvailable.vue';
import DesktopDefault from './layouts/DesktopDefault.vue';
import Basic from './layouts/Basic.vue';
import AccountDashboard from './layouts/Account/AccountDashboard.vue';
import MobileDefault from './layouts/MobileDefault.vue';
import HeaderlessMobile from './layouts/HeaderlessMobile.vue';
import MobileAccountSubpage from './layouts/Account/MobileAccountSubpage.vue';
import MobileSearchHeader from './layouts/MobileSearchHeader.vue';
import MobileSubpageHeader from './layouts/MobileSubpageHeader.vue';
import MobileScanView from './layouts/MobileScanView.vue';
import WCMobileSideBar from './modules/sidebar/components/WCMobileSideBar/WCMobileSideBar.vue';
import MetaInfoMixin from './mixins/MetaInfoMixin';
import PwaPromotionalToastMixin from './mixins/PwaPromotionalToast';
import checkIfUnsupportedOpera from './utils/unsupported-browsers';

export default {
  components: {
    PlatformProvider,
    DesktopDefault,
    MobileDefault,
    Basic,
    WCModalRoot,
    FeatureNotAvailable,
    AccountDashboard,
    EmployeeDashboard,
    WCToastRoot,
    WCMobileContextMenuRoot,
    WCCartDrawerRoot,
    HeaderlessMobile,
    MobileAccountSubpage,
    MobileSearchHeader,
    WCMobileSideBar,
    MobileSubpageHeader,
    MobileScanView,
    WCSpinnerRoot,
  },
  mixins: [PlatformMixin, PwaPromotionalToastMixin, MetaInfoMixin],
  data() {
    return {
      loading: true,
      renderApp: true,
    };
  },
  computed: {
    layout() {
      const layout = this.$route.meta.layout;
      if (this.isMobile) {
        return layout && layout.mobile ? layout.mobile.name : MobileDefault.name;
      }

      return layout && layout.desktop ? layout.desktop.name : DesktopDefault.name;
    },
    defaultLayout() {
      return this.isMobile ? MobileDefault.name : DesktopDefault.name;
    },
    restricted() {
      const restricted = this.$route.meta.restricted;
      if (
        (restricted && restricted.desktop && !this.isMobile) ||
        (restricted && restricted.mobile && this.isMobile)
      )
        return true;
      return false;
    },
  },
  methods: {
    ...mapActions({
      reload: 'cart/reload',
      fetchLists: 'lists/fetch',
      getCarts: 'cart/getCarts',
    }),
  },
  async created() {
    await this.reload();
    await this.fetchLists();
    this.renderApp = !checkIfUnsupportedOpera();
    this.loading = false;
  },
};
</script>
<style scoped>
.error-container {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
}
</style>
