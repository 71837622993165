<template>
  <WCModal @dismiss="dismiss('Dismissed')">
    <WCModalHeader @dismiss="dismiss('Dismissed')" class="d-flex align-items-center">
      <h3 class="mb-0">
        <font-awesome-icon icon="cart-xmark" class="mr-1 font-size-lg" />
        {{ $t(localeHeader) }}
      </h3>
      <button
        v-if="hasCancel"
        class="btn btn-link underline-link-from-center"
        @click="dismiss('Dismissed')"
      >
        {{ $t('cancel') }}
      </button>
    </WCModalHeader>
    <WCModalBody>
      <p>{{ $t(localeBody) }}</p>
    </WCModalBody>
    <WCModalFooter class="justify-content-between">
      <button @click="clearCartItems()" class="btn btn-outline-dark w-47 font-size-sm">
        {{ $t('noDeleteMyItems') }}
      </button>
      <button @click="saveToList()" class="btn btn-outline-primary w-47 font-size-sm">
        {{ $t('yesMoveMyItems') }}
      </button>
    </WCModalFooter>
  </WCModal>
</template>
<script>
import WCModal from '@/modules/modals/components/WCModal/WCModal.vue';
import WCModalHeader from '@/modules/modals/components/WCModalHeader/WCModalHeader.vue';
import WCModalBody from '@/modules/modals/components/WCModalBody/WCModalBody.vue';
import WCModalFooter from '@/modules/modals/components/WCModalFooter/WCModalFooter.vue';
import ModalMixin from '@/modules/modals/mixins/ModalMixin';
import { mapActions, mapGetters } from 'vuex';
import modalService from '@/modules/modals/services/modal.service';
import WCAddToListModal from '@/modules/lists/components/WCAddToListModal/WCAddToListModal.vue';
import ItemAddToListMixin from '@/modules/lists/mixins/ItemAddToListMixin';

export default {
  name: 'WCScanModeRemoveCartModal',
  mixins: [ModalMixin, ItemAddToListMixin],
  components: { WCModal, WCModalHeader, WCModalBody, WCModalFooter },
  props: {
    localeHeader: {
      type: String,
      default: 'removeCartAcceleratedCheckOutTitle',
    },
    localeBody: {
      type: String,
      default: 'removeCartAcceleratedCheckOut',
    },
    hasCancel: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      cartLineItems: 'cart/getLineItems',
      carts: 'cart/carts',
    }),
  },
  methods: {
    ...mapActions({
      removeAllCartItems: 'cart/removeAllCartItems',
      motRemoveAllCartItems: 'cart/motRemoveAllCartItems',
      syncCart: 'cart/sync',
    }),
    /**
     * Method to clear all cart items
     */
    async clearCartItems() {
      if (!this.$configuration.orderTypesEnabled) {
        await this.removeAllCartItems();
      } else if (this.$configuration.orderTypesEnabled) {
        await this.motRemoveAllCartItems();
      }
      this.close();
    },
    /**
     * Method to add items to shopping list
     */
    async saveToList() {
      try {
        const toListItems = [];
        this.carts?.forEach(cart =>
          cart.lineItems.forEach(item => {
            toListItems.push(item);
          }),
        );
        await modalService.open(WCAddToListModal, {
          items: this.carts ? toListItems : this.cartLineItems,
        });
      } catch (error) {
        // NOOP
        console.error(error);
      } finally {
        this.clearCartItems();
      }
    },
  },
};
</script>
<style scoped lang="scss">
.w-47 {
  width: 47% !important;
}
</style>
