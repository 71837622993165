<template>
  <PlatformRenderer>
    <!-- Desktop - show customize text and icon -->
    <template v-slot:onDesktop>
      <button
        class="btn justify-content-between align-items-center"
        @click="customize()"
        ref="customizeBtnRef"
      >
        <div class="ml-2" />
        {{ $t('customize') }}
        <font-awesome-icon icon="wand-magic-sparkles" class="ml-2" size="sm" />
      </button>
    </template>
    <!-- Mobile - show customize icon only -->
    <template v-slot:onMobile>
      <button class="unstyled-btn " :aria-label="$t('customize')">
        <font-awesome-icon
          class="btn-link"
          icon="wand-magic-sparkles"
          size="lg"
          ref="customizeBtnRef"
          @click="goToCustomizePage()"
        />
      </button>
    </template>
  </PlatformRenderer>
</template>

<script>
import CartControlsMixin from '@/modules/cart/mixins/CartControls/CartControls';
import ModalService from '@/modules/modals/services/modal.service';
import WCItemModifierRoot from '@/modules/itemModifiers/components/WCItemModifierRoot/WCItemModifierRoot.vue';
import PlatformRenderer from '@/modules/platform/components/PlatformRenderer';

export default {
  components: { PlatformRenderer },
  name: 'WCCustomizeButton',
  methods: {
    async customize() {
      try {
        await ModalService.open(WCItemModifierRoot, {
          item: this.item,
          editingItem: false,
          orderType: this.orderType,
        });
      } catch (error) {
        console.log(error);
      } finally {
        if (this.$refs.customizeBtnRef) this.$refs.customizeBtnRef.focus();
      }
    },
    goToCustomizePage() {
      this.$router.push({
        name: 'Customize Your Item',
        params: { id: this.item.id, name: this.item.name },
      });
    },
  },
  mixins: [CartControlsMixin],
};
</script>

<style scoped></style>
