<template>
  <button
    @click="modifierQuantity()"
    class="unstyled-btn nav-link"
    data-toggle="tab"
    role="tab"
    id="quantity-tab"
  >
    {{ name }}
  </button>
</template>

<script>
import CartControls from '@/modules/cart/mixins/CartControls/CartControls';

export default {
  mixins: [CartControls],
  methods: {
    modifierQuantity() {
      this.$emit('modifierQuantitySelected');
    },
  },
  computed: {
    name() {
      if (this.isSoldByRandomWeight(this.item)) {
        return this.$t('quantitySlashWeight');
      }
      return this.$t('quantity');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

@include media-breakpoint-down(md) {
  .nav-link {
    padding: $pad-3 $pad-1 $pad-1 $pad-1;
  }
}
</style>
