export const RESPONSE_CODE = {
  CODE_SUCCESS: 0,
  CODE_GENERIC_ERROR: -1,
  CODE_INVALID_CUSTOMER: -2,
  CODE_CANNOT_REMOVE_PASSWORD_PROVIDER: -3,
  CODE_CANNOT_REMOVE_LAST_PROVIDER: -4,
  CODE_UNABLE_TO_DELETE: -5,
  CODE_NO_WEBPROFILE: -6,
  CODE_NO_REMOTE: -7,
  CODE_INVALID_ORDER: -8,
  CODE_NO_SUCH_ORDER: -9,
  CODE_INCORRECT_CUSTOMER: -10,
  CODE_NO_RECEIPT_PROFILE: -11,
  CODE_VALIDATION_ERROR: -12,
  CODE_INVALID_EMPLOYEE: -13,
  CODE_TWO_FACTOR_REQUIRED: -14,
  CODE_DISABLED_FEATURE: -15,
  CODE_RATE_LIMIT: -16,
  CODE_TOKEN_EXPIRED: -17,
  CODE_TOKEN_FAILURES_EXCEEDED: -18,
  CODE_INVALID_ORDER_STATUS: -19,
  CODE_ALREADY_SUBMITTED: -20,
  CODE_FRAUD_DETECTED: -21,
  CODE_GIFT_CARD_PIN_REQUIRED: -22,
  CODE_REPLICATION_OFFLINE: -23,
  CODE_ORDER_TYPE_REMOVED: -24,
};

export const RESPONSE_STATUS_CODE = {
  CODE_RATE_LIMIT: 429,
};

export const ALERT_CODE = {
  SHIPPING: {
    INVALID: {
      validate(alert) {
        return alert.code === 'shipping.invalid';
      },
      getReason(alert) {
        return alert.values.reason;
      },
    },
  },
  EDIT: {
    EXPIRED: {
      validate(alert) {
        return alert.code === 'edit.expired';
      },
      getMessage(alert) {
        return alert.values.message;
      },
    },
  },
  EBT: {
    VOIDED: {
      validate(alert) {
        return alert.code === 'ebt.voided';
      },
      getTenders(alert) {
        return alert.values.tenders;
      },
    },
  },
};
