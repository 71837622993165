import Vue from 'vue';

export default new Vue({
  methods: {
    open() {
      this.$emit('loader:open');
    },
    close() {
      this.$emit('loader:close');
    },
  },
});
