<template>
  <div>
    <PlatformRenderer>
      <template v-slot:onMobile>
        <div>
          <fieldset>
            <legend class="h4">{{ tipProfile.displayName }}</legend>
            <WCSelect
              data-testid="tipOptionSelect"
              :value="selectedTipOptionId"
              @input="selectedTipOptionIdChange"
              class="mb-2 bg-light"
            >
              <option
                v-for="tipOption in dropdownOptions"
                :key="tipOption.id"
                :value="tipOption.id"
              >
                <span v-if="isTipOptionIdNone(tipOption.id)" data-testid="noThankYou">{{
                  $t('noThankYou')
                }}</span>
                <span v-else-if="isTipOptionIdCustom(tipOption.id) && tipProfile.allowCustomAmount">
                  {{ $t('tipCustomAmountPlaceholder') }}
                </span>
                <span v-else-if="isTipOptionPercentage(tipOption)">
                  {{ tipOption.value }}% ({{ getTipAmountFromTipOption(tipOption) | currency }})
                </span>
                <span v-else-if="isTipOptionDollar(tipOption)">
                  {{ tipOption.value | currency }}
                </span>
              </option>
            </WCSelect>
            <WCInputGroup v-if="isTipOptionIdCustom(selectedTipOptionId)">
              <template v-slot:left>
                <span class="input-group-text border-primary">{{ $t('dollarSign') }}</span>
              </template>
              <input
                data-testid="customInput"
                class="flex-grow-1 rounded-right border border-primary text-primary bg-light"
                type="number"
                :placeholder="$t('tipCustomAmountPlaceholder')"
                :value="customAmount"
                @change="customAmountChange"
              />
            </WCInputGroup>
          </fieldset>
        </div>
      </template>
      <template v-slot:onDesktop>
        <div>
          <fieldset>
            <legend class="h4">{{ tipProfile.displayName }}</legend>
            <WCSelectBoxGroup class="position-relative flex-wrap">
              <WCSelectBox
                v-for="tipOption in sortedTipOptions"
                :key="tipOption.id"
                :data-testid="tipOption.id"
                :id="tipOption.id"
                class="text-center"
                :model="selectedTipOptionId"
                @change="selectedTipOptionIdChange"
                :primary="true"
              >
                <div v-if="isTipOptionPercentage(tipOption)">
                  <div>
                    <span class="font-size-base">{{ tipOption.value + '%' }}</span>
                  </div>
                  <div>
                    <span class="font-size-sm">
                      {{ getTipAmountFromTipOption(tipOption) | currency }}
                    </span>
                  </div>
                </div>
                <div v-if="isTipOptionDollar(tipOption)">
                  <span class="font-size-base">{{ tipOption.value | currency }}</span>
                </div>
              </WCSelectBox>

              <WCInputGroup
                v-if="tipProfile.allowCustomAmount"
                class="m-2"
                style="width:fit-content;"
              >
                <template v-slot:left>
                  <span class="input-group-text border-primary">{{ $t('dollarSign') }}</span>
                </template>
                <input
                  data-testid="customInput"
                  class="rounded-right border border-primary text-primary bg-light"
                  type="number"
                  step="0.01"
                  :placeholder="$t('tipCustomAmountPlaceholder')"
                  :value="customAmount"
                  @change="customAmountChange"
                />
              </WCInputGroup>

              <WCSelectBox
                data-testid="noThankYou"
                :id="tipOptionIdNone"
                class="text-center"
                :model="selectedTipOptionId"
                @change="selectedTipOptionIdChange"
                :primary="true"
              >
                <span>{{ $t('noThankYou') }}</span>
              </WCSelectBox>
            </WCSelectBoxGroup>
          </fieldset>
        </div>
      </template>
    </PlatformRenderer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import PlatformRenderer from '@/modules/platform/components/PlatformRenderer';
import WCSelectBoxGroup from '@/modules/forms/components/WCSelectBoxGroup/WCSelectBoxGroup.vue';
import WCSelectBox from '@/modules/forms/components/WCSelectBox/WCSelectBox.vue';
import WCSelect from '@/modules/forms/components/WCSelect/WCSelect.vue';
import WCInputGroup from '@/modules/forms/components/WCInputGroup/WCInputGroup.vue';
import WCSimpleActionableToast from '@/modules/toasts/components/WCSimpleActionableToast/WCSimpleActionableToast.vue';
import ToastService from '@/modules/toasts/services/ToastService';

const TIP_OPTION_TYPE = {
  DOLLAR: 'dollar',
  PERCENTAGE: 'percentage',
};

const TIP_OPTION_ID = {
  CUSTOM: 'custom',
  NONE: 'none',
};

export const isTipOptionIdCustomOrNone = tipOptionId => {
  return tipOptionId === TIP_OPTION_ID.CUSTOM || tipOptionId === TIP_OPTION_ID.NONE;
};

export default {
  components: { PlatformRenderer, WCSelect, WCSelectBoxGroup, WCSelectBox, WCInputGroup },
  props: {
    tipProfile: {
      type: Object,
      required: true,
    },
  },
  created() {
    if (this.selectedTipOptionId === null && this.customAmount === null) {
      this.setDefaultTip();
    }
  },
  methods: {
    setDefaultTip() {
      let newSelectedTipOptionId = TIP_OPTION_ID.NONE;
      const tipOptions = this.tipProfile.tipOptions;
      for (let tipOptionIndex = 0; tipOptionIndex < tipOptions.length; tipOptionIndex += 1) {
        const tipOption = this.tipProfile.tipOptions[tipOptionIndex];
        if (tipOption.default) {
          newSelectedTipOptionId = tipOption.id;
          break;
        }
      }
      this.change(newSelectedTipOptionId, null);
    },
    selectedTipOptionIdChange(id) {
      const newSelectedTipOptionId = id;
      const newCustomAmount = null;
      this.change(newSelectedTipOptionId, newCustomAmount);
    },
    customAmountChange(e) {
      const newCustomAmount = Number(e.target.value);
      this.change(TIP_OPTION_ID.CUSTOM, newCustomAmount);
      this.showAmountWarningToastIfOverMax();
    },
    change(newSelectedTipOptionId, newCustomAmount) {
      const data = {
        tipProfileId: this.tipProfile.id,
        tipOptionId: newSelectedTipOptionId,
        tipAmount: this.getTipAmount(newSelectedTipOptionId, newCustomAmount),
      };
      this.setSelectedTip(data);
      this.$emit('change', data);
    },
    showAmountWarningToastIfOverMax() {
      if (!this.isSelectedAmountOver50Percent) {
        return;
      }
      ToastService.open(WCSimpleActionableToast, {
        props: {
          variant: 'warning',
          title: this.$t('tipHighTitle'),
          message: this.$t('tipHighWarning'),
          btnList: [
            {
              actionName: 'undo',
              text: this.$t('tipHighUndo'),
            },
            {
              actionName: 'confirm',
              text: this.$t('tipHighConfirm'),
              isOutlineBtn: true,
            },
          ],
        },
        actions: {
          undo: this.setDefaultTip,
          confirm: () => {},
        },
      });
    },
    getTipAmount(tipOptionId, customAmount) {
      if (tipOptionId === TIP_OPTION_ID.NONE) {
        return 0;
      }
      if (tipOptionId === TIP_OPTION_ID.CUSTOM) {
        return customAmount;
      }
      return this.getTipAmountFromTipOptionId(tipOptionId);
    },
    getTipAmountFromTipOptionId(tipOptionId) {
      const tipOption = this.tipProfile.tipOptions.find(to => to.id === tipOptionId);
      if (!tipOption) {
        console.warn(`Option ID "${tipOptionId}" not found`);
        return 0;
      }
      return this.getTipAmountFromTipOption(tipOption);
    },
    getTipAmountFromTipOption(tipOption) {
      if (this.isTipOptionPercentage(tipOption)) {
        return (tipOption.value / 100) * this.totals.sub;
      }
      if (this.isTipOptionDollar(tipOption)) {
        return tipOption.value;
      }
      console.warn(`Unknown Tip Option Type: ${tipOption.type}`);
      return 0;
    },
    isTipOptionDollar(tipOption) {
      return this.getTipOptionType(tipOption) === TIP_OPTION_TYPE.DOLLAR;
    },
    isTipOptionPercentage(tipOption) {
      return this.getTipOptionType(tipOption) === TIP_OPTION_TYPE.PERCENTAGE;
    },
    getTipOptionType(tipOption) {
      if (tipOption.type === 0) {
        return TIP_OPTION_TYPE.DOLLAR;
      }
      return TIP_OPTION_TYPE.PERCENTAGE;
    },
    isTipOptionIdNone(tipOptionId) {
      return tipOptionId === TIP_OPTION_ID.NONE;
    },
    isTipOptionIdCustom(tipOptionId) {
      return tipOptionId === TIP_OPTION_ID.CUSTOM;
    },
    ...mapActions({
      setSelectedTip: 'cart/setSelectedTip',
    }),
  },
  computed: {
    isSelectedAmountOver50Percent() {
      return this.getTipAmount(this.selectedTipOptionId, this.customAmount) > this.totals.sub / 2;
    },
    selectedTipOptionId() {
      const tip = this.selectedTips.find(t => t.tipProfileId === this.tipProfile.id);
      if (tip === undefined) {
        return null;
      }
      if (tip.tipAmount === 0) {
        return TIP_OPTION_ID.NONE;
      }
      if (!tip.tipOptionId) {
        return TIP_OPTION_ID.CUSTOM;
      }
      return tip.tipOptionId;
    },
    customAmount() {
      const tip = this.selectedTips.find(t => t.tipProfileId === this.tipProfile.id);
      if (this.selectedTipOptionId === TIP_OPTION_ID.CUSTOM) {
        return tip.tipAmount;
      }
      return null;
    },
    dropdownOptions() {
      const ddOptions = [];
      ddOptions.push({ id: TIP_OPTION_ID.NONE, value: null, type: null });
      ddOptions.push(...this.sortedTipOptions);
      if (this.tipProfile.allowCustomAmount) {
        ddOptions.push({ id: TIP_OPTION_ID.CUSTOM, value: null, type: null });
      }
      return ddOptions;
    },
    sortedTipOptions() {
      const sorted = [...this.tipProfile.tipOptions];
      return sorted.sort((a, b) => {
        if (a.type === b.type) {
          return a.value - b.value;
        }
        return b.type - a.type;
      });
    },
    tipOptionIdNone() {
      return TIP_OPTION_ID.NONE;
    },
    ...mapGetters({
      totals: 'cart/getTotals',
      selectedTips: 'cart/getSelectedTips',
    }),
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';
</style>
