import { render, staticRenderFns } from "./WCCouponsModal.vue?vue&type=template&id=0a7ba4ba&scoped=true&"
import script from "./WCCouponsModal.vue?vue&type=script&lang=js&"
export * from "./WCCouponsModal.vue?vue&type=script&lang=js&"
import style0 from "./WCCouponsModal.vue?vue&type=style&index=0&id=0a7ba4ba&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a7ba4ba",
  null
  
)

export default component.exports