/**
 * Filter for employee actions in mobile and desktop
 */
import axios from 'axios';
import WCSimpleToast from '@/modules/toasts/components/WCSimpleToast/WCSimpleToast.vue';
import ToastService from '@/modules/toasts/services/ToastService';
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      isCustomer: 'user/isCustomer',
      isEmployee: 'user/isEmployee',
      user: 'user/getUser',
    }),
    downloadLogPath() {
      return 'api/employee/logs.zip';
    },
    /**
     * Property to check whether account menus can be displayed
     * If user is employee and associated with customer, display the account menus
     * Else if user is employee but not associated, hide the menus
     * Else show account menu for guest and customers
     */
    isNotAnEmployee() {
      let isCustomerAvailable = true;
      if (this.isEmployee) {
        if (this.isCustomer) {
          isCustomerAvailable = true;
        } else {
          isCustomerAvailable = false;
        }
      }
      return isCustomerAvailable;
    },
  },
  methods: {
    /**
     * Method for replicateNow functionality
     */
    async replicateNow() {
      try {
        const response = await axios.get('api/employee/rn', { timeout: 120000 });
        ToastService.open(WCSimpleToast, {
          props: {
            variant: 'success',
            title: 'Data Replication',
            message: response.data.message,
          },
          timeout: 7000,
        });
      } catch (error) {
        ToastService.open(WCSimpleToast, {
          props: {
            variant: 'danger',
            title: 'Error',
            message: error.message,
          },
          timeout: 7000,
        });
      }
    },
  },
};
