<template>
  <!-- START : Quantity Adjustor Display -->
  <div class="qty-adjustor-btn-grp">
    <div class="btn-group d-flex">
      <!-- START : Minus or Trash Button -->
      <button
        data-testid="decrementButton"
        class="btn btn-sm"
        v-if="!hasRemove || canDecrement"
        :disabled="!canDecrement || disabled"
        @click="decrement()"
        :aria-label="$t('decrement')"
      >
        <font-awesome-icon class="text-primary" icon="minus" />
      </button>
      <button
        class="btn btn-sm"
        v-else-if="hasRemove"
        :aria-label="$t('delete')"
        :disabled="disabled"
        @click="remove()"
      >
        <font-awesome-icon class="text-primary" icon="trash" />
      </button>
      <!-- END : Minus or Trash Button -->
      <!-- START : Spinner Qty -->
      <button data-testid="quantityDisplay" class="btn btn-sm qty" :disabled="disabled">
        {{ safeDisplayValue }}
      </button>
      <!-- END : Spinner Qty -->
      <!-- START : Plus button -->
      <button
        data-testid="incrementButton"
        class="btn btn-sm"
        :disabled="!canIncrement || disabled"
        :aria-label="$t('increment')"
        @click="increment()"
      >
        <font-awesome-icon class="text-primary" icon="plus" />
      </button>
      <!-- END : Plus button -->
    </div>
  </div>
  <!-- END : Quantity Adjustor Display -->
</template>
<script>
import { BigNumber } from 'bignumber.js';

export default {
  name: 'WCSimpleReactiveQuantityAdjustor',
  props: {
    // Reactive property
    value: {
      type: BigNumber,
      default() {
        return new BigNumber(1);
      },
    },
    displayValue: {
      type: String,
      default: '',
    },
    // Boolean to check if the remove option is present
    hasRemove: {
      type: Boolean,
      default: true,
    },
    // Increment step value
    incrementStep: {
      type: BigNumber,
      default() {
        return new BigNumber(1);
      },
    },
    // Maximum Quantity allowed for the item
    maxQty: {
      type: BigNumber,
      default: null,
    },
    // Minimum Quantity allowed for the item
    minQty: {
      type: BigNumber,
      default() {
        return new BigNumber(1);
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isNextIncrementValid: {
      type: Boolean,
      default: true,
    },
    isNextDecrementValid: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    /*
     * Property to check if the item quantity can be incremented based on max value
     */
    canIncrement() {
      return (
        ((this.maxQty || 0) <= 0 ||
          this.incrementStep.plus(this.value).comparedTo(this.maxQty) <= 0) &&
        this.isNextIncrementValid
      );
    },

    /**
     * Property to check if the item quantity can be decremented based on min value
     */
    canDecrement() {
      return (
        this.value.minus(this.incrementStep).comparedTo(new BigNumber(this.minQty)) >= 0 &&
        this.isNextDecrementValid
      );
    },

    safeDisplayValue() {
      if (this.displayValue === '') {
        return this.value.toString();
      }
      return this.displayValue;
    },
  },
  methods: {
    /**
     * Method to increment selected quantity and emit the 'increment' event
     */
    increment() {
      const newValue = this.value.plus((this.value ? this.incrementStep : this.minQty) || 1);
      this.$emit('input', newValue);
    },
    /**
     * Method to decrement selected quantity and emit the 'decrement' event
     */
    decrement() {
      const newValue = this.value.minus(this.incrementStep);
      this.$emit('input', newValue);
    },
    /**
     * Method to emit the 'remove' event
     */
    remove() {
      const newValue = new BigNumber(0);
      this.$emit('input', newValue);
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

/*----- START : Qty Adjustor -----*/
.qty-adjustor-btn-grp {
  .btn-group {
    display: flex;
    .btn {
      background-color: var(--white, $white);
    }
  }
  /*----- START : Qty Adjustor Mobile-----*/
  @include media-breakpoint-down(md) {
    .btn-group {
      display: block;
      .btn {
        border: 1px solid var(--gray-500, $gray-500);
        &.btn-sm {
          padding: $pad-3 * 0.35 $pad-3 * 0.65;
          font-size: $font-size-base;
        }
        &.qty {
          min-width: rem(60px);
        }
      }
    }
  }
  /*----- END : Qty Adjustor Mobile-----*/
}
/*----- END : Qty Adjustor -----*/
</style>
