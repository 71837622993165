<template>
  <div class="d-flex flex-row p-1 justify-content-between align-items-center">
    <div class="d-flex flex-row align-items-center">
      <WCImage
        :imageSrc="getProductImage(item.image)"
        :imageAlt="productImgAltText(item.name, item.brand)"
        imageClass="wc-eligible-product-image p-2"
        defaultClass="wc-eligible-product-image p-2"
        :defaultTitle="noProductImageAltText(item.name)"
      />
      <div class="d-flex flex-column">
        <WCProductPricing class="" :item="item"></WCProductPricing>
        <router-link
          class="font-weight-bold underline-link-from-center mr-2 font-size-xs"
          :to="`/i/${item.id}`"
          :id="item.id"
          >{{ item.name }} {{ item.size ? `, ${item.size}` : '' }}
        </router-link>
      </div>
    </div>

    <WCBasicAddToCartButton
      :item="item"
      class="btn-block"
      :describedBy="item.id"
      :orderType="activeOrderType"
      v-if="!isCustomerModeScan"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import WCImage from '@/components/WCImage/WCImage.vue';
import WCProductPricing from '@/components/WCProductPricing/WCProductPricing.vue';
import ProductDetailMixin from '@/views/ProductDetailPage/mixins/ProductDetailMixin';
import OrderTypesMixin from '@/modules/orderTypes/mixins/OrderTypesMixin';
import ProductImageInfoMixin from '@/mixins/ProductImageInfoMixin';
import CartControlsMixin from '@/modules/cart/mixins/CartControls/CartControls';

export default {
  name: 'WCCouponEligibleItemDetails',
  props: {
    item: {
      type: Object,
    },
  },
  components: {
    WCBasicAddToCartButton: () =>
      import('@/modules/cart/components/WCBasicAddToCartButton/WCBasicAddToCartButton.vue'),
    WCProductPricing,
    WCImage,
  },
  mixins: [CartControlsMixin, ProductImageInfoMixin, ProductDetailMixin, OrderTypesMixin],
  computed: {
    ...mapGetters({
      isCustomer: 'user/isCustomer',
      isCustomerModeScan: 'user/isCustomerModeScan',
    }),
  },
};
</script>

<style lang="scss" scoped>
.wc-coupon-detail-clip-button {
  flex-grow: 3;
}

.wc-eligible-product-image {
  width: 25%;
  height: 25%;
}
</style>
