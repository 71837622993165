<template>
  <header class="wc-header__wrapper shadow-sm" role="banner" ref="wc_header">
    <WCHeaderTop
      :isEmployee="isEmployee"
      :isCustomer="isCustomer"
      :isCustomerModeScan="isCustomerModeScan"
      v-if="isNotAnEmployee"
    />
    <section class="wc-header__middle p-3 container-xl border-bottom">
      <div class="row align-items-center">
        <!-- Allow navigation to home only if not an employee -->
        <div class="col-3" v-if="isNotAnEmployee">
          <router-link to="/">
            <WCImage
              :imageSrc="getStoreImageSrc"
              :imageAlt="getStoreName"
              imageClass="logo"
              :defaultTitle="getStoreName"
            />
          </router-link>
        </div>
        <div class="col-3" v-else>
          <WCImage
            :imageSrc="getStoreImageSrc"
            :imageAlt="getStoreName"
            imageClass="logo"
            :defaultTitle="getStoreName"
          />
        </div>
        <div class="col-6">
          <WCSearchBar
            v-if="
              $route.name !== 'Gift Card' && isNotAnEmployee && $configuration.itemSearchEnabled
            "
            @search="search"
            @fetchSuggestions="fetchSuggestions"
            :suggestions="suggestions"
            :itemGroups="itemGroups"
          />
        </div>
        <div class="col-3 d-flex align-items-center">
          <WCUserGreeter class="ml-auto" @logout="logout" />
          <WCShoppingCartButton
            v-if="isNotAnEmployee && ($configuration.cartEnabled || isCustomerModeScan)"
            @showItems="toggleCartDrawer"
            class="ml-4"
            :loading="isSyncing"
            cartIconSize="lg"
            id="headerCartButton"
          />
        </div>
      </div>
      <div class="d-flex justify-content-between">
        <WCHeaderStoreLabel v-if="isMultiStore && isNotAnEmployee" />
        <!-- Empty div for spacing the order type badge-->
        <div v-else />

        <div>
          <WCCurrentOrderTypeBadge
            v-if="activeOrderType"
            :orderType="activeOrderType"
            :cartsCount="cartsCount"
          />
        </div>
      </div>
    </section>
    <WCNavBar :wcHeaderHeight="wcHeaderHeight" />
    <section class="d-flex justify-content-center" v-if="showGiftCardError">
      <div class="alert alert-danger mt-3 col-8" role="alert">
        {{ $t('eGiftCardCartError') }}
      </div>
    </section>
  </header>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import CartDrawerService from '@/modules/cart/services/CartDrawerService';
import WCCartDrawer from '@/modules/cart/components/WCCartDrawer/WCCartDrawer.vue';
import EmployeeMixin from '@/modules/employee/mixins/EmployeeMixin';
import UserMixin from '@/modules/user/mixins/UserMixin';
import WCHeaderStoreLabel from '@/components/WCHeaderStoreLabel/WCHeaderStoreLabel.vue';
import WCImage from '@/components/WCImage/WCImage.vue';
import WCNavBar from '@/components/WCNavBar/WCNavBar.vue';
import CartItemControls from '@/modules/cart/mixins/CartControls/CartItemControls';
import OrderTypesMixin from '@/modules/orderTypes/mixins/OrderTypesMixin';
import WCCurrentOrderTypeBadge from '@/modules/orderTypes/components/WCCurrentOrderTypeBadge/WCCurrentOrderTypeBadge.vue';
import WCSearchBar from '../WCSearchBar/WCSearchBar.vue';
import WCUserGreeter from '../WCUserGreeter/WCUserGreeter.vue';
import WCShoppingCartButton from '../WCShoppingCartButton/WCShoppingCartButton.vue';
import WCHeaderTop from '../WCHeaderTop/WCHeaderTop.vue';

export default {
  mixins: [UserMixin, EmployeeMixin, CartItemControls, OrderTypesMixin],
  components: {
    WCHeaderStoreLabel,
    WCSearchBar,
    WCUserGreeter,
    WCShoppingCartButton,
    WCNavBar,
    WCHeaderTop,
    WCImage,
    WCCurrentOrderTypeBadge,
  },
  data() {
    return {
      wcHeaderHeight: '0px',
    };
  },
  computed: {
    ...mapGetters({
      suggestions: 'browse/getSuggestions',
      isSyncing: 'cart/isSyncing',
      isGiftCard: 'cart/isGiftCard',
      cartsCount: 'cart/getCartsCount',
    }),
    getStoreImageSrc() {
      return `${this.$configuration.cdn}/api/img/${this.$configuration.store.image}`;
    },
    getStoreName() {
      return this.$configuration.store.name;
    },
    itemGroups() {
      return this.$configuration.mainMenu?.children;
    },
    isMultiStore() {
      return this.$configuration.multistore;
    },
    showGiftCardError() {
      return (
        this.isGiftCard &&
        (this.$route.name === 'Browse' ||
          this.$route.name === 'Home' ||
          this.$route.name === 'Product Detail Page' ||
          this.$route.name === 'List') &&
        this.isNotAnEmployee &&
        !this.$configuration.orderTypesEnabled
      );
    },
  },
  /**
   * Created hook to make the store as default if there is only one store i.e. multistore is false
   */
  async created() {
    this.updateHomeStore();
  },
  methods: {
    ...mapActions({ fetchSuggestions: 'browse/fetchSuggestions' }),
    search({ term, department, itemGroup }) {
      this.$router.push({
        name: 'Browse',
        query: { q: term, g: itemGroup, ...(department ? { Dept: [department] } : undefined) },
      });
    },
    toggleCartDrawer() {
      CartDrawerService.toggle(WCCartDrawer);
    },
    /**
     * Make the store as default if there is only one store i.e. multistore is false
     */
    updateHomeStore() {
      if (!this.isMultiStore) {
        this.setUserHomeStore(this.$configuration.store.id);
      }
    },
  },
  mounted() {
    this.wcHeaderHeight = window.getComputedStyle(this.$refs.wc_header).height;
  },
};
</script>

<style scoped lang="scss">
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

.wc-header__wrapper {
  position: sticky;
  top: 0;
  z-index: map-get($zindex, 'header');

  background-color: white;
}

.wc-header__middle {
  .logo {
    max-width: 100%;
    max-height: 3.5rem;
  }
}
</style>
