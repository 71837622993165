<!-- Collapsible item groups section in mobile sidebar -->
<template>
  <ul>
    <template v-if="circularMenu && circularMenu.length">
      <WCSideMenuCollapsibleTemplate class="item--border">
        <template v-slot:menuHeader>
          <div class="text-dark">{{ $t('currentSpecials') }}</div>
        </template>
        <template v-slot:menuBody>
          <div v-for="child in circularMenu" :key="child.id">
            <WCSideMenuItem
              ><a href="#" @click.prevent="select(child.id)" class="menu__text--color">
                <span class="submenu-text underline-link-from-center">{{ child.name }}</span>
              </a></WCSideMenuItem
            >
          </div>
        </template>
      </WCSideMenuCollapsibleTemplate>
    </template>
    <template v-if="menu && menu.children">
      <template v-for="group in menu.children">
        <div v-if="!group.children || !group.children.length" :key="group.id">
          <WCSideMenuItem
            ><a href="#" @click.prevent="selectGroup(group)" class="menu__text--color">
              <span class="underline-link-from-center">{{ group.name }}</span>
            </a>
          </WCSideMenuItem>
        </div>
        <li v-else :key="group.id">
          <WCSideMenuCollapsibleTemplate class="item--border">
            <template v-slot:menuHeader>
              <div class="text-dark">{{ group.name }}</div>
            </template>
            <template v-slot:menuBody>
              <div v-for="child in group.children" :key="child.id">
                <WCSideMenuItem
                  ><a href="#" @click.prevent="selectGroup(group, child)" class="menu__text--color">
                    <span class="submenu-text underline-link-from-center">{{ child.name }}</span>
                  </a></WCSideMenuItem
                >
              </div>
              <WCSideMenuItem
                ><a href="#" @click.prevent="selectGroup(group, group)" class="menu__text--color">
                  <span class="submenu-text underline-link-from-center">{{ $t('viewAll') }}</span>
                </a></WCSideMenuItem
              >
            </template>
          </WCSideMenuCollapsibleTemplate>
        </li>
      </template>
    </template>
  </ul>
</template>

<script>
import OrderTypesMixin from '@/modules/orderTypes/mixins/OrderTypesMixin';
import WCSideMenuCollapsibleTemplate from '../WCSideMenuCollapsibleTemplate/WCSideMenuCollapsibleTemplate.vue';
import ItemGroupMixin from '../../../filters/mixins/ItemGroupMixin';
import WCSideMenuItem from '../WCSideMenuItem/WCSideMenuItem.vue';

export default {
  components: { WCSideMenuCollapsibleTemplate, WCSideMenuItem },
  name: 'WCItemGroupCollapsibleMenu',
  mixins: [ItemGroupMixin, OrderTypesMixin], // for select function and navigation to browse
  props: {
    circularMenu: {
      type: Array,
    },
    menu: {
      type: Object,
    },
  },
  methods: {
    async selectGroup(group, child) {
      if (this.$configuration.orderTypesEnabled) {
        await this.setActiveOrderType(this.orderTypeObjectFromId(group.id));
      }

      // Condition used to set Browse page URL and breadcrumbs correctly
      // This will always work as long as only going down 1 child layer
      if (child && group.id !== child.id) {
        this.select([group.id, child.id]);
      } else if (this.$configuration.orderTypesEnabled) {
        this.navigateToOrderTypeHome(group.id);
      } else {
        this.select(child?.id || group?.id);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

ul {
  list-style: none;
  padding: 0;
  margin: 0;
  color: var(--dark);
}

.item--border {
  border-top: 1px solid var(--gray-300) !important;
}
.menu__text--color {
  color: var(--dark);
}
.submenu-text {
  padding-left: 1rem;
  box-decoration-break: clone; /* For multiline padded text */
  -webkit-box-decoration-break: clone;
}
</style>
