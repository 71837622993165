<template>
  <div class="wc-cart wc-cart--empty d-flex flex-column align-items-center justify-content-center">
    <font-awesome-icon class="mb-3" icon="cart-shopping" size="4x" />
    <h3 class="mb-4">{{ $t('yourCartIsEmpty') }}</h3>
    <router-link :to="isScanAndGoMode ? '/scan' : '/'">
      <button class="btn btn-outline-primary" @click="close" ref="continueShoppingRef">
        {{ $t('continueShopping') }}
      </button>
    </router-link>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'EmptyCart',
  /**
   * Mounted hook to focus the shopping cart button
   */
  mounted() {
    this.focus();
  },
  computed: {
    ...mapGetters({
      isScanAndGoMode: 'user/isCustomerModeScan',
    }),
  },
  methods: {
    close() {
      this.$emit('close');
    },
    focus() {
      if (this.$refs.continueShoppingRef) this.$refs.continueShoppingRef.focus();
    },
  },
};
</script>
