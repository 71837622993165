<template>
  <div class="wc-pricing font-size-xs" :class="{ 'wc-scan-pricing': isScanMode }">
    <div
      v-if="hasActualPrice && !isAdditionalScanCode"
      ref="actualPrice"
      class="font-weight-bold"
      :class="{ 'wc-pricing__actual--sale': isDiscounted }"
      :aria-describedby="`${item.id}-priceDesc`"
    >
      {{ actualPriceDisplay }}
      <span class="sr-only" :id="`${item.id}-priceDesc`">
        {{ isDiscounted ? $t('salePrice') : $t('productPrice') }}
      </span>

      <template v-if="hasAcoDiscountToolTip">
        <WCToolTip target="actualPrice" position="top-start" class="wc-pricing__tooltip">
          <div class="whitespace-pre">{{ acoDiscountToolTipText }}</div>
        </WCToolTip>
        <font-awesome-icon icon="circle-question" />
      </template>
      <template v-else-if="isDiscountedInCart">
        <WCToolTip target="actualPrice" position="top-start" class="wc-pricing__tooltip">
          <div class="whitespace-pre">{{ $t('discountAppliedInCart') }}</div>
        </WCToolTip>
        <font-awesome-icon icon="circle-question" />
      </template>
    </div>
    <div v-else-if="!isSoldByAverageWeight" ref="noPrice">
      {{ $t('seePriceInCart') }}
    </div>
    <div
      v-if="hasActualPrice && isDiscounted"
      class="text-muted"
      :aria-describedby="`${item.id}-priceText`"
    >
      <s>{{ suggestedPriceDisplay }}</s>
      <span class="sr-only" :id="`${item.id}-priceText`">{{ $t('productPrice') }}</span>
    </div>
    <div
      v-if="hasActualPrice && isSoldByAverageWeight"
      class="font-weight-bold wc-sold-by-avg-weight"
      :aria-describedby="`${item.id}-avgPriceText`"
    >
      {{ avgPerUnitPrice }}
      <span class="sr-only" :id="`${item.id}-avgPriceText`">{{ $t('averagePerUnitPrice') }}</span>
    </div>
    <div v-if="!hasActualPrice" class="font-weight-bold">
      {{ $t('callForPrice') }}
    </div>
    <div v-if="embeddedPrice" class="font-weight-bold">
      {{ embeddedPrice | currency }}{{ '/ea' }}
    </div>
    <div v-if="price" class="font-weight-bold">{{ price | currency }}{{ '/ea' }}</div>
  </div>
</template>

<script>
import ProductPricingMixin from '@/mixins/ProductPricingMixin';
import { RIBBON_CONFIG } from '../../constants/PromoRibbonConstants';
import { currency } from '../../utils/formatters';
import WCToolTip from '../WCToolTip/WCToolTip.vue';

export default {
  components: { WCToolTip },
  mixins: [ProductPricingMixin],
  props: {
    item: {
      type: Object,
    },
    price: {
      type: Number,
    },
    embeddedPrice: {
      type: Number,
    },
    isScanMode: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    acoDiscountToolTipText() {
      let discountText = '';
      if (RIBBON_CONFIG.DISCOUNT_NAME_RIBBON.canDisplay(this.item)) {
        discountText += `${RIBBON_CONFIG.DISCOUNT_NAME_RIBBON.getTextContent(this.item)}\n`;
      }

      if (RIBBON_CONFIG.SALE_AMOUNT_RIBBON.canDisplay(this.item)) {
        discountText += `${RIBBON_CONFIG.SALE_AMOUNT_RIBBON.getTextContent(this.item)}`;
      }
      return discountText;
    },
    hasAcoDiscountToolTip() {
      return this.isScanMode && this.acoDiscountToolTipText;
    },
    avgPerUnitPrice() {
      const price = `${currency(this.item.averageWeight * this.item.actualPrice)}`;
      return `${price}/ea avg`;
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

.wc-pricing {
  line-height: normal;

  .wc-pricing__actual--sale {
    color: var(--info, $info);
  }

  .wc-pricing__tooltip {
    font-size: $font-size-2xs;
  }
}

.wc-scan-pricing {
  transform: translate3d(0, 0, 0);
}

.whitespace-pre {
  white-space: pre;
}
</style>
