import { store } from '@/store';

/**
 * Redirects to the 'redirectTo' query parameter in the current url if it exists.
 * Redirects to home otherwise.
 * @returns {Promise<void>}
 */
export default async function redirectFallbackHome(router) {
  const isEmployee = store.getters['user/isEmployee'];
  const isScanMode = store.getters['user/isCustomerModeScan'];
  const isInStore = store.getters['cart/getInStore'];
  try {
    const redirectTo = router.currentRoute.query.redirectTo;
    if (redirectTo) {
      // Vue 2 has silly router errors. TODO: Catch and handle in Vue 3
      await router.push({ path: redirectTo }).catch(() => {});
    } else if (isEmployee) {
      await router.push({ name: 'EmployeeCustomerSelect' }).catch(() => {});
    } else if (isScanMode && isInStore) {
      await router.push({ name: 'Scan Products' }).catch(() => {});
    } else {
      // Fallback to home
      await router.push({ name: 'Home' }).catch(() => {});
    }
  } catch (e) {
    console.log(e);
  }
}
