<template>
  <div class="product-card-group">
    <slot></slot>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

/* Using grid to display product items in row based on breakpoints */
.product-card-group {
  display: grid;
  grid-template-columns: repeat(2, calc(50% - 0.25rem));
  gap: 0.5rem;
}

@include media-breakpoint-up(xl) {
  .product-card-group {
    grid-template-columns: repeat(4, calc(25% - 0.25rem));
  }
}
@include media-breakpoint-only(lg) {
  .product-card-group {
    grid-template-columns: repeat(3, calc(33% - 0.25rem));
  }
}
@include media-breakpoint-only(md) {
  .product-card-group {
    grid-template-columns: repeat(4, calc(25% - 0.25rem));
  }
}
@include media-breakpoint-only(sm) {
  .product-card-group {
    grid-template-columns: repeat(3, calc(33% - 0.25rem));
  }
}
</style>
