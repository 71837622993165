<template>
  <Dashboard>
    <template v-slot:breadcrumbs>
      <WCBreadcrumb :crumbs="breadcrumbs" @selected="breadcrumbs[breadcrumbs.length - 1]" />
    </template>

    <template v-slot:title>
      {{ $t('yourAccount') }}
    </template>

    <template v-slot:sidebar>
      <WCAccountSidebar />
    </template>
  </Dashboard>
</template>

<script>
import WCAccountSidebar from '@/modules/user/components/WCAccountSidebar/WCAccountSidebar.vue';
import WCBreadcrumb from '@/components/WCBreadcrumb/WCBreadcrumb.vue';
import { mapGetters } from 'vuex';
import Dashboard from '@/layouts/components/Dashboard/Dashboard.vue';

export default {
  name: 'AccountDashboard',
  components: { Dashboard, WCAccountSidebar, WCBreadcrumb },
  computed: {
    ...mapGetters('lists', ['getListById']),
    breadcrumbs() {
      const breadcrumbs = this.$route.meta.breadcrumbs;

      if (breadcrumbs.length === 3 && breadcrumbs[1].text === 'Shopping Lists') {
        const list = this.getListById(this.$route.params.id);
        return [
          {
            to: '/me',
            text: 'Account',
          },
          {
            to: '/me/lists/',
            text: 'Shopping Lists',
          },
          {
            to: '/me/lists/list',
            text: `${list && list.name}`,
          },
        ];
      }
      return breadcrumbs && breadcrumbs.length ? breadcrumbs : [];
    },
  },
};
</script>

<style scoped></style>
