import { format, parse, parseISO, setHours, setMinutes, setSeconds } from 'date-fns';

export const currency = value => {
  if (typeof value !== 'number') {
    return value;
  }
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });
  return formatter.format(value);
};

export const formatTimeStamp = timestamp => {
  if (!timestamp) {
    return null;
  }

  const date = new Date(timestamp);
  const isoDate = parseISO(date.toISOString());

  if (isoDate instanceof Error) {
    return null;
  }

  return format(isoDate, "'Balance as of 'MMM dd 'at' h:mm a");
};

export const formatDateTime = datetime => {
  const isoDate = parseISO(datetime);

  if (!datetime || isoDate instanceof Error) {
    return null;
  }

  return format(isoDate, 'MMM d, yyyy h:mm a');
};
export const formatDate = date => {
  const isoDate = parseISO(date);

  if (!date || isoDate instanceof Error) {
    return null;
  }

  return format(isoDate, 'MMM d, yyyy');
};
export const dayOfWeek = date => {
  const isoDate = parseISO(date);

  if (!date || isoDate instanceof Error) {
    return null;
  }

  return format(isoDate, 'iiii');
};
export const shortDay = date => {
  const isoDate = parseISO(date);

  if (!date || isoDate instanceof Error) {
    return null;
  }

  return format(isoDate, 'iii').toUpperCase();
};
export const formatMonthDay = date => {
  const isoDate = parseISO(date);

  if (!date || isoDate instanceof Error) {
    return null;
  }

  return format(isoDate, 'MMM d');
};
export const formatTime = time => {
  if (!time) {
    return null;
  }

  let formattedTime = time;
  if (time.indexOf('T') >= 0) {
    formattedTime = time.split('T')[1];
  }

  let datetime = new Date(0);
  const [hours = 0, minutes = 0, seconds = 0] = formattedTime.split(':');
  datetime = setHours(datetime, hours);
  datetime = setMinutes(datetime, minutes);
  datetime = setSeconds(datetime, seconds);

  return format(datetime, "h:mm aaaaa'm'").toUpperCase();
};

/**
 * Method to check if date/time satisfies the given format and return date object
 * @param {String} dateFormat
 * @param {String} dateString
 * @returns {Date} parsed date
 */
export const checkDateTimeFormat = (dateFormat, dateString) => {
  const error = {
    invalidDate: false,
    date: null,
  };
  if (dateString) {
    const date = parse(dateString, dateFormat, new Date());
    if (date.toString() === 'Invalid Date') {
      error.invalidDate = true;
    } else {
      error.date = date;
    }
  } else {
    error.invalidDate = true;
  }
  return error;
};

/**
 * Filter to make first letter only capitalized
 * @param {String} value
 * @returns formattedString
 */
export const capitalizeFirstLetter = value => {
  let formattedString = '';
  if (typeof value === 'string')
    formattedString = value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
  return formattedString;
};
