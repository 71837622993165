import { render, staticRenderFns } from "./WCEditSpecialOrderItemButton.vue?vue&type=template&id=ddfbda08&scoped=true&"
import script from "./WCEditSpecialOrderItemButton.vue?vue&type=script&lang=js&"
export * from "./WCEditSpecialOrderItemButton.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ddfbda08",
  null
  
)

export default component.exports