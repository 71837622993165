export default {
  metaInfo() {
    return {
      title: `${this.$route.meta?.title || this.$route.name || ''}`,
      titleTemplate: `${this.$configuration.store.name} - %s`,
      meta: [
        {
          name: 'description',
          content: 'Shop online with WebCart',
        },
        {
          name: 'keyword',
          content: 'WebCart | curbside pickup | grocery | grocery delivery',
        },
        {
          name: 'robots',
          content: 'noindex,nofollow',
        },
        {
          name: 'google',
          content: 'nositelinkssearchbox',
        },
        /* FB share */
        { property: 'og:title', content: 'webcart' },
        { property: 'og:type', content: 'product.group' },
        { property: 'og:url', content: `${this.$configuration.siteUrl}` },
        { property: 'og:description', content: `${this.$configuration.store.name}` },
        {
          property: 'og:image',
          content: `${this.$configuration.cdn}/api/img/${this.$configuration.store.image}`,
        },

        /* Twitter share */
        { property: 'twitter:title', content: 'webcart' },
        {
          property: 'twitter:description',
          content: `${this.$configuration.store.name}`,
        },
        {
          property: 'twitter:image',
          content: `${this.$configuration.cdn}/api/img/${this.$configuration.store.image}`,
        },
        { property: 'twitter:card', content: 'summary_large_image' },
      ],
    };
  },
};
