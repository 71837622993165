<template>
  <!-- Non MTO items as usual add to list flow -->
  <button
    v-if="!isFavoriteMTOItem"
    :class="btnClass"
    :disabled="disabled"
    @click="openModal"
    ref="addToListModalBtnRef"
  >
    <span v-if="showText">{{ $t('addToList') }}</span>
    <font-awesome-icon class="ml-2" icon="list-check" @click="open" />
  </button>
  <!-- MTO item from fav icon opening modal -->
  <button
    v-else
    :class="btnClass"
    :disabled="disabled"
    @click="openModal"
    ref="addToListModalBtnRef"
  >
    <span v-if="showText">{{ $t('addToFavorite') }}</span>
    <font-awesome-icon class="ml-2" icon="heart" @click="open" />
  </button>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ModalService from '@/modules/modals/services/modal.service';
import ItemModifierMixin from '@/modules/itemModifiers/mixins/ItemModifierMixin';
import ToastService from '@/modules/toasts/services/ToastService';
import WCAddToListToast from '@/modules/lists/components/WCAddToListToast/WCAddToListToast.vue';
import ItemAddToListMixin from '@/modules/lists/mixins/ItemAddToListMixin';
import WCAddToListModal from '../WCAddToListModal/WCAddToListModal.vue';

/**
 * Different varients of buttons
 */
export const BTN_VARIENT = {
  LINK: 'LINK',
  SOLID: 'SOLID',
  ATC: 'ATC',
  MOBILE_CARD: 'MOBILE_CARD',
};

export default {
  props: {
    item: {},
    btnVarient: {
      type: String,
      validator: value => BTN_VARIENT[value],
      default: BTN_VARIENT.LINK,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    instructions: {
      type: String,
    },
    isFavoriteMTOItem: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [ItemModifierMixin, ItemAddToListMixin],
  emits: ['close'],
  computed: {
    ...mapGetters({
      getItemVariation: 'items/getItemVariation',
      isGuest: 'user/isGuest',
    }),
    /**
     * Computed property to hold the button class based on the button varient
     */
    btnClass() {
      const btnClassInfo = {
        [BTN_VARIENT.LINK]: 'btn btn-link underline-link-from-center p-0',
        [BTN_VARIENT.SOLID]: 'btn btn-primary',
        [BTN_VARIENT.ATC]: 'btn btn-block btn-primary',
        [BTN_VARIENT.MOBILE_CARD]: 'btn text-primary rounded add-to-list--btn-mobile',
      };
      return btnClassInfo[this.btnVarient];
    },
    showText() {
      return this.btnVarient !== BTN_VARIENT.MOBILE_CARD;
    },
  },
  methods: {
    ...mapActions({
      createVariation: 'items/createVariation',
    }),
    /**
     * To decide the modal flow according to the item's type
     */
    async openModal() {
      if (!this.isGuest) {
        // NON MTO item
        if (!this.isFavoriteMTOItem) {
          await this.open();
        }
        // MTO item - directly favorited
        else {
          try {
            if (this.item && (this.item.hasModifiers || this.item.hasLinkedItems)) {
              // To check if the item has modifiers or linked items. If so, the variation will be created
              await this.createVariation(this.item.id);
            }

            const list = await this.addItemToList({
              list: { name: 'Favorites', type: 'FAVORITES' },
              item: this.item,
              variation: this.getItemVariation || null,
            });

            ToastService.open(WCAddToListToast, {
              props: {
                variant: 'favorite',
                title: `Item added to ${list.name}`,
                list,
                close: {},
                dismiss: {},
              },
              timeout: 2000,
            });

            this.$emit('close');
          } catch (error) {
            ToastService.open(WCAddToListToast, {
              props: {
                variant: 'danger',
                title: 'Error',
                message: error.message,
              },
              timeout: 7000,
            });
          }
        }
      } else {
        this.$router.push('/login');
      }
    },
    /**
     * To open Add to list modal
     */
    async open() {
      this.$emit('close');
      try {
        if (this.item && (this.item.hasModifiers || this.item.hasLinkedItems)) {
          // To check if the item has modifiers or linked items. If so, the variation will be created
          await this.createVariation(this.item.id);
        }
        await ModalService.open(WCAddToListModal, {
          item: this.item,
          variation: this.getItemVariation || null, // Add variation into the list
        });
      } catch (error) {
        // NOOP
        console.error(error);
      } finally {
        if (this.$refs.addToListModalBtnRef) this.$refs.addToListModalBtnRef.focus();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.add-to-list--btn-mobile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: auto;
}
</style>
