<template>
  <section class="d-flex flex-column w-75 mx-auto my-0">
    <template v-for="(alert, ind) in AlertService.alerts">
      <component
        :key="ind"
        :is="alert.component"
        :data-id="alert.id"
        v-bind:close="alert.close"
        v-bind:dismiss="alert.dismiss"
        v-bind="alert.props"
      />
    </template>
  </section>
</template>

<script>
import AlertService from '../../services/AlertService';

export default {
  data() {
    return {
      AlertService,
    };
  },
};
</script>
