<template>
  <WCCarousel class="wc-web-ads wc-carousel--negate-container position-relative" :isSlider="true">
    <Splide
      :options="primaryOptions"
      ref="primary"
      @splide:autoplay:play="setAutoplayPlaying"
      @splide:autoplay:pause="setAutoplayPaused"
    >
      <slot></slot>
    </Splide>
    <Splide
      :options="secondaryOptions"
      class="wc-splide m-auto rounded"
      ref="secondary"
      @splide:autoplay:play="setAutoplayPlaying"
      @splide:autoplay:pause="setAutoplayPaused"
    >
      <slot></slot>

      <template v-slot:controls>
        <div class="splide__arrows">
          <button class="splide__arrow splide__arrow--prev splide-arrows wc-arrow-left-margin">
            <font-awesome-icon icon="arrow-right-long" size="xl" />
          </button>
          <button class="splide__arrow splide__arrow--next splide-arrows wc-arrow-right-margin">
            <font-awesome-icon icon="arrow-right-long" size="xl" />
          </button>
        </div>
        <div class="splide__slider d-none"></div>
        <div class="wc-autoplay-wrapper">
          <div class="splide__autoplay wc-autoplay btn text-white" @click="toggleAutoplay">
            <font-awesome-icon
              icon="pause"
              v-if="autoplayPlaying"
              :aria-label="$t('pauseAutoplay')"
              class="splide__pause"
            />
            <font-awesome-icon
              icon="play"
              v-else
              :aria-label="$t('startAutoplay')"
              class="splide__play"
            />
          </div>
        </div>
      </template>
    </Splide>
  </WCCarousel>
</template>

<script>
import { Splide } from '@splidejs/vue-splide';
import { PlatformMixin } from '@/modules/platform';
import ElementSizeMixin from '@/mixins/ElementSizeMixin';
import WCCarousel from '../WCCarousel/WCCarousel.vue';

export default {
  name: 'WCAutoplayCarousel',
  components: { WCCarousel, Splide },
  data() {
    return {
      autoplayPlaying: true,
      slidePaused: false,
      pauseTimeout: null,
      primaryOptions: {
        perPage: 1,
        arrows: false,
        pauseOnHover: false, // Hover/focus conflict with play/pause button
        pauseOnFocus: false,
        rewind: true,
        preloadPages: 3,
        lazyLoad: 'nearby',
        gap: '0.2rem', // Without a gap, the next and previous image can bleed into the current slide.
        type: 'loop',
        pagination: false,
      },
      secondaryOptions: {
        type: 'slide',
        rewind: true,
        gap: '0.2rem', // Without a gap, the next and previous image can bleed into the current slide.
        pagination: true,
        autoplay: true,
        fixedWidth: 1,
        fixedHeight: 1,
        focus: 'center',
        preloadPages: 3,
        lazyLoad: 'nearby',
        isNavigation: true,
        updateOnMove: true,
        pauseOnHover: false, // Hover/focus conflict with play/pause button
        pauseOnFocus: false,
        classes: {
          pagination: 'splide__pagination wc-pagination',
        },
      },
    };
  },
  mixins: [PlatformMixin, ElementSizeMixin],
  mounted() {
    const primary = this.$refs.primary;
    const secondary = this.$refs.secondary;

    primary.sync(secondary.splide);

    const secondaryTrack = secondary.splide.Components.Elements.track;
    secondaryTrack.classList.add('d-none');

    this.setArrowMargin();
    window.addEventListener('resize', () => {
      this.setArrowMargin();
    });
  },
  beforeDestroy() {
    clearTimeout(this.pauseTimeout);
    window.removeEventListener('resize', () => {
      this.setArrowMargin();
    });
  },
  methods: {
    setAutoplayPlaying() {
      if (this.slidePaused) {
        this.autoplayPlaying = false;
        this.pauseTimeout = setTimeout(() => {
          const secondary = this.$refs.secondary;
          const autoplay = secondary.splide.Components.Autoplay;
          autoplay.pause();
        }, 500);
      } else {
        this.autoplayPlaying = true;
      }
    },
    setAutoplayPaused() {
      this.autoplayPlaying = false;
    },
    toggleAutoplay() {
      const secondary = this.$refs.secondary;
      const autoplay = secondary.splide.Components.Autoplay;

      if (this.autoplayPlaying) {
        this.slidePaused = true;
        autoplay.pause();
      } else {
        this.slidePaused = false;
        autoplay.play();
      }
    },
    setArrowMargin() {
      const buffer = 75; // Buffer for space between arrows and pagination.
      const adWidth = this.getClientWidth();
      const paginationWidth = this.getWidthOfClass('wc-pagination');
      const widthOfArrow = this.getWidthOfClass('splide__arrow');

      const arrowMargin = (adWidth - 2 * widthOfArrow - paginationWidth - buffer) / 2;
      document.documentElement.style.setProperty('--arrow-margin', `${arrowMargin}px`);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

.wc-splide {
  position: absolute;
  width: 100%;
  bottom: -0.3rem;
}

::v-deep .splide__pagination__page {
  width: rem(10px);
  height: rem(10px);
}

@include media-breakpoint-up(lg) {
  .wc-splide {
    width: 100%;
  }
}

@include media-breakpoint-down(sm) {
  .splide-arrows {
    display: none;
  }
  .wc-autoplay {
    font-size: 2rem !important;
    padding-right: 2rem !important;
  }
  ::v-deep(.splide__pagination__page) {
    width: rem(15px) !important;
    height: rem(15px) !important;
    margin-left: rem(10px) !important;
    margin-right: rem(10px) !important;
  }
}

.wc-splide--pagination {
  font-size: $font-size-lg;
}

::v-deep .splide__pagination {
  flex-wrap: nowrap;
}

.wc-autoplay {
  float: right;
  position: relative;
  z-index: 2;
  width: 1.8rem;
  padding-right: 1.5rem;
  font-size: 1.5rem;
}

.wc-autoplay-wrapper {
  width: 100%;
}

.splide__slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.splide-arrows {
  color: white;
  opacity: 100%;
}

::v-deep .wc-pagination {
  width: fit-content;
  bottom: 30%;
}

::v-deep .splide-arrows {
  font-size: 1.5rem !important;
}

.wc-arrow-left-margin {
  margin-left: var(--arrow-margin);
}

.wc-arrow-right-margin {
  margin-right: var(--arrow-margin);
}
</style>
