import { BigNumber } from 'bignumber.js';
import { mapMutations, mapGetters, mapActions } from 'vuex';
import WCSimpleToast from '@/modules/toasts/components/WCSimpleToast/WCSimpleToast.vue';
import ToastService from '@/modules/toasts/services/ToastService';

export default {
  computed: {
    ...mapGetters({
      activeCategory: 'items/getActiveCategory',
      categories: 'items/getCategories',
      categoryCounts: 'items/getCategoryCounts',
      itemModifiers: 'items/getItemModifiers',
      fetched: 'items/fetched',
      currentAnswers: 'items/getCurrentAnswers',
      modifyingItem: 'items/getModifyingItem',
    }),
    /**
     * Returns the array of answers that should be displayed in 'Current Selections'
     * @returns {Array}
     */
    visibleAnswers() {
      return Object.values(this.currentAnswers).filter(answer => {
        return (!answer.hidden && answer.label && answer.quantity > 0) || answer.subtraction;
      });
    },
    /**
     * Returns the array of answers that are default selected on the item modifier.
     * These are the the answers on itemModifiers.answers from the im response
     * @returns {Array}
     */
    defaultAnswers() {
      return Object.values(this.currentAnswers).filter(answer => {
        return answer.default;
      });
    },
    /**
     * Returns true if this item modifier is valid and all the min-max requirements
     * on the categories have been met.
     * @returns {boolean}
     */
    allValid() {
      if (!this.categoryCounts) {
        return false;
      }

      let isValid = true;
      // Added Null check for Categories
      if (this.categories) {
        this.categories.forEach(cat => {
          const catCount = this.categoryCounts[cat.id] || 0;
          // WO defaults min and max to 0, so if retailer sets min, but not max, then the max will be 0 and that should not invalidate our logic. 2nd condition handles this
          if (catCount < cat.min || (cat.max > cat.min && catCount > cat.max)) {
            isValid = false;
          }
        });
      } else {
        isValid = false;
      }

      return isValid;
    },
  },
  methods: {
    ...mapMutations({
      setActiveCategory: 'items/SET_ACTIVE_CATEGORY',
      setCategoryCounts: 'items/SET_CATEGORY_COUNTS',
      setItemModifiers: 'items/SET_ITEM_MODIFIERS',
      setCurrentAnswer: 'items/SET_CURRENT_ANSWER',
      setCategories: 'items/SET_CATEGORIES',
      clearAnswers: 'items/CLEAR_ANSWERS',
    }),
    ...mapActions({
      clearSelections: 'items/clearSelections',
    }),
    /**
     * Construct unique id from name and id
     * @param {String} name - category name
     * @param {String} id - category id
     * @returns String
     */
    constructCategoryId(name, id) {
      const categoryId = name + id;
      return categoryId ? categoryId.replace(/^[^a-z]+|[^\w:.-]+/gi, '') : '';
    },
    /**
     * Updates the default answers/selections on the item so that the inital state of the dialog is accurate.
     * The answers come in from the API without much info so we have to do some preprocessing.
     */
    updateDefaultAnswers() {
      this.processItemModifierDefaults(this.itemModifiers, this.itemModifiers.answers);

      if (this.modifyingItem?.categories) {
        this.setCategoryCounts({});
        this.setCategories(this.modifyingItem?.categories);
        this.clearAnswers();
        this.processItemModifierDefaults(this.modifyingItem, this.modifyingItem.answers);
      }
    },
    /**
     * Process the item modifier to get information about the default selected answers
     * TODO setDefaultAnswers and updateAnswerDetails both call updateAnswerDetails, consider new function?
     * @param {Object} itemMods Item Modifiers to process defaults for
     * @param {Array} answers The default answers selected on the item modifier, or its parent's in the case of subtrees
     */
    processItemModifierDefaults(itemMods, answers) {
      itemMods.categories.forEach(category => {
        category.items.forEach(itemModItem => {
          const defaultAnswer = answers[itemModItem.id];
          if (defaultAnswer) {
            // Set the item's "default enabled" state
            this.setDefaultAnswer(itemModItem, category);
            // Update the details about the answer in case it is different than the default
            this.updateAnswerDetails(itemModItem, { ...defaultAnswer });
          }

          if (itemModItem.subTree && itemModItem.subTree.categories) {
            this.processItemModifierDefaults(itemModItem.subTree, answers);
          }
        });
      });

      if (itemMods.linkedItems) {
        itemMods.linkedItems.items.forEach(itemModItem => {
          const defaultAnswer = itemMods.answers[itemModItem.id];
          if (defaultAnswer) {
            this.setDefaultAnswer(itemModItem, itemMods.linkedItems.items[0]);
            this.updateAnswerDetails(itemModItem, { ...defaultAnswer });
          }
        });
      }
    },
    /**
     * Toggles whether or not an item modifier is selected
     * @param {Object} category The category that the item is being selected for
     * @param {Object} itemModItem The item modifier item being toggled
     */
    toggleModifier(category, itemModItem) {
      const currentAnswer = this.currentAnswers[itemModItem.id];
      if (
        currentAnswer &&
        ((itemModItem.quantities && currentAnswer.quantityId) ||
          (!itemModItem.quantities && currentAnswer.quantity > 0))
      ) {
        this.removeAnswerItem(itemModItem, category);
      } else if (this.categoryAllowsSelection(category)) {
        this.setDefaultAnswer(itemModItem, category);
      } else if (!this.categoryAllowsSelection(category)) {
        // If the category reachs maximum qty, cannot add item warning message toast will be displayed
        ToastService.open(WCSimpleToast, {
          props: {
            variant: 'danger',
            title: this.$t('error'),
            message: this.$t('categoryLimitExceededMsg', {
              limit: category.max,
              categoryName: category.name,
            }),
          },
          timeout: 7000,
        });
      }
    },
    /**
     * Determines if a category has reached its maximum based on the category limits
     * Used to decide if an item can be selected or not
     * @param {Object} category The category that the item is being tested against
     */
    categoryAllowsSelection(category) {
      return !category.max || (this.categoryCounts[category.id] || 0) + 1 <= category.max;
    },
    /**
     * Removes an item modifier item from the list of answers
     * @param {Object} itemModItem The itemModItem to remove
     * @param {Object} category The category to remove the item from
     */
    removeAnswerItem(itemModItem, category) {
      const currentAnswer = this.currentAnswers[itemModItem.id];
      if (currentAnswer) {
        if (itemModItem.quantities) {
          currentAnswer.quantityId = null;
          currentAnswer.quantity = 0;
        } else {
          currentAnswer.quantity = 0;
        }

        const newCategoryCounts = { ...this.categoryCounts };
        newCategoryCounts[category.id] = this.categoryCounts[category.id]
          ? this.categoryCounts[category.id] - 1
          : 0;
        this.setCategoryCounts(newCategoryCounts);

        // Remove the added subcategories
        if (itemModItem.subTree?.categories) {
          this.removeCategories(itemModItem.subTree.categories);
        }
      }

      this.updateAnswerDetails(itemModItem, currentAnswer);
    },
    /**
     * Removes the category that is passed in from the categories
     * @param {Object} category
     */
    removeCategories(categories) {
      if (!categories) {
        return;
      }

      const updatedCategories = this.categories;
      categories.forEach(category => {
        const categoryIndex = updatedCategories.find(activeCategory => {
          return activeCategory.id === category.id;
        });

        if (this.categoryCounts[category.id]) {
          delete this.categoryCounts[category.id];
        }

        category.items.forEach(itemModItem => {
          if (itemModItem.subTree) {
            this.removeCategories(itemModItem.subTree.categories);
          }

          if (this.currentAnswers[itemModItem.id]) {
            delete this.currentAnswers[itemModItem.id];
          }
        });
        updatedCategories.splice(updatedCategories.indexOf(categoryIndex), 1);
      });

      this.setCategories(updatedCategories);
    },
    /**
     * Sets the default selected answer for an item. This should be the default state of a modifier when it is selected
     * @param {Object} itemModItem The item to add default answer information for
     * @param {Object} category The category that the item modifier item is being enabled on
     */
    setDefaultAnswer(itemModItem, category) {
      this.setDefaultCategoryCount(category);
      this.updateAnswerByQuantityType(itemModItem);

      if (itemModItem.subTree && itemModItem.subTree.categories) {
        this.addSubCategories(itemModItem.subTree.categories, category);
      }
    },
    /**
     *
     * @param {Array} subCategories List of categories to be added after the category
     * @param {Object} category Category that will have subcategories added to it
     */
    addSubCategories(subCategories, category) {
      const index = this.categories.findIndex(ccat => {
        return ccat.id === category.id;
      });

      if (index >= 0) {
        subCategories.forEach(subCategory => {
          // eslint-disable-next-line no-param-reassign
          subCategory.depth = (category.depth || 0) + 1;
        });

        // Insert the categories from the subtree into the existing categories
        const newCategories = JSON.parse(JSON.stringify(this.categories));

        newCategories.splice(...[index + 1, 0].concat(subCategories));

        this.setCategories(newCategories);
        this.setActiveCategory(category);
      }
    },
    /**
     * Increment the category count for an item's 'default' state
     * @param {Object} category The category the item is being enabled on
     */
    setDefaultCategoryCount(category) {
      const newCategoryCounts = { ...this.categoryCounts };
      newCategoryCounts[category.id] = (this.categoryCounts[category.id] || 0) + 1;
      this.setCategoryCounts(newCategoryCounts);
    },
    /**
     * Sets the default selected answer for an item based on its quantity type (by number or by description).
     * @param {Object} itemModItem The item modifier item to add default information for
     */
    updateAnswerByQuantityType(itemModItem) {
      const defaultDescriptor = this.defaultQuantityDescriptor(itemModItem);

      if (defaultDescriptor) {
        this.updateAnswers(
          itemModItem,
          defaultDescriptor.quantity,
          defaultDescriptor.id,
          defaultDescriptor.prohibitAlterations,
        );
      } else {
        this.updateAnswers(
          itemModItem,
          Math.min(Math.max(1, itemModItem.minimum || 0), itemModItem.maximum || 100),
        );
      }
    },
    /**
     * Attempts to find the default Quantity Description Info (qdi) for an item. These items will be choosable by Description
     * @param {Object} itemModItem The item modifier item we want the default descriptor for
     * @returns The default quantity descriptor for the item if it exists
     */
    defaultQuantityDescriptor(itemModItem) {
      let descriptionInfo;

      if (itemModItem.quantities) {
        descriptionInfo = itemModItem.quantities.find(qdi => {
          return qdi.quantity > 0 && qdi.defaultItem;
        });

        if (!descriptionInfo) {
          descriptionInfo = itemModItem.quantities.find(qdi => {
            return qdi.quantity > 0;
          });
        }
      }

      return descriptionInfo;
    },
    /**
     * Updates information about the item modifier so that we have all details available on the state.
     * @param {Object} itemModItem The item modifier that's being updated
     * @param {Number} quantity The item modifier's quantity
     * @param {String} quantityId The Id for the quantity descriptor
     * @param {boolean} prohibitAlterations True if the rules on the category prohibit further alterations
     */
    updateAnswers(itemModItem, quantity, quantityId, prohibitAlterations) {
      const updatedAnswer = {
        id: itemModItem.id,
        order: itemModItem.order,
        quantity,
        quantityId,
        prohibitAlterations,
      };

      this.updateAnswerDetails(itemModItem, updatedAnswer);
    },
    /**
     * Pulls together details about the item's answer and sets the details on the state
     * @param {Object} itemModItem The item being modified
     * @param {Object} answer The item's basic answer information
     */
    updateAnswerDetails(itemModItem, answer) {
      if (!answer) return;

      const defaultAnswer = this.itemModifiers.answers[itemModItem.id];
      const updatedAnswer = answer;
      updatedAnswer.default = Boolean(defaultAnswer);
      updatedAnswer.addition =
        (!defaultAnswer && answer.quantity > 0) ||
        (defaultAnswer && new BigNumber(defaultAnswer.quantity).comparedTo(answer.quantity) !== 0);
      updatedAnswer.subtraction =
        defaultAnswer && defaultAnswer.quantity > 0 && answer.quantity <= 0; // todo
      updatedAnswer.label = itemModItem.name;
      updatedAnswer.toggled = itemModItem.quantities
        ? Boolean(answer.quantityId)
        : answer.quantity > 0;

      if (updatedAnswer.default) {
        updatedAnswer.hidden =
          !(answer.addition || answer.subtraction) && !defaultAnswer.showDefault;
      } else {
        updatedAnswer.hidden = answer.subtraction;
      }
      if (answer.quantityId) {
        // Process an amount with a Quantity Descriptor
        const quantityDescriptor = itemModItem.quantities.find(quantityDesc => {
          return quantityDesc.id === answer.quantityId;
        });

        if (quantityDescriptor) {
          updatedAnswer.priceModifier =
            quantityDescriptor.quantity > 0 ? quantityDescriptor.price : 0;
          updatedAnswer.label = `${answer.label} (${quantityDescriptor.name})`;
        } else {
          updatedAnswer.priceModifier = 0;
        }
      } else if (itemModItem.freeQuantity && itemModItem.freeQuantity >= answer.quantity) {
        // Process a normal amount
        updatedAnswer.priceModifier = 0;
      } else if (itemModItem.freeQuantity) {
        updatedAnswer.priceModifier =
          Math.max(0, answer.quantity - itemModItem.freeQuantity) * itemModItem.price;
      } else {
        updatedAnswer.priceModifier = answer.quantity * itemModItem.price;
      }

      updatedAnswer.label = this.getLabel(updatedAnswer);

      this.setCurrentAnswer({ itemId: itemModItem.id, answer: updatedAnswer });
    },
    /**
     * Returns a label for the answer
     * @param {Object} answer The item's answer
     * @returns The label that should be used for this answer
     */
    getLabel(answer) {
      let label;
      if (answer.subtraction) {
        label = answer.default ? `NO ${answer.label}` : undefined;
      } else {
        label = `${answer.default ? '' : 'ADD '}${answer.label} ${
          answer.quantityId || new BigNumber(1).comparedTo(answer.quantity) === 0
            ? ''
            : `(${answer.quantity})`
        }`;
      }

      return label;
    },

    /**
     * Method to reset the item modifier selections.
     * Resets to default answers.
     * If editing, Resets to the initial modifier item variant.
     */
    resetSelected() {
      this.clearSelections();
      this.updateDefaultAnswers();
    },
  },
};
