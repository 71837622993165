<template>
  <!-- START : Promo Ribbon -->
  <div
    v-if="isRibbonVisible && !shouldHideItemPrices"
    class="wc-promo-ribbon py-1 px-2 font-size-xs rounded"
    :class="variant"
  >
    {{ textContent }}
  </div>
  <!-- END : Promo Ribbon -->
</template>

<script>
import HidePricingMixin from '@/mixins/HidePricingMixin';
import { PROMO_RIBBON_TYPE, RIBBON_CONFIG } from '../../constants/PromoRibbonConstants';
/**
 * The component is rendered based on the PROMO_RIBBON_TYPE.
 * The promo ribbon config is taken from the RIBBON_CONFIG constant based on the PROMO_RIBBON_TYPE
 */
export default {
  props: {
    product: {
      type: Object,
      required: true,
    },
    ribbonType: {
      type: String,
      validator: value => PROMO_RIBBON_TYPE[value],
      default: PROMO_RIBBON_TYPE.DISCOUNT_NAME_RIBBON,
    },
  },
  name: 'WCPromoRibbon',
  mixins: [HidePricingMixin],
  computed: {
    /**
     * Computed property to get ribbon config based on ribbon type
     * @returns {Object} - current ribbon config
     */
    ribbonConfig() {
      return RIBBON_CONFIG[this.ribbonType];
    },
    /**
     * Computed property to check if the promo ribbon has to be displayed
     * @returns {Boolean} - true | false - whether to show promo ribbon
     */
    isRibbonVisible() {
      return this.ribbonConfig.canDisplay(this.product);
    },
    /**
     * Computed property to get the promo ribbon text content
     * @returns {String} - ribbon text content
     */
    textContent() {
      return this.ribbonConfig.getTextContent(this.product);
    },
    /**
     * Computed property to get the variant of the promo ribbon
     * @returns {String} - ribbon variant based on the current ribbon type
     */
    variant() {
      return this.ribbonConfig.variant;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';
/*----- START : Promo Ribbon Styles -----*/
.wc-promo-ribbon {
  display: inline-block;

  &.info {
    background-color: var(--info, $info);
    color: var(--info-contrast, get-color-contrast('info'));
  }
  &.secondary {
    background-color: var(--secondary, $secondary);
    color: var(--secondary-contrast, get-color-contrast('secondary'));
  }
}
/*----- END : Promo Ribbon Styles -----*/
</style>
