<template>
  <div>
    <div class="wc-store-select-header">
      <div class="wc-store-select-row" :class="{ 'mt-2 mx-2': isMobile }">
        <div
          :aria-label="$t('goBack')"
          class="wc-store-select-back text-primary"
          @click="$emit('showAllStores')"
        >
          <font-awesome-icon class="ml-2" icon="arrow-left" size="2x" />
          <span class="my-auto ml-2">{{ $t('goBack') }}</span>
        </div>

        <div @click="dismiss(MODAL_EVENTS.DISMISS)">
          <font-awesome-icon
            :aria-label="$t('exit')"
            class="wc-store-select-exit-inner mr-2 text-primary"
            icon="xmark"
            size="2x"
          />
        </div>
      </div>

      <div class="text-center mb-2">
        <div class="font-weight-bold font-size-xl">{{ $t('whereWouldYouLikeToShop') }}</div>
      </div>
    </div>

    <div
      :class="{
        'wc-store-select-body wc-store-select-border-radius': !isMobile,
        'wc-store-select-body-mobile': isMobile,
      }"
    >
      <div
        class="p-3"
        :class="{
          'wc-store-select-header-row wc-select-store-header': !isMobile,
          'wc-select-store-header-mobile wc-store-select-row-mobile wc-store-header-mobile-border': isMobile,
        }"
      >
        <div class="text-center">
          <label for="zipcode-search" class="font-weight-bold mb-2">{{
            $t('searchByZipcode')
          }}</label>
          <WCSearchInput
            id="zipcode-search"
            :ariaLabel="$t('zipCode').toLowerCase()"
            :placeHolderText="$t('zipCode').toLowerCase()"
            @focusIn="hasFocus = true"
            @focusOut="hasFocus = false"
            :hasFocusInput="hasFocus"
            @updateValue="updateSearchValue"
            @onClear="updateSearchValue"
            @onSearch="search()"
            @onKeyDown="search()"
          />
          <div aria-live="assertive">
            <WCValidationMessage
              data-testid="zipcode-error"
              class="mt-2"
              v-if="searchString && $v.searchString.$invalid"
            >
              {{ $t('zipCodeInvalid') }}
            </WCValidationMessage>
            <WCValidationMessage
              data-testid="geocode-error"
              v-else-if="geocodeError && geocodeError.search === searchString"
              class="mt-2"
            >
              {{ geocodeError.message }}
            </WCValidationMessage>
          </div>
        </div>
        <div class="text-center">
          <span>{{ $t('or').toLowerCase() }}</span>
        </div>
        <div class="text-center">
          <font-awesome-icon class="mr-2" icon="location-dot" />
          <span
            :aria-label="$t('SearchByLocation')"
            class="wc-store-select-by-current-location"
            @click="sortStoresAroundUser"
            >{{ $t('SearchByLocation') }}</span
          >
        </div>
      </div>

      <div v-if="stores" class="wc-select-store-overflow wc-store-select-header-border">
        <WCRadioButtonGroup>
          <div class="py-2 wc-store-select-border" v-for="store in stores" :key="store.id">
            <WCRadioButton class="ml-2" name="store" :id="store.id" v-model="selectedStore">
              <WCStoreRow
                :store="store"
                :selectedStoreId="selectedStore"
                :isMobile="isMobile"
              ></WCStoreRow>
            </WCRadioButton>
          </div>
        </WCRadioButtonGroup>
      </div>

      <div class="wc-select-store-buttons">
        <button
          :aria-label="$t('shopStore')"
          class="btn btn-primary w-100"
          @click="redirectToSelectedStore"
        >
          {{ $t('shopStore') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import UserMixin from '@/modules/user/mixins/UserMixin';
import FocusMixin from '@/mixins/FocusMixin';
import WCValidationMessage from '@/modules/forms/components/WCValidationMessage/WCValidationMessage.vue';
import ModalMixin from '@/modules/modals/mixins/ModalMixin';
import WCRadioButton from '@/modules/forms/components/WCRadioButton/WCRadioButton.vue';
import SpinnerService from '@/services/spinner.service';
import { postal } from '@/utils/validators/validators';
import { maxLength } from 'vuelidate/lib/validators';
import MapService from '@/services/map.service';
import WCRadioButtonGroup from '@/modules/forms/components/WCRadioButtonGroup/WCRadioButtonGroup.vue';
import { MODAL_EVENTS } from '@/constants/EventConstants';
import WCSearchInput from '@/components/WCSearchInput/WCSearchInput.vue';
import { PlatformMixin } from '@/modules/platform';
import WCStoreRow from '../WCStoreRow/WCStoreRow.vue';

export default {
  mixins: [PlatformMixin, ModalMixin, FocusMixin, UserMixin],
  components: { WCStoreRow, WCRadioButton, WCSearchInput, WCRadioButtonGroup, WCValidationMessage },
  data() {
    return {
      MODAL_EVENTS,
      stores: [],
      selectedStore: {},
      hasFocus: false,
      mapService: null,
      searchString: '',
      geocodeError: null,
    };
  },
  validations: {
    searchString: {
      postal,
      maxLength: maxLength(5),
    },
  },
  async mounted() {
    try {
      SpinnerService.open();
      this.mapService = new MapService();
      const storesResponse = await axios.get('api/stores');
      this.stores = storesResponse.data.data;

      this.moveCurrentStoreToFrontOfStores();
      this.selectedStore = this.stores[0]?.id;
    } catch (error) {
      console.log(error);
    } finally {
      SpinnerService.close();
    }
  },
  async beforeDestroy() {
    if (this.mapService) {
      await this.mapService.unload();
    }
  },
  methods: {
    moveCurrentStoreToFrontOfStores() {
      const currentStore = this.stores.find(store => store.id === this.$configuration.store.id);
      const filteredStoreArray = this.stores.filter(
        store => store.id !== this.$configuration.store.id,
      );
      filteredStoreArray.unshift(currentStore);
      this.stores = filteredStoreArray;
    },
    updateSearchValue(searchValue) {
      this.searchString = searchValue;
    },
    sortStoresAroundUser() {
      try {
        MapService.geolocate().then(location => {
          this.updateOrigin({
            latitude: location.latitude,
            longitude: location.longitude,
          });
        });
      } catch (error) {
        console.log(error);
      }
    },
    search() {
      try {
        if (this.searchString && !this.$v.searchString.$invalid) {
          this.mapService.load().then(() => {
            this.mapService
              .geocode(this.searchString)
              .then(location => {
                this.updateOrigin(location);
                this.geocodeError = null;
              })
              .catch(e => {
                this.geocodeError = { message: e.message, search: this.searchString };
              });
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    updateOrigin(origin) {
      try {
        this.mapService.load().then(() => {
          this.stores.forEach(store => {
            if (store.latitude && store.longitude) {
              const position = new google.maps.LatLng(store.latitude, store.longitude);
              const distance = MapService.distance(origin, {
                latitude: position.lat(),
                longitude: position.lng(),
              });
              this.$set(store, 'distance', distance);
            }
          });

          this.sortStoresByDistance();
        });
      } catch (error) {
        console.log(error);
      }
    },
    sortStoresByDistance() {
      this.stores.sort((a, b) => {
        if (a.distance === undefined) return 1;
        if (b.distance === undefined) return -1;
        return a.distance - b.distance;
      });
    },
    redirectToSelectedStore() {
      if (this.$configuration.store.id !== this.selectedStore) {
        this.$router.push(`/redirect-to/${this.selectedStore}`);
      } else {
        this.dismiss(MODAL_EVENTS.DISMISS);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

.wc-store-select-header {
  display: flex;
  flex-direction: column;
}

.wc-store-header-mobile-border {
  border-top: rem(1px) solid var(--gray-500);
}

.wc-store-select-border {
  border-bottom: rem(1px) solid var(--gray-500);
}

.wc-store-select-header-border {
  border-top: rem(3px) solid var(--primary, $primary);
}

.wc-store-select-exit {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.wc-store-select-back {
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

.wc-store-select-by-current-location {
  cursor: pointer;
  text-decoration: underline;
}

.wc-store-select-exit-inner {
  float: right;
  cursor: pointer;
}

.wc-store-select-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.wc-store-select-header-row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.wc-store-select-row-mobile {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.wc-store-select-body {
  border: rem(1px) solid var(--gray-700, $gray-700);
  display: flex;
  flex-direction: column;
  box-shadow: 0px 3px 6px #00000029;
}

.wc-store-select-body-mobile {
  display: flex;
  flex-direction: column;
}

.wc-store-select-border-radius {
  border-radius: 0.4rem;
}

.wc-select-store-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 1rem;
  background-color: var(--light-lighter-4);
  border-bottom-left-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;
  border-top: rem(3px) solid var(--primary, $primary);
}

.wc-select-store-header {
  background-color: var(--light-lighter-4);
  border-top-left-radius: 0.4rem;
  border-top-right-radius: 0.4rem;
}

.wc-select-store-header-mobile {
  background-color: var(--light-lighter-4);
}

.wc-select-store-overflow {
  height: rem(300px);
  overflow-y: auto;
}

.wc-search-area {
  background-color: white;
  .wc-search-box {
    &.form-control:focus {
      box-shadow: none;
    }
  }
}
</style>
