<template>
  <div>
    <div v-if="!isMoreInfoExpanded && isValidCoupon" class="row m-0">
      <div class="font-size-xs text-wrap pr-1">{{ shortCouponDescription }}</div>
      <div v-if="coupon.description.length > BODY_MAX_SIZE" class="font-size-xs text-wrap pr-1">
        ...
      </div>
      <button
        v-if="coupon.description.length > BODY_MAX_SIZE || coupon.requirements"
        class="btn btn-link m-0 p-0 font-size-xs text-wrap border-0"
        @click="toggleMoreInfo"
      >
        {{ $t('moreDetails') }}
      </button>
    </div>
    <div v-if="isMoreInfoExpanded" class="font-size-xs text-wrap">
      <span class="font-size-xs text-wrap">
        {{ coupon.description }}
      </span>
      <div class="d-flex row m-0 mt-2">
        <span v-if="coupon.requirements" class="pr-1">{{ coupon.requirements }}</span>
        <button
          class="font-size-xs btn btn-link m-0 p-0 text-wrap border-0"
          @click="toggleMoreInfo"
        >
          {{ $t('lessDetails') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WCCouponDetailModalBody',
  props: {
    coupon: {
      type: Object,
    },
  },
  data() {
    return {
      isMoreInfoExpanded: false,
      BODY_MAX_SIZE: 120,
    };
  },
  methods: {
    toggleMoreInfo() {
      this.isMoreInfoExpanded = !this.isMoreInfoExpanded;
    },
  },
  computed: {
    shortCouponDescription() {
      const couponDescription = this.coupon?.description;
      return couponDescription?.substring(
        0,
        couponDescription?.length > this.BODY_MAX_SIZE
          ? this.BODY_MAX_SIZE
          : couponDescription?.length,
      );
    },
    isValidCoupon() {
      return this.coupon?.description?.length;
    },
  },
};
</script>
