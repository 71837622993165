<template>
  <DashboardSidebar>
    <DashboardSidebarLink to="/employee/cs">
      {{ $t('associateAsCustomer') }}
    </DashboardSidebarLink>

    <DashboardSidebarLink :href="`api/employee/logs.zip`" target="_self">
      <template v-slot:native>{{ $t('downloadLogs') }}</template>
    </DashboardSidebarLink>

    <li class="unstyled-list-item">
      <DashboardSidebarButton @click="replicateNow">
        {{ $t('replicateNow') }}
      </DashboardSidebarButton>
    </li>

    <li class="unstyled-list-item">
      <DashboardSidebarButton @click="logout">
        {{ $t('logOut') }}
      </DashboardSidebarButton>
    </li>
  </DashboardSidebar>
</template>

<script>
import UserMixin from '@/modules/user/mixins/UserMixin';
import DashboardSidebarLink from '@/layouts/components/Dashboard/DashboardSidebarLink.vue';
import DashboardSidebarButton from '@/layouts/components/Dashboard/DashboardSidebarButton.vue';
import DashboardSidebar from '@/layouts/components/Dashboard/DashboardSidebar.vue';
import EmployeeMixin from '@/modules/employee/mixins/EmployeeMixin';

export default {
  name: 'WCEmployeeSidebar',
  components: { DashboardSidebar, DashboardSidebarLink, DashboardSidebarButton },
  mixins: [UserMixin, EmployeeMixin],
};
</script>

<style lang="scss" scoped>
.wc-sidebar__link {
  text-decoration: none;
  display: block;

  &--active {
    background-color: var(--light);
    font-weight: bold;
  }
}
.unstyled-list-item {
  list-style-type: none;
}
</style>
