<template>
  <div class="font-size-sm">
    <router-link
      ref="wc-header-store-label__link"
      class="underline-link-from-center"
      to="/select-store"
    >
      <font-awesome-icon :icon="['fa', 'map-location-dot']" class="font-size-xl" />
      <span class="font-weight-bold ml-2">{{ $t('shoppingAt') }}</span>
      <span> {{ getStoreName }}</span>
      <span v-if="isHomeStore">{{ $t('homeStoreAlt') }}</span>
    </router-link>
    <span v-if="!isHomeStore">
      <WCToolTip
        target="wc-header-store-label__link"
        :position="toolTipPosition"
        class="font-size-sm"
      >
        {{ $t('notYourHomeStore') }}
      </WCToolTip>
    </span>
  </div>
</template>

<script>
import WCToolTip from '@/components/WCToolTip/WCToolTip.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    WCToolTip,
  },
  props: {
    toolTipPosition: {
      type: String,
      default: 'right',
    },
  },
  computed: {
    ...mapGetters({ getUser: 'user/getUser' }),
    getStoreName() {
      return `${this.$configuration.store.name}`;
    },
    isHomeStore() {
      return this.getUser?.home === this.$configuration.store.id;
    },
  },
};
</script>
