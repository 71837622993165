<template>
  <div class="card wc-item-linked-item" :class="{ 'wc-item-linked-item--active': selected }">
    <platform-renderer>
      <template v-slot:onMobile>
        <div class="d-flex flex-column justify-content-between h-100">
          <!-- START : Top Section -->
          <div>
            <!-- START : Button Section -->
            <button
              class="item-select unstyled-btn mt-2 mr-1"
              :disabled="prohibitAlterations"
              @click="toggle"
            >
              <font-awesome-icon
                v-if="!selected && !prohibitAlterations"
                icon="plus"
                size="lg"
                class="text-primary"
                :title="$t('increment')"
              />
              <font-awesome-icon
                v-else-if="!prohibitAlterations"
                icon="trash"
                size="lg"
                class="text-primary"
                :title="$t('remove')"
              />
            </button>
            <!-- END : Button Section -->
            <!-- START : Item Image Section -->
            <div
              class="wc-item-image-wrapper p-1"
              :class="{ 'wc-item-image-wrapper--active': selected }"
            >
              <WCImage
                :imageSrc="getProductImage(linkedItem.image)"
                :imageAlt="productImgAltText(linkedItem.name)"
                imageClass="wc-item-image"
                defaultClass="wc-item-image font-size-6xl"
                :defaultTitle="noProductImageAltText(linkedItem.name)"
              />
            </div>
            <!-- END : Item Image Section -->
            <!-- START : Item Details Section -->
            <div class="wc-item-name-wrapper clearfix p-1">
              <div class="small card-text font-weight-bold">
                {{ linkedItem.name }}
              </div>
              <div class="small text-muted card-text">+{{ linkedItem.price | currency }}</div>
            </div>
            <!-- END : Item Details Section -->
          </div>
          <!-- END : Top Section -->
          <!-- START : Prohibit Alterations -->
          <WCProhibitAlternativeMsg
            v-if="prohibitAlterations"
            class="w-100 justify-content-center mb-1"
          />
          <!-- END : Prohibit Alterations -->
        </div>
      </template>
      <template v-slot:onDesktop>
        <button role="checkbox" :aria-checked="selected" @click="toggle" class="unstyled-btn p-0">
          <!-- START : Top Section -->
          <div class="d-flex flex-column h-100 justify-content-between">
            <!-- START : Section Indication -->
            <div class="d-flex justify-content-between w-100">
              <font-awesome-icon
                v-if="selected"
                icon="square-check"
                class="position-absolute fa-lg align-self-start text-primary"
                :title="$t('selected')"
              />
            </div>
            <!-- END : Section Indication -->
            <!-- START : Item Image Section -->
            <div
              class="wc-item-image-wrapper p-1"
              :class="{ 'wc-item-image-wrapper--active': selected }"
            >
              <WCImage
                :imageSrc="getProductImage(linkedItem.image)"
                :imageAlt="productImgAltText(linkedItem.name, linkedItem.brand)"
                imageClass="wc-item-image"
                defaultClass="wc-item-image font-size-6xl"
                :defaultTitle="noProductImageAltText(linkedItem.name)"
              />
            </div>
            <!-- END : Item Image Section -->
            <!-- START : Item Details Section -->
            <div class="wc-item-name-wrapper clearfix p-1">
              <span class="small float-left card-text font-weight-bold">
                {{ linkedItem.name }}
              </span>
              <span class="small float-right text-muted card-text">
                +{{ linkedItem.price | currency }}
              </span>
            </div>
            <!-- END : Item Details Section -->
          </div>
          <!-- END : Top Section -->
          <!-- START : Prohibit Alterations -->
          <WCProhibitAlternativeMsg
            v-if="prohibitAlterations"
            class="w-100 justify-content-center mb-1"
          />
          <!-- END : Prohibit Alterations -->
        </button>
      </template>
    </platform-renderer>
  </div>
</template>
<script>
import PlatformRenderer from '@/modules/platform/components/PlatformRenderer';
import ItemModifiersMixin from '@/modules/itemModifiers/mixins/ItemModifierMixin';
import ProductImageInfoMixin from '@/mixins/ProductImageInfoMixin';
import WCProhibitAlternativeMsg from '@/modules/itemModifiers/components/WCProhibitAlternativeMsg/WCProhibitAlternativeMsg.vue';
import WCImage from '@/components/WCImage/WCImage.vue';

export default {
  name: 'WCItemModifierLinkedItem',
  props: {
    linkedItem: {},
    prohibitAlterations: {
      type: Boolean,
    },
  },
  components: {
    PlatformRenderer,
    WCProhibitAlternativeMsg,
    WCImage,
  },
  mixins: [ItemModifiersMixin, ProductImageInfoMixin],

  methods: {
    toggle() {
      if (!this.prohibitAlterations) {
        this.toggleModifier(this.linkedItem, this.linkedItem);
      }
    },
  },
  computed: {
    selected() {
      if (this.currentAnswers[this.linkedItem.id]) {
        return (
          this.currentAnswers[this.linkedItem.id] &&
          this.currentAnswers[this.linkedItem.id].quantity > 0
        );
      }
      if (this.itemModifiers.answers[this.linkedItem.id]) {
        return (
          this.itemModifiers.answers[this.linkedItem.id] &&
          this.itemModifiers.answers[this.linkedItem.id].quantity > 0
        );
      }
      return false;
    },
    price() {
      if (this.linkedItem.price) {
        return this.linkedItem.price;
      }
      return 0;
    },
  },
};
</script>
<style scoped lang="scss">
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

.wc-item-linked-item {
  min-height: 13rem;
  max-width: 10rem;
  white-space: normal;
  &--active {
    border-color: var(--primary, $primary);
  }
}

.wc-item-image-wrapper {
  border-bottom: 1px solid lightgray;

  &--active {
    border-color: var(--primary, $primary);
  }
}

.wc-item-image {
  display: block;
  object-fit: scale-down;
  max-height: rem(125px);
  min-height: rem(125px);
  max-width: rem(150px);
  margin: auto;
  align-self: center;
}

.wc-item-name-wrapper {
  display: grid;
  grid-template-columns: 2fr 1fr;
}

@include media-breakpoint-down(md) {
  .wc-item-linked-item {
    box-shadow: $default-box-shadow;
    min-height: unset;
    max-width: unset;

    .item-select {
      position: absolute;
      z-index: 1;
      right: 0;
      top: 0;
    }
    &--active {
      box-shadow: 0px 3px 6px var(--primary-lighter-6, get-color('primary', 'lighter-6'));
    }
  }
  .wc-item-image-wrapper {
    margin-top: 2rem;
    border-bottom: none;
    width: 100%;
    height: 6rem;

    .wc-item-image {
      width: 100%;
      height: 100%;
      max-width: unset;
      min-width: unset;
      max-height: unset;
      min-height: unset;
      object-fit: contain;
    }
  }
  .wc-item-name-wrapper {
    display: block;
    text-align: center;
  }
}
</style>
