<template>
  <!-- Cleart cart Modal - either to remove all cart items or save all cart items later -->
  <WCModal position="center">
    <WCModalHeader @dismiss="dismiss('Dismissed')" class="d-flex align-items-center">
      <h3 class="mb-0">
        <font-awesome-icon icon="cart-xmark" class="mr-1 font-size-lg" />
        {{ allCarts ? $t('clearCarts') : $t('clearCart') }}
      </h3>
      <button class="btn btn-link underline-link-from-center" @click="dismiss('Dismissed')">
        {{ $t('cancel') }}
      </button>
    </WCModalHeader>
    <WCModalBody>
      <p v-if="!allCarts">
        {{ showSaveForLaterOption ? $t('clearOrSaveText') : $t('clearAllText') }}
      </p>
      <p v-else-if="allCarts">{{ $t('clearAllCarts') }}</p>
    </WCModalBody>
    <WCModalFooter>
      <button @click="clearCartItems()" class="btn btn-outline-primary clear-cart-btn">
        {{ allCarts ? $t('removeAllItems') : $t('removeItems') }}
      </button>
      <button
        @click="saveAllLater()"
        v-if="showSaveForLaterOption"
        class="btn btn-outline-dark save-later-btn"
      >
        {{ $t('saveItemsForLater') }}
      </button>
    </WCModalFooter>
  </WCModal>
</template>

<script>
import { mapActions } from 'vuex';
import ModalMixin from '@/modules/modals/mixins/ModalMixin';
import WCModal from '@/modules/modals/components/WCModal/WCModal.vue';
import WCModalHeader from '@/modules/modals/components/WCModalHeader/WCModalHeader.vue';
import WCModalBody from '@/modules/modals/components/WCModalBody/WCModalBody.vue';
import WCModalFooter from '@/modules/modals/components/WCModalFooter/WCModalFooter.vue';
import ToastService from '@/modules/toasts/services/ToastService';
import WCAddToListToast from '@/modules/lists/components/WCAddToListToast/WCAddToListToast.vue';
import WCSimpleToast from '@/modules/toasts/components/WCSimpleToast/WCSimpleToast.vue';
import ItemAddToListMixin from '@/modules/lists/mixins/ItemAddToListMixin';

export default {
  mixins: [ModalMixin, ItemAddToListMixin],
  components: { WCModal, WCModalHeader, WCModalBody, WCModalFooter },
  props: {
    lineItems: {
      type: Array,
    },
    carts: {
      type: Array,
    },
    isGuest: {
      type: Boolean,
    },
    isGiftCard: {
      type: Boolean,
    },
    allCarts: {
      type: Boolean,
    },
  },
  computed: {
    /**
     * For Guest and Giftcard flow, hide the save for later button
     */
    showSaveForLaterOption() {
      return !(this.isGuest || this.isGiftCard) && !this.allCarts;
    },
  },
  methods: {
    ...mapActions({
      removeAllCartItems: 'cart/removeAllCartItems',
      motRemoveAllCartItems: 'cart/motRemoveAllCartItems',
      loadDetails: 'lists/loadDetails',
      getCarts: 'cart/getCarts',
    }),
    /**
     * Method to add all items to Save for Later list
     */
    async saveAllLater() {
      try {
        const toListItems = [];
        this.carts?.forEach(cart =>
          cart.lineItems.forEach(item => {
            toListItems.push(item);
          }),
        );
        const list = await this.addItemsToList({
          list: { name: 'Save for later', type: 'SAVE_FOR_LATER' },
          items: this.allCarts ? toListItems : this.lineItems,
        });
        if (this.$configuration.orderTypesEnabled && this.allCarts) {
          this.motRemoveAllCartItems();
        } else {
          this.removeAllCartItems();
        }
        this.loadDetails({ list });
        ToastService.open(WCAddToListToast, {
          props: {
            variant: 'favorite',
            title: this.$t('itemAddedToSaveForLater'),
            list,
            close: {},
            dismiss: {},
          },
          timeout: 2000,
        });
      } catch (error) {
        ToastService.open(WCSimpleToast, {
          props: {
            variant: 'danger',
            title: this.$t('error'),
            message: error.message,
          },
          timeout: 7000,
        });
      } finally {
        await this.close();
      }
    },
    /**
     * Method to clear all cart items
     */
    async clearCartItems() {
      if (!this.$configuration.orderTypesEnabled || !this.allCarts) {
        await this.removeAllCartItems();
      } else if (this.$configuration.orderTypesEnabled) {
        await this.motRemoveAllCartItems();
        await this.getCarts();
      }
      await this.close();
    },
  },
};
</script>
