<template>
  <!-- START : Sub Page Header Wrap -->
  <div class="wc-mobile-subpage-header container-fluid text-primary-contrast p-3">
    <!-- customClick prop - to have custom event on clicking go back icon -->
    <button
      class="unstyled-btn"
      @click="!customClick ? $router.go(-1) : customEvent()"
      :aria-label="$t('goBack')"
    >
      <font-awesome-icon class="font-size-xl" icon="arrow-left" />
    </button>
    <h1 class="ml-3 mb-0 wc-account-subpage-header__text ">{{ title }}</h1>
  </div>
  <!-- END : Sub Page Header Wrap -->
</template>

<script>
export default {
  name: 'WCMobileSubpageHeader',
  props: {
    title: {
      type: String,
      required: true,
    },
    customClick: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    /**
     * To have custom event on clicking go back
     * - particularly for mobile see more popup
     */
    customEvent() {
      this.$emit('customClickEvent');
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

.wc-mobile-subpage-header {
  display: flex;
  align-items: center;
  background-color: var(--primary, get-color('primary'));
}
</style>
