<template>
  <div class="wc-gift-card col-12">
    <div class="row" v-if="giftCard.design">
      <div class="col-sm-4 text-center">
        <WCImage
          :imageSrc="`${$configuration.cdn}/api/img/${giftCard.design.image}`"
          :imageAlt="$t('giftCardImgAltText')"
          imageClass="wc-gift-card__design-image"
          imageStyle="max-width: 100%;"
          defaultClass="font-size-6xl"
          id="gift-card__img"
        />
      </div>
      <div class="wc-gift-card__text font-size-sm col-sm-8">
        <h3>{{ $t('eGiftCard') }}</h3>

        <div class="d-flex font-size-sm justify-content-between wc-gift-card__text">
          <span v-if="giftCard.recipientName"
            ><b class="pr-1">{{ $t('toCap') }}:</b>{{ giftCard.recipientName }},<b class="pr-1">
              {{ $t('from') }}:</b
            >{{ giftCard.senderName }}</span
          >
          <div class="text-right px-3">
            {{ giftCard.amount | currency }}
          </div>
        </div>

        <div v-if="giftCard.recipientEmail">
          <b class="pr-1">{{ $t('emailTo') }}:</b>{{ giftCard.recipientEmail }}
        </div>
        <div v-if="giftCard.recipientPhoneNumber">
          <b class="pr-1">{{ $t('textTo') }}:</b>{{ giftCard.recipientPhoneNumber }}
        </div>
        <div v-if="giftCard.print">
          <b>{{ $t('selectedToPrint') }}</b>
        </div>
        <div v-if="giftCard.sendDate">
          <b class="pr-1">{{ $t('sendOn') }}:</b>{{ formattedDate }}
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-end">
      <button
        class="btn btn-link underline-link-from-center link-primary text-body"
        @click="removeGiftCard(giftCard)"
      >
        <font-awesome-icon class="mr-1" icon="trash" />{{ $t('removeFromCart') }}
      </button>
    </div>
  </div>
</template>
<script>
import CartControlsMixin from '@/modules/cart/mixins/CartControls/CartControls';
import { format } from 'date-fns';
import WCImage from '@/components/WCImage/WCImage.vue';

export default {
  name: 'WCCartDrawerGiftCardItem',
  components: {
    WCImage,
  },
  mixins: [CartControlsMixin],
  props: {
    giftCard: { type: Object },
  },
  computed: {
    formattedDate() {
      return format(new Date(this.giftCard.sendDate), 'MM/dd/yyyy h:mm a');
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

.wc-gift-card {
  margin: $margin-3 auto;
  padding: $pad-3 * 0.75;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  min-width: 100%;
}
.wc-gift-card__design-image {
  margin-bottom: $margin-3 * 0.75;
  border-radius: 5px;
  height: 5rem;
  max-width: 100%;
}

.wc-gift-card__text {
  text-overflow: ellipsis;
}
</style>
