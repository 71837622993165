<template>
  <div>
    <button
      class="btn py-0 px-1 border-0"
      :class="isNextWeightedQuantityDecrementInCartValid(item) ? 'text-primary' : 'text-muted'"
      @click.stop="decrementWeightedQuantity(item)"
      :disabled="!isNextWeightedQuantityDecrementInCartValid(item)"
      :aria-label="$t('decreaseQuantity')"
      ref="decrementWeightButtonRef"
      :aria-describedby="'decrement items weighted quantity'"
    >
      <font-awesome-icon
        v-if="getNextWeightedQuantityDecrementInCart(item).isZero()"
        icon="trash"
        size="sm"
      />
      <font-awesome-icon v-else icon="minus" size="sm" />
    </button>
    <span class="wc-atc-btn__quantitiy-label px-2"
      ><!-- Screen reader only visible label for quantity -->
      <span class="sr-only"> {{ $t('quantity') }}</span
      >{{ getDisplayWeightedQuantityInCart(item, variation) }}</span
    >
    <button
      class="btn py-0 px-1 border-0"
      :class="isNextWeightedQuantityIncrementInCartValid(item) ? 'text-primary' : 'text-muted'"
      @click.stop="incrementWeightedQuantity(item, variation)"
      :disabled="!isNextWeightedQuantityIncrementInCartValid(item)"
      :aria-label="$t('increaseQuantity')"
      ref="incrementWeightButtonRef"
      :aria-describedby="'increment items weighted quantity'"
    >
      <font-awesome-icon icon="plus" size="sm" />
    </button>
  </div>
</template>

<script>
import CartControls from '@/modules/cart/mixins/CartControls/CartControls';

export default {
  name: 'WCAddToCartWeightedQuantityAdjuster',
  mixins: [CartControls],
};
</script>
<style scoped lang="scss">
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';
</style>
