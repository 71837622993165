<template>
  <button
    class="wc-sidebar__button btn btn-link underline-link-from-center text-left ml-0 mb-3"
    v-on="$listeners"
  >
    <slot>
      <!-- Pass the internationalized button name -->
    </slot>
  </button>
</template>

<script>
export default {
  name: 'DashboardSidebarButton',
};
</script>

<style scoped lang="scss">
.wc-sidebar__button {
  text-decoration: none;
  display: block;
}
</style>
