import { isMobile, isMediumDevice, isDesktop } from '../utils/platform';

export default {
  data() {
    return {
      onResize: null,
      isMobile: isMobile(),
      isMediumDevice: isMediumDevice(),
      isDesktop: isDesktop(),
    };
  },
  methods: {
    setDeviceType() {
      this.isMobile = isMobile();
      this.isMediumDevice = isMediumDevice();
      this.isDesktop = isDesktop();
    },
  },
  created() {
    window.addEventListener('resize', this.setDeviceType);
  },
  destroyed() {
    window.removeEventListener('resize', this.setDeviceType);
  },
};
