<!-- Collapsible languages section in mobile sidebar -->
<template>
  <WCSideMenuCollapsibleTemplate>
    <template v-slot:menuHeader v-if="$configuration.i18nEnabled">
      <h3 class="mb-0 text-dark" fixed>
        {{ $t('chooseLanguage') }}
      </h3>
    </template>
    <template v-slot:menuBody>
      <ul>
        <WCSideMenuItem
          v-for="locale in activeLocales"
          :key="locale.code"
          @click.native="onSwitchLocale(locale)"
          tabindex="0"
        >
          {{ locale.name }}
        </WCSideMenuItem>
      </ul>
    </template>
  </WCSideMenuCollapsibleTemplate>
</template>

<script>
import LanguagesMixin from '../../../platform/mixins/LanguagesMixin';
import WCSideMenuCollapsibleTemplate from '../WCSideMenuCollapsibleTemplate/WCSideMenuCollapsibleTemplate.vue';
import WCSideMenuItem from '../WCSideMenuItem/WCSideMenuItem.vue';

export default {
  components: { WCSideMenuCollapsibleTemplate, WCSideMenuItem },
  /**
   * mixin for Locales and switchLocale
   */
  mixins: [LanguagesMixin],
  methods: {
    onSwitchLocale(locale) {
      this.switchLocale(locale);
    },
  },
};
</script>

<style lang="scss" scoped>
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
</style>
