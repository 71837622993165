import Vue from 'vue';
import { v4 as uuid } from 'uuid';

export default new Vue({
  data() {
    return {
      alerts: [],
    };
  },
  methods: {
    show(component, options = { props: {}, timeout: 0 }) {
      return new Promise((resolve, reject) => {
        const id = uuid();
        const { props, timeout } = options;
        const alert = {
          id,
          component,
          props,
          close: value => {
            this.alerts = this.alerts.filter(a => a.id !== id);
            resolve(value);
          },
          dismiss: reason => {
            this.alerts = this.alerts.filter(a => a.id !== id);
            reject(reason);
          },
        };

        this.alerts.unshift(alert); // Push onto front to reverse order of alerts

        if (timeout) {
          setTimeout(() => alert.dismiss('Timeout'), timeout);
        }
      });
    },
  },
});
