<template>
  <div class="wc-layout wc-layout--mobile-account-subpage">
    <WCMobileHeader />
    <div :class="mobileHeaderStyles" role="main">
      <WCMobileSubpageHeader :title="$route.meta.title ? $route.meta.title : $route.name" />
      <div class="wc-layout__body">
        <div class="">
          <router-view />
        </div>
      </div>
    </div>
    <WCEmployeeAssociationBanner v-if="isEmployee && isCustomer" />
    <WCMobileNavigation v-else-if="!isEmployee" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import WCMobileSubpageHeader from '@/components/WCMobileSubpageHeader/WCMobileSubpageHeader.vue';
import WCMobileHeader from '@/components/WCMobileHeader/WCMobileHeader.vue';
import WCMobileNavigation from '@/components/WCMobileNavigation/WCMobileNavigation.vue';
import EmployeeMixin from '@/modules/employee/mixins/EmployeeMixin';
import WCEmployeeAssociationBanner from '@/modules/employee/components/WCEmployeeAssociationBanner/WCEmployeeAssociationBanner.vue';

export default {
  name: 'MobileAccountSubpage',
  components: {
    WCMobileSubpageHeader,
    WCMobileHeader,
    WCMobileNavigation,
    WCEmployeeAssociationBanner,
  },
  mixins: [EmployeeMixin],
  computed: {
    ...mapGetters({
      isScanAndGoMode: 'user/isCustomerModeScan',
    }),
    /**
     * Property to check if multistore
     */
    isMultiStore() {
      return this.$configuration.multistore;
    },
    mobileHeaderStyles() {
      return {
        'account-page__body': this.isMultiStore,
        'wc-single-store-layout__body': !this.isMultiStore,
        'account-page__body-scan-mode': this.isScanAndGoMode,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/_tools.scss';
@import '~@/assets/styles/_settings.scss';

.account-page__body {
  padding-top: $mobile-header-padding-top;
  padding-bottom: $mobile-body-padding-bottom;
}

.account-page__body-scan-mode {
  padding-top: $mobile-header-padding-top - 5.4rem;
}

.wc-single-store-layout__body {
  padding-top: $mobile-header-padding-top - 2rem;
  padding-bottom: $mobile-body-padding-bottom;
}

.wc-layout--mobile-account-subpage {
  .wc-layout__body {
    padding-bottom: $pad-3 * 3.7;
  }
}
</style>
