var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('platform-renderer',{scopedSlots:_vm._u([{key:"onMobile",fn:function(){return [_c('div',{staticClass:"mobile-item-modifier"},[_c('div',{staticClass:"selections-wrap pb-3"},[_c('WCItemModifierAnswers',{attrs:{"item":_vm.item,"itemModifiers":_vm.itemModifiers,"visibleAnswers":_vm.visibleAnswers},on:{"clearAll":function($event){return _vm.$emit('clearAll')}}}),_c('div',{staticClass:"d-flex justify-content-end mx-3 mb-2"},[_c('button',{staticClass:"unstyled-btn text-primary font-size-lg font-weight-bold",attrs:{"type":"button"},on:{"click":function($event){return _vm.addInstructions()}}},[_c('font-awesome-icon',{staticClass:"font-size-base",attrs:{"icon":"plus"}}),_vm._v(" "+_vm._s(_vm.$t('addSpecialInstructions'))+" ")],1)]),_c('div',{staticClass:"selections__footer d-flex justify-content-between align-items-center mx-3"},[_c('div',{staticClass:"font-size-lg font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('total'))+": "),_c('span',{staticClass:"ml-3"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.calculateSelectionTotal))+" ")])]),_vm._t("addToCartBtn")],2)],1),_c('WCCollapseCardGroup',{attrs:{"count":_vm.categories.length,"initialActiveIdx":1,"oneActiveCard":true,"hasBorderRadius":false}},[_c('WCCollapseCard',{staticClass:"w-100",attrs:{"bodyClass":"p-0 mb-3","isCollapsed":_vm.activeCategory.id !== -2,"openIcon":"chevron-down","closeIcon":"chevron-up"},on:{"onCollapseOrExpand":function($event){return _vm.setActiveCategory({ id: -2 })}},scopedSlots:_vm._u([{key:"headerTitle",fn:function(){return [_c('WCItemModifierQuantity',{staticClass:"wc-category nav-item font-weight-bold",class:{
                  'wc-category-active': _vm.activeCategory.id === -2,
                  'wc-category-inactive': _vm.activeCategory.id !== -2,
                },attrs:{"item":_vm.item}})]},proxy:true},{key:"cardBody",fn:function(){return [_c('WCItemModifierQuantityEntry',{attrs:{"itemModifiers":_vm.itemModifiers,"item":_vm.item,"lineItem":_vm.lineItem}})]},proxy:true}])}),_vm._l((_vm.categories),function(category){return _c('WCCollapseCard',{key:category.id,staticClass:"w-100",attrs:{"bodyClass":"p-0 mb-3","isCollapsed":_vm.activeCategory.id !== category.id,"openIcon":"chevron-down","closeIcon":"chevron-up"},on:{"onCollapseOrExpand":function($event){return _vm.setActiveCategory(category)}},scopedSlots:_vm._u([{key:"headerTitle",fn:function(){return [_c('WCItemModifierCategory',{staticClass:"wc-category nav-item font-weight-bold",class:{
                  'wc-category-active': _vm.activeCategory.id === category.id,
                  'wc-category-inactive': _vm.activeCategory.id !== category.id,
                },attrs:{"value":category,"category":category,"selectedAnswers":_vm.getSelectedItemsByCategory(category),"criteriaIconColor":"red"},on:{"categorySelected":function($event){return _vm.categorySelected(category)}}})]},proxy:true},{key:"cardBody",fn:function(){return [_c('WCItemModifierItemGroup',{attrs:{"category":category,"itemModifiers":_vm.itemModifiers,"selectedAnswers":_vm.getSelectedItemsByCategory(category)}})]},proxy:true}],null,true)})}),(_vm.itemModifiers.linkedItems)?_c('WCCollapseCard',{staticClass:"w-100",attrs:{"bodyClass":"p-0 mb-3","isCollapsed":_vm.activeCategory.id !== 1,"openIcon":"chevron-down","closeIcon":"chevron-up"},on:{"onCollapseOrExpand":function($event){return _vm.setActiveCategory({ id: 1 })}},scopedSlots:_vm._u([{key:"headerTitle",fn:function(){return [(_vm.itemModifiers.linkedItems)?_c('WCItemModifierLinkedItems',{staticClass:"wc-category nav-item font-weight-bold",class:{
                  'wc-category-active': _vm.activeCategory.id === 1,
                  'wc-category-inactive': _vm.activeCategory.id !== 1,
                },attrs:{"linkedItems":_vm.itemModifiers.linkedItems}}):_vm._e()]},proxy:true},{key:"cardBody",fn:function(){return [_c('WCItemModifierLinkedItemsGroup',{attrs:{"itemModifiers":_vm.itemModifiers,"aria-labelledby":_vm.constructCategoryId(
                    _vm.itemModifiers.linkedItems.name,
                    _vm.itemModifiers.linkedItems.id,
                  ) + 'tab'}})]},proxy:true}],null,false,1494786721)}):_vm._e()],2)],1)]},proxy:true},{key:"onDesktop",fn:function(){return [_c('div',{staticClass:"row wc-item-modifier-dialog"},[_c('section',{staticClass:"col-3"},[_c('WCItemModifierCategoryGroup',{attrs:{"categories":_vm.categories,"itemModifiers":_vm.itemModifiers,"value":_vm.activeCategory,"itemName":_vm.item.name,"item":_vm.item},on:{"input":function($event){return _vm.setActiveCategory($event)}}}),_c('b',[_vm._v(" "+_vm._s(_vm.$t('needToStartOver'))+" "),_c('button',{staticClass:"btn btn-link underline-link-from-center",on:{"click":function($event){$event.preventDefault();return _vm.$emit('clearAll')}}},[_vm._v(" "+_vm._s(_vm.$t('clearAll'))+" ")])])],1),_c('section',{staticClass:"col-6"},[_c('div',{staticClass:"tab-content",attrs:{"id":"wc-tabContent"}},[_vm._l((_vm.categories),function(category){return _c('div',{key:category.id,staticClass:"tab-pane fade",class:{
                show: _vm.activeCategory.id === category.id,
                active: _vm.activeCategory.id === category.id,
              },attrs:{"id":_vm.constructCategoryId(category.name, category.id) + 'tabpanel',"role":"tabpanel","aria-labelledby":_vm.constructCategoryId(category.name, category.id) + 'tab',"tabindex":"0"}},[_c('WCItemModifierItemGroup',{attrs:{"category":category,"itemModifiers":_vm.itemModifiers,"selectedAnswers":_vm.getSelectedItemsByCategory(category)}})],1)}),_c('div',{staticClass:"tab-pane fade",class:{
                show: _vm.activeCategory.id === -1,
                active: _vm.activeCategory.id === -1,
              },attrs:{"role":"tabpanel","id":"special-instructions-tabpanel","aria-labelledby":"special-instructions-tab","tabindex":"0"}},[_c('WCItemModifierInstructionsEntry',{attrs:{"itemModifiers":_vm.itemModifiers,"item":_vm.item,"instructions":_vm.instructions},on:{"input":function($event){return _vm.$emit('input', $event)}}})],1),_c('div',{staticClass:"tab-pane fade",class:{
                show: _vm.activeCategory.id === -2,
                active: _vm.activeCategory.id === -2,
              },attrs:{"role":"tabpanel","id":"modifier-quantity-tabpanel","aria-labelledby":"modifier-quantity-tab","tabindex":"0"}},[_c('WCItemModifierQuantityEntry',{attrs:{"itemModifiers":_vm.itemModifiers,"item":_vm.item,"lineItem":_vm.lineItem}})],1),(_vm.itemModifiers.linkedItems)?_c('div',{staticClass:"tab-pane fade",class:{
                show: _vm.activeCategory.id === 1,
                active: _vm.activeCategory.id === 1,
              },attrs:{"role":"tabpanel","aria-labelledby":_vm.constructCategoryId(
                  _vm.itemModifiers.linkedItems.name,
                  _vm.itemModifiers.linkedItems.id,
                ) + 'tab',"id":_vm.constructCategoryId(
                  _vm.itemModifiers.linkedItems.name,
                  _vm.itemModifiers.linkedItems.id,
                ) + 'tabpanel',"tabindex":"0"}},[_c('WCItemModifierLinkedItemsGroup',{attrs:{"itemModifiers":_vm.itemModifiers,"aria-labelledby":_vm.constructCategoryId(
                    _vm.itemModifiers.linkedItems.name,
                    _vm.itemModifiers.linkedItems.id,
                  ) + 'tab'}})],1):_vm._e()],2)]),_c('section',{staticClass:"col-3 d-flex flex-column"},[_c('WCItemModifierAnswers',{attrs:{"item":_vm.item,"itemModifiers":_vm.itemModifiers,"visibleAnswers":_vm.visibleAnswers}})],1),_c('div',{staticClass:"col-12 d-flex justify-content-end"},[_c('b',[_vm._v(" "+_vm._s(_vm.$t('total'))+" ")]),_c('span',{staticClass:"ml-3"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.calculateSelectionTotal))+" ")])])])]},proxy:true}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }