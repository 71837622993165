<template>
  <div class="d-flex flex-column" role="group" aria-labelledby="special-instructions-tab">
    <label for="wc-special-instructions" class="pb-4">
      {{ $t('enterInstructionsFor', { itemName: item.name }) }}
    </label>
    <WCTextArea
      class="form-control wc-special-instructions"
      id="wc-special-instructions"
      maxlength="256"
      rows="5"
      resize="none"
      :value="instructions"
      @input="changed"
    />
  </div>
</template>

<script>
import ItemModifiersMixin from '@/modules/itemModifiers/mixins/ItemModifierMixin';
import CartControls from '@/modules/cart/mixins/CartControls/CartControls';
import WCTextArea from '@/modules/forms/components/WCTextArea/WCTextArea.vue';

export default {
  components: { WCTextArea },
  props: {
    instructions: {},
  },
  mixins: [ItemModifiersMixin, CartControls],
  methods: {
    changed(input) {
      this.$emit('input', input);
    },
  },
};
</script>

<style scoped>
.wc-special-instructions {
  resize: none;
}
</style>
