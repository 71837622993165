<template>
  <section
    aria-live="polite"
    aria-atomic="true"
    :class="{ 'wc-mobile-menu-root mt-auto': menus.length > 0 }"
  >
    <template v-for="(menu, index) in menus">
      <component
        :is="menu.component"
        :key="index"
        :data-id="menu.id"
        v-bind:close="menu.close"
        v-bind:dismiss="menu.dismiss"
        v-bind="menu.props"
      />
    </template>
  </section>
</template>

<script>
import { v4 as uuid } from 'uuid';
import MobileContextMenuService from '../../services/MobileContextMenuService';
import WCMobileContextMenu from '../WCMobileContextMenu/WCMobileContextMenu.vue';

export default {
  name: 'WCMobileContextMenuRoot',
  components: { WCMobileContextMenu },
  data() {
    return {
      menus: [],
    };
  },
  created() {
    MobileContextMenuService.$on('menu:open', ({ component, options, resolve, reject }) => {
      const id = uuid();
      const { props, timeout } = options;
      const menu = {
        id,
        component,
        props,
        close: value => {
          this.menus = this.menus.filter(t => t.id !== id);
          resolve(value);
        },
        dismiss: reason => {
          this.menus = this.menus.filter(t => t.id !== id);
          reject(reason);
        },
      };
      this.menus.push(menu);

      if (timeout) {
        setTimeout(() => menu.dismiss('Timeout'), timeout);
      }
    });
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

.wc-mobile-menu-root {
  position: fixed;
  bottom: 0;
  z-index: map-get($zindex, 'modals');
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.2);
}
</style>
