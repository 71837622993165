<template>
  <!-- Exit Accelerated Check Out screen -->
  <div class="modal-root">
    <WCModal position="center">
      <WCModalHeader>
        <h3 class="mb-0">
          <font-awesome-icon icon="laptop-mobile" class="mr-1 font-size-lg" />
          {{ $t('mobileAcceleratedCheckOutSessionInProgress') }}
        </h3>
      </WCModalHeader>
      <WCModalBody>
        <p>{{ $t('exitAcceleratedCheckOutModeMessage') }}</p>
      </WCModalBody>
      <WCModalFooter>
        <button class="btn btn-primary" @click="exitScanMode" ref="exitButtonRef">
          {{ $t('continueHereAndEndAcceleratedCheckOut') }}
        </button>
      </WCModalFooter>
    </WCModal>
  </div>
</template>

<script>
import WCModal from '@/modules/modals/components/WCModal/WCModal.vue';
import WCModalHeader from '@/modules/modals/components/WCModalHeader/WCModalHeader.vue';
import WCModalBody from '@/modules/modals/components/WCModalBody/WCModalBody.vue';
import WCModalFooter from '@/modules/modals/components/WCModalFooter/WCModalFooter.vue';
import ScanAndGoMixin from '@/modules/user/mixins/ScanAndGoMixin';
import redirectFallbackHome from '@/router/redirects/redirect-fallback-home/redirect-fallback-home';
import ModalMixin from '@/modules/modals/mixins/ModalMixin';

export default {
  name: 'ExitScanAndGo',
  mixins: [ScanAndGoMixin, ModalMixin],
  components: { WCModal, WCModalHeader, WCModalBody, WCModalFooter },
  mounted() {
    this.$refs.exitButtonRef?.focus();
  },
  methods: {
    async exitScanMode() {
      await this.exitScanAndGoMode();
      await this.close();
      await redirectFallbackHome(this.$router);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

.modal-root {
  position: fixed;
  top: 0;
  right: 0;
  z-index: map-get($zindex, 'modals');
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.2);
}
</style>
