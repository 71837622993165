<template>
  <div class="tooltip" :class="{ show: hover }" role="tooltip">
    <div class="tooltip-inner"><slot /></div>
    <div class="arrow" data-popper-arrow></div>
  </div>
</template>

<script>
import { createPopper } from '@popperjs/core';

export default {
  props: {
    target: {
      type: String,
      required: true,
    },
    position: {
      type: String,
      default: 'top',
    },
    delayMillis: {
      type: Number,
      default: 0,
    },
  },
  async mounted() {
    this.targetElement = this.$parent.$refs[this.target];
    this.targetElement = this.targetElement.$el || this.targetElement;
    this.targetElement.addEventListener('mouseenter', this.setHover);
    this.targetElement.addEventListener('mouseleave', this.setUnhover);
    this.targetElement.addEventListener('click', this.toggleHover);

    this.popper = createPopper(this.targetElement, this.$el, {});
    this.updatePopperOptions();
  },

  updated() {
    this.updatePopperOptions();
    this.popper.update();
  },

  beforeDestroy() {
    if (this.popper) {
      this.popper.destroy();
      this.popper = null;
    }

    this.targetElement.removeEventListener('mouseenter', this.setHover);
    this.targetElement.removeEventListener('mouseleave', this.setUnhover);
    this.targetElement.removeEventListener('click', this.toggleHover);
  },
  data() {
    return {
      popper: null,
      targetElement: null,
      hover: false,
      mouseIsOver: false,
    };
  },
  methods: {
    setHover() {
      this.mouseIsOver = true;
      this.hover = true;
    },
    setUnhover() {
      this.mouseIsOver = false;
      this.hover = false;
    },
    toggleHover() {
      if (!this.mouseIsOver) this.hover = !this.hover;
      this.mouseIsOver = false;
    },
    updatePopperOptions() {
      const offset = [0, 8];

      const padding = 5;

      this.popper.setOptions({
        placement: this.position,
        modifiers: [
          {
            name: 'offset',
            options: {
              offset,
            },
          },
          {
            name: 'arrow',
            options: {
              padding,
            },
          },
        ],
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/_tools.scss';
@import '~@/assets/styles/_settings.scss';

.arrow,
.arrow::before {
  position: absolute;
  width: rem(8px);
  height: rem(8px);
  z-index: -1;
  left: rem(3px);
}

.arrow::before {
  content: '';
  transform: rotate(45deg);
  background: black;
}

.tooltip[data-popper-placement^='top'] > .arrow {
  bottom: rem(-4px);
}

.tooltip[data-popper-placement^='bottom'] > .arrow {
  top: rem(-4px);
}

.tooltip[data-popper-placement^='left'] > .arrow {
  right: rem(-4px);
}

.tooltip[data-popper-placement^='right'] > .arrow {
  left: rem(-4px);
}

/* Bring element to background when hidden */
.tooltip {
  z-index: $zindex-popover;
  opacity: 1 !important;
  &:not(.show) {
    display: none;
  }
}

/* Maximum width before wrapping occurs */
.tooltip-inner {
  max-width: 20rem;
}
</style>
