<template>
  <div :class="classes" role="status">
    <span class="sr-only">{{ $t('loading') }}...</span>
  </div>
</template>

<script>
export default {
  name: 'WCSpinner',
  props: {
    type: {
      type: String,
      validator: value => ['border', 'grow'].includes(value),
      default: 'border',
    },
    size: {
      type: String,
      validator: value => ['small', 'default', 'large', 'xl'].includes(value),
      default: 'default',
    },
    position: {
      type: String,
      validator: value =>
        ['top-left', 'top-center', 'center', 'self', 'window-center'].includes(value),
      default: 'self',
    },
    color: {
      type: String,
      validator: value => {
        return [
          'primary',
          'secondary',
          'success',
          'danger',
          'warning',
          'info',
          'light',
          'dark',
        ].includes(value);
      },
      default: 'primary',
    },
  },
  computed: {
    classes() {
      const classes = [];
      classes.push(`spinner-${this.type}`);
      classes.push(`text-${this.color}`);
      if (this.position !== 'self') classes.push(`spinner-${this.position}`); // To add spinner position props

      switch (this.size) {
        case 'small':
          classes.push(`spinner-${this.type}-sm`);
          break;
        case 'large':
          classes.push(`spinner-${this.type}-lg`);
          break;
        case 'xl':
          classes.push(`spinner-${this.type}-xl`);
          break;
        default:
          break;
      }

      return classes;
    },
  },
};
</script>

<style scoped lang="scss">
.spinner-border-lg,
.spinner-grow-lg {
  width: 3rem;
  height: 3rem;
  border-width: 0.3em;
}

.spinner-border-xl,
.spinner-grow-xl {
  width: 4rem;
  height: 4rem;
  border-width: 0.35em;
}
.spinner-top-left,
.spinner-top-center,
.spinner-center {
  position: absolute;
  z-index: 1;
}
.spinner-window-center {
  position: fixed;
  z-index: 1;
}
.spinner-top-left {
  left: 0%;
  top: 0%;
}
.spinner-top-center {
  left: calc(50% - 2rem);
  top: 0%;
}
.spinner-center,
.spinner-window-center {
  left: calc(50% - 2rem);
  top: 50%;
}
</style>
