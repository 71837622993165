<template>
  <div class="container-fluid">
    <div class="justify-content-between">
      <div class="d-flex">
        <h4 class="pb-2">{{ categoryText }}</h4>
        <font-awesome-icon
          v-if="category.min && (selectedAnswers.length || 0) < category.min"
          icon="circle-exclamation"
          color="red"
          class="ml-2 wc-category-limit-warning"
          :title="$t('needsMinimumSelection', { min: category.min })"
          :aria-label="$t('needsMinimumSelection', { min: category.min })"
        />
      </div>
      <div
        class="wc-item-modifier-group card-deck"
        role="group"
        :aria-labelledby="constructCategoryId(category.name, category.id) + 'tab'"
      >
        <WCItemModifierItem
          v-for="item in items"
          :key="item.id"
          :item="item"
          :itemModifiers="itemModifiers"
          :prohibitAlterations="prohibitAlterationsForModifier(item)"
          :category="category"
        />
      </div>
    </div>
  </div>
</template>

<script>
import WCItemModifierItem from '@/modules/itemModifiers/components/WCItemModifierItem/WCItemModifierItem.vue';
import ItemModifiersMixin from '@/modules/itemModifiers/mixins/ItemModifierMixin';

export default {
  name: 'WCItemModifierItemGroup',
  props: {
    category: {
      type: Object,
      required: true,
    },
    // The selected answers only for the active category
    selectedAnswers: {
      type: Array,
    },
  },
  mixins: [ItemModifiersMixin],
  components: { WCItemModifierItem },
  data() {
    return {
      items: this.category.items,
    };
  },
  computed: {
    categoryText() {
      if (this.category.max !== 0 && this.category.max === this.category.min) {
        return this.$t('choose', { max: this.category.max });
      }

      if (this.category.max && this.category.min) {
        return this.$t('chooseBetween', { min: this.category.min, max: this.category.max });
      }

      if (this.category.max) {
        return this.$t('chooseUpTo', { max: this.category.max });
      }

      if (this.category.min) {
        return this.$t('chooseAtLeast', { min: this.category.min });
      }

      return this.$t('chooseYour', { category: this.category.name });
    },
  },
  methods: {
    /**
     * Determines the value of prohibit alterations for this modifier. If true, this modifier should not be shown as an option to be selected. This may be subject to change.
     * @param {Object} item This is the modifier itself. Ex: lettuce
     * @returns A boolean representing the Web Office variable Prohibit Alterations for this specific modifier
     */
    prohibitAlterationsForModifier(item) {
      return this.fetched && item && this.fetched[item.id]
        ? this.fetched[item.id].prohibitAlterations
        : false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

.wc-item-modifier-group {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 1rem;

  @include media-breakpoint-between(md, lg) {
    grid-template-columns: 1fr 1fr;
  }

  @include media-breakpoint-down(md) {
    grid-template-columns: repeat(auto-fill, minmax(9rem, 1fr));
    gap: 1rem;
    &.card-deck {
      margin-left: 0;
      margin-right: 0;
    }
  }
  @include media-breakpoint-down(sm) {
    grid-template-columns: repeat(auto-fill, minmax(6rem, 1fr));
  }
}

@include media-breakpoint-up(lg) {
  .card-group {
    display: flex;
    width: 100%;
  }

  .card-deck .card {
    display: flex;
    //flex: 1 1 auto;
    flex-direction: column;
    justify-content: space-between;
  }

  .card-block {
    flex: 1 1 auto;
  }
}
</style>
