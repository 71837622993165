/**
 * PlatformRenderer accepts two named slots: `onMobile` and `onDesktop`.
 * When on a mobile device, onMobile will be rendered.
 * When on a desktop device, onDesktop will be rendered.
 *
 * Note: The slots must contain only one root. Vue 2 doesn't
 * support multi-root components.
 */
export default {
  name: 'PlatformRenderer',
  render() {
    return this.platformInfo.isMobile
      ? this.$scopedSlots.onMobile()
      : this.$scopedSlots.onDesktop();
  },
  inject: {
    /* Provided by PlatformProvider */
    platformInfo: 'platformInfo',
  },
};
