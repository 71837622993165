<template>
  <DashboardSidebar>
    <DashboardSidebarLink to="/me/orders" v-if="$configuration.pastOrdersEnabled">
      {{ $t('orderHistory') }}
    </DashboardSidebarLink>

    <DashboardSidebarLink to="/me/items" v-if="$configuration.pastItemsEnabled">
      {{ $t('buyItAgain') }}
    </DashboardSidebarLink>

    <DashboardSidebarLink to="/me/lists" v-if="$configuration.listEnabled">
      {{ $t('shoppingLists') }}
    </DashboardSidebarLink>

    <DashboardSidebarLink to="/me/rewards" v-if="$configuration.rewardsEnabled">
      {{ $t('rewards') }}
    </DashboardSidebarLink>

    <DashboardSidebarLink to="/me/wallet" v-if="$configuration.walletEnabled">
      {{ $t('wallet') }}
    </DashboardSidebarLink>

    <DashboardSidebarLink to="/me/edit">
      {{ $t('accountSettings') }}
    </DashboardSidebarLink>

    <DashboardSidebarLink to="/me/password">
      {{ $t('changePassword') }}
    </DashboardSidebarLink>

    <DashboardSidebarLink to="/me/programs" v-if="customerPrograms">
      {{ $t('customerPrograms') }}
    </DashboardSidebarLink>

    <li class="unstyled-list-item">
      <DashboardSidebarButton @click="logout">
        {{ $t('signOut') }}
      </DashboardSidebarButton>
    </li>
  </DashboardSidebar>
</template>

<script>
import UserMixin from '@/modules/user/mixins/UserMixin';
import DashboardSidebarLink from '@/layouts/components/Dashboard/DashboardSidebarLink.vue';
import DashboardSidebarButton from '@/layouts/components/Dashboard/DashboardSidebarButton.vue';
import DashboardSidebar from '@/layouts/components/Dashboard/DashboardSidebar.vue';

export default {
  name: 'WCAccountSidebar',
  components: { DashboardSidebar, DashboardSidebarLink, DashboardSidebarButton },
  mixins: [UserMixin],
  computed: {
    customerPrograms() {
      return (
        this.$configuration.customerGroups?.length > 0 &&
        this.$configuration.customerGroups.filter(el => el.groups?.length > 0).length > 0
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.unstyled-list-item {
  list-style-type: none;
}
</style>
