import { helpers } from 'vuelidate/lib/validators';
import { PASSWORD_REGEXP } from '@/constants/RegExpConstants';

/**
 * WebCart password passwordValidator.
 *
 * @param {string} value - the password
 * @returns {Object} state - An object containing whether or not the password isValid. Includes
 *                           a requirementsMet object containing all requirements and whether or
 *                           not they have been met (true if met, false otherwise).
 */
export function passwordValidator(value) {
  return {
    requirementsMet: {
      minLength: !!(value && value.length >= 8),
      upper: !!new RegExp(PASSWORD_REGEXP.UPPERCASE).test(value),
      number: !!new RegExp(PASSWORD_REGEXP.NUMBERS).test(value),
      symbol: !!new RegExp(PASSWORD_REGEXP.SYMBOL).test(value),
      maxLength: !!(value && value.length <= 255),
    },
    get isValid() {
      return (
        this.requirementsMet.minLength &&
        this.requirementsMet.upper &&
        this.requirementsMet.number &&
        this.requirementsMet.symbol &&
        this.requirementsMet.maxLength
      );
    },
  };
}

/**
 * Sets the state on the password parameter state. Since this causes a vuelidate feedback
 * loop, we only set the state every other call. First call will be intentional, followed by a
 * feedback-loop call that we ignore.
 * - NOTE: maybeValidator should call setState only once
 *
 * @param {object} context - the vue component instance containing $v.form.password
 * @param {object} newState- the new state
 */
let duplicateCall = false;
function setState(context, newState) {
  if (!duplicateCall) {
    duplicateCall = true;
    // eslint-disable-next-line no-param-reassign
    context.$v.form.password.$params.password.state = newState;
  }
  duplicateCall = false;
}

/**
 * Vuelidate password passwordValidator.
 *
 * Sets a state object containing 'isValid' (whether or not the provided password is valid) as well
 * as the requirements that the password has/hasn't met. The state object will be located at
 * $v.form.password.$params.password.state and will be of the form:
 * {
 *   isValid: Boolean,
 *   requirementsMet: {
 *     length: Boolean,
 *     upper: Boolean,
 *     number: Boolean,
 *     symbol: Boolean
 *   }
 * }
 *
 * Requirements:
 * - Password must be accessible at $v.form.password.
 *
 * @returns {function} - The passwordValidator function called by vuelidate.
 */
export default function() {
  return helpers.withParams({ type: 'password', state: null }, function maybeValidator(value) {
    const state = passwordValidator(value);
    setState(this, state);
    return state.isValid;
  });
}
