<template>
  <button
    @click="categorySelected(category)"
    :href="`#${category.id}`"
    :class="{ 'wc-category-with-icons': needsSelection || categorySatisfied }"
    class="unstyled-btn nav-link"
    role="tab"
    data-toggle="tab"
  >
    <span v-if="needsSelection || categorySatisfied" />
    {{ category.name }}
    <font-awesome-icon
      v-if="category.min && (selectedAnswers.length || 0) < category.min"
      icon="circle-exclamation"
      :color="criteriaIconColor"
      class="ml-2"
      :title="$t('needsMinimumSelection', { min: category.min })"
      :aria-label="$t('needsMinimumSelection', { min: category.min })"
    />

    <font-awesome-icon
      v-if="category.min && (selectedAnswers.length || 0) >= category.min"
      icon="check"
      class="ml-2"
      :title="$t('minimumSelectionSatisfied')"
      :aria-label="$t('minimumSelectionSatisfied')"
    />
  </button>
</template>

<script>
export default {
  name: 'WCItemModifierCategory',
  props: {
    category: {
      type: Object,
      required: true,
    },
    value: {
      type: Object,
    },
    // The selected answers only for the active category
    selectedAnswers: {
      type: Array,
    },
    criteriaIconColor: {
      type: String,
      default: 'white',
    },
  },
  computed: {
    needsSelection() {
      return this.category.min && (this.selectedAnswers.length || 0) < this.category.min;
    },
    categorySatisfied() {
      return this.category.min && (this.selectedAnswers.length || 0) >= this.category.min;
    },
  },
  methods: {
    categorySelected(category) {
      this.$emit('categorySelected', category);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

.wc-category-with-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@include media-breakpoint-down(md) {
  .nav-link {
    padding: $pad-3 $pad-1 $pad-1 $pad-1;
  }
}
</style>
