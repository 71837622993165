import { isEqual, isAfter, isBefore, parseISO } from 'date-fns';
import { helpers } from 'vuelidate/lib/validators';

export const minDate = date => {
  return helpers.withParams({ type: 'minDate', min: date }, value => {
    if (typeof value === 'string')
      return isEqual(parseISO(value), date) || isAfter(parseISO(value), date);
    return isEqual(value, date) || isAfter(value, date);
  });
};

export const maxDate = date => {
  return helpers.withParams({ type: 'maxDate', max: date }, value => {
    if (typeof value === 'string')
      return isEqual(parseISO(value), date) || isBefore(parseISO(value), date);
    return isEqual(value, date) || isBefore(value, date);
  });
};

export default {
  minDate,
  maxDate,
};
