import { render, staticRenderFns } from "./WCOrderTypeCollapseCardBody.vue?vue&type=template&id=61050452&scoped=true&"
import script from "./WCOrderTypeCollapseCardBody.vue?vue&type=script&lang=js&"
export * from "./WCOrderTypeCollapseCardBody.vue?vue&type=script&lang=js&"
import style0 from "./WCOrderTypeCollapseCardBody.vue?vue&type=style&index=0&id=61050452&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61050452",
  null
  
)

export default component.exports