<template>
  <div ref="recaptcha" />
</template>
<script>
import RecaptchaService from '../../services/recaptcha.service';

export default {
  name: 'WCInvisibleRecaptcha',
  data() {
    return {
      widget: null,
      promise: null,
    };
  },
  async mounted() {
    if (!RecaptchaService.isEnabled()) {
      return;
    }

    try {
      this.widget = RecaptchaService.render(this.$refs.recaptcha, {
        callback: token => this.$emit('response', token),
        'expired-callback': () => this.$emit('expired'),
        'error-callback': error => this.$emit('error', error),
      });
    } catch (error) {
      console.error(error);
    }
  },
  methods: {
    execute() {
      if (!RecaptchaService.isEnabled()) {
        return Promise.resolve();
      }

      if (!this.promise) {
        this.promise = new Promise((resolve, reject) => {
          this.$once('response', token => resolve(token));
          this.$once('expired', () => reject(new Error('Recaptcha has expired')));
          this.$once('error', error => reject(error));
        });
      }

      RecaptchaService.execute(this.widget);

      return this.promise;
    },
    reset() {
      if (!RecaptchaService.isEnabled()) {
        return;
      }

      this.promise = null;
      RecaptchaService.reset(this.widget);
    },
  },
};
</script>
