export class GoogleOptimizeService {
  loaded = false;

  constructor(googleOptimizeId) {
    this.googleOptimizeId = googleOptimizeId;
  }

  load() {
    if (!this.loaded && this.googleOptimizeId) {
      this.loaded = new Promise(resolve => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = `https://www.googleoptimize.com/optimize.js?id=${this.googleOptimizeId}`;
        document.head.appendChild(script);
        resolve();
      });
    }

    return this.loaded;
  }
}

export default GoogleOptimizeService;
