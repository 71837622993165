import Vue from 'vue';
import axios from 'axios';
import BigNumber from 'bignumber.js';

const initialQuantity = new BigNumber(0);
const initialWeightedQuantity = new BigNumber(0);

const state = {
  itemModifiers: [],
  variation: '',
  total: '',
  activeCategory: {},
  categoryCounts: {}, // The number of selected items per category
  currentAnswers: {}, // The answers object representing user's current selections w/ labels
  modifyingItem: {}, // The item currently being modifying / edited,
  categories: [], // The list of categories being edited, including subtree categories
  quantity: initialQuantity, // The quantity of items desired, e.g "3 items", "1.5lbs".
  weightedQuantity: initialWeightedQuantity, // The weighted quantity, e.g. "2" at 0.5lb each
};

const getters = {
  getItemModifiers(state) {
    return state.itemModifiers;
  },
  getCurrentAnswers(state) {
    return state.currentAnswers;
  },
  getItemVariation(state) {
    return state.variation;
  },
  getItemAmount(state) {
    return state.total;
  },
  getActiveCategory(state) {
    const firstCategory = state.categories?.length > 0 ? state.itemModifiers.categories[0] : {};
    return state.activeCategory || firstCategory;
  },
  getCategoryCounts(state) {
    return state.categoryCounts;
  },
  fetched(state) {
    return state.itemModifiers && state.itemModifiers.categories && state.itemModifiers.answers;
  },
  getModifyingItem(state) {
    return state.modifyingItem;
  },
  getCategories(state) {
    return state.categories;
  },
  getQuantity(state) {
    return state.quantity;
  },
  getWeightedQuantity(state) {
    return state.weightedQuantity;
  },
};

export const SET_ITEM_MODIFIERS = 'SET_ITEM_MODIFIERS';
export const SET_ITEM_VARIATION = 'SET_ITEM_VARIATION';
export const SET_ITEM_AMOUNT = 'SET_ITEM_AMOUNT';
export const SET_ACTIVE_CATEGORY = 'SET_ACTIVE_CATEGORY';
export const SET_CATEGORY_COUNTS = 'SET_CATEGORY_COUNTS';
export const SET_CURRENT_ANSWER = 'SET_CURRENT_ANSWER';
export const CLEAR_ANSWERS = 'CLEAR_ANSWERS';
export const CLEAR_ALL = 'CLEAR_ALL';
export const CLEAR_SELECTIONS = 'CLEAR_SELECTIONS';
export const SET_MODIFYING_ITEM = 'SET_MODIFYING_ITEM';
export const SET_CATEGORIES = 'SET_CATEGORIES';
export const SET_QUANTITY = 'SET_QUANTITY';
export const SET_WEIGHTED_QUANTITY = 'SET_WEIGHTED_QUANTITY';

const mutations = {
  [SET_ITEM_MODIFIERS](state, itemModifiers) {
    state.itemModifiers = itemModifiers;
  },
  [SET_ITEM_VARIATION](state, variation) {
    state.variation = variation;
  },
  [SET_ITEM_AMOUNT](state, total) {
    state.total = total;
  },
  [SET_ACTIVE_CATEGORY](state, category) {
    state.activeCategory = category;
  },
  [SET_CATEGORY_COUNTS](state, categoryCounts) {
    state.categoryCounts = categoryCounts;
  },
  [SET_CURRENT_ANSWER](state, { itemId, answer }) {
    Vue.set(state.currentAnswers, itemId, answer);
  },
  [SET_MODIFYING_ITEM](state, modifyingItem) {
    state.modifyingItem = modifyingItem;
  },
  [CLEAR_ANSWERS](state) {
    state.currentAnswers = {};
  },
  [CLEAR_ALL](state) {
    state.itemModifiers = [];
    state.variation = '';
    state.total = '';
    state.activeCategory = {};
    state.categoryCounts = {};
    state.currentAnswers = {};
    state.modifyingItem = {};
    state.categories = [];
    state.quantity = initialQuantity;
    state.weightedQuantity = initialWeightedQuantity;
  },
  [CLEAR_SELECTIONS](state) {
    state.total = '';
    state.activeCategory = state.itemModifiers.categories[0];
    state.categories = state.itemModifiers.categories;
    state.categoryCounts = {};
    state.currentAnswers = {};
  },
  [SET_CATEGORIES](state, categories) {
    state.categories = categories;
  },
  [SET_QUANTITY](state, quantity) {
    state.quantity = quantity;
  },
  [SET_WEIGHTED_QUANTITY](state, weightedQuantity) {
    state.weightedQuantity = weightedQuantity;
  },
};

const actions = {
  fetchItemModifiers({ commit }, id) {
    return axios.get(`/api/i/${id}/im`).then(res => {
      const data = res.data;
      commit(SET_ITEM_MODIFIERS, data);
    });
  },

  async createVariation({ commit, getters }, id) {
    const answers = Object.values(getters.getCurrentAnswers);

    const options = [];

    answers.forEach(answer => {
      options.push({
        id: answer.id,
        quantity: Number(answer.quantity),
        quantityId: answer.quantityId,
      });
    });

    options.sort((a, b) => {
      if (a.id < b.id) {
        return -1;
      }
      if (a.id > b.id) {
        return 1;
      }
      return 0;
    });

    try {
      const response = await axios.post(`/api/i/${id}/im`, { options });
      const data = response.data;
      commit(SET_ITEM_VARIATION, data.id);
    } catch (error) {
      console.log(error);
    }
  },

  async modifyItem({ commit }, payload) {
    try {
      const { id, variation } = payload;
      const response = await axios.post(`/api/i/${id}/mi`, { variation });
      const data = response.data;
      commit(SET_MODIFYING_ITEM, data);
    } catch (error) {
      console.log(error);
    }
  },

  async clearItemModifiers({ commit }) {
    await commit(CLEAR_ALL);
  },

  async clearSelections({ commit }) {
    await commit(CLEAR_SELECTIONS);
  },

  setMtoItemTotal({ commit }, total) {
    commit(SET_ITEM_AMOUNT, total);
  },

  async setCategories({ commit }, categories) {
    await commit(SET_CATEGORIES, categories);
  },

  setQuantity({ commit }, quantity) {
    commit(SET_QUANTITY, quantity);
  },

  setWeightedQuantity({ commit }, weightedQuantity) {
    commit(SET_WEIGHTED_QUANTITY, weightedQuantity);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
