import Vue from 'vue';
import { v4 as uuid } from 'uuid';
import { sha1 } from 'object-hash';

export default new Vue({
  data() {
    return {
      /**
       * This data structure needn't be optimized as we argue there should be no more than 10 toasts
       * at once, so n <= 10, i.e. n is bounded by a constant, giving O(1) per-operation runtime
       */
      toasts: [],
      /**
       * Used to help us detect infinite loops since toasts don't duplicate anymore.
       */
      callsInLastFiveSeconds: 0,
    };
  },
  methods: {
    open(component, options = { props: {}, actions: {}, timeout: 0, skipHash: false }) {
      this.incrementCalls();
      return new Promise((resolve, reject) => {
        const id = uuid();
        const hash = options.skipHash ? null : sha1(options);
        const { props, timeout, actions } = options;
        const toast = {
          id,
          hash,
          component,
          props,
          actions,
          timeout,
          close: value => {
            this.toasts = this.toasts.filter(t => t.id !== id);
            resolve(value);
          },
          dismiss: reason => {
            this.toasts = this.toasts.filter(t => t.id !== id);
            reject(reason);
          },
          startTimeout: timeoutMillis => {
            clearTimeout(toast.timerId);
            toast.timerId = setTimeout(() => toast.dismiss('Timeout'), timeoutMillis);
            resolve(timeoutMillis);
          },
        };

        /* Block duplicate toasts: instead of inserting, simply reset their timer */
        const duplicateToasts = toast.hash ? this.toasts.filter(t => t.hash === toast.hash) : [];
        if (duplicateToasts.length > 0) {
          // We guard toast insertions, so we should only ever find one duplicate toast
          if (duplicateToasts[0].timeout) {
            // Found a timer, reset it
            duplicateToasts[0].startTimeout(duplicateToasts[0].timeout);
          }
          // TODO: Shake the toast left/right to signal that it has been re-triggered
        } else {
          // Not a duplicate, simply add the toast
          this.toasts.unshift(toast); // Push onto front to reverse order of toasts
          if (timeout) {
            toast.timerId = setTimeout(() => toast.dismiss('Timeout'), timeout);
          }
        }
      });
    },

    /**
     * Increments the number of calls.
     */
    incrementCalls() {
      this.callsInLastFiveSeconds += 1;
    },
    /**
     * Clears the number of calls;
     */
    clearCalls() {
      if (this.callsInLastFiveSeconds > 100) {
        console.error('Infinite loop detected in ToastService');
      }
      this.callsInLastFiveSeconds = 0;
    },
  },
  created() {
    /**
     * Clear the number of calls we've received every 5 seconds.
     */
    setInterval(this.clearCalls, 5000);
  },
});
