<template>
  <div>
    <nav :aria-label="$t('breadcrumb')" v-if="crumbs[0].data">
      <ol class="breadcrumb wc-breadcrumb p-0">
        <li
          v-for="(crumb, ci) in crumbs"
          :key="crumb.data.group"
          class="breadcrumb-item wc-breadcrumb-item"
          :aria-current="isLast(ci) ? 'page' : null"
        >
          <button
            class="btn btn-link underline-link-from-center"
            :class="{ disabled: isLast(ci) }"
            @click="!isLast(ci) && selected(crumb)"
            :aria-disabled="isLast(ci) && 'true'"
          >
            {{ crumb.label }}
          </button>
        </li>
      </ol>
    </nav>
    <nav :aria-label="$t('breadcrumb')" v-if="crumbs[0].text">
      <ol class="wc-breadcrumb breadcrumb">
        <li
          v-for="(crumb, ci) in crumbs"
          :key="ci"
          class="wc-breadcrumb-item breadcrumb-item align-items-center"
          :aria-current="ci === crumbs.length - 1 ? 'page' : null"
        >
          <router-link
            class="btn btn-link underline-link-from-center"
            :class="{ disabled: ci === crumbs.length - 1 }"
            :to="ci !== crumbs.length - 1 ? crumb.to : ''"
            :aria-disabled="ci === crumbs.length - 1 && 'true'"
          >
            {{ crumb.text }}
          </router-link>
        </li>
      </ol>
    </nav>
  </div>
</template>

<script>
export default {
  props: {
    crumbs: {
      type: Array,
      required: true,
    },
  },

  methods: {
    isLast(index) {
      return index === this.crumbs.length - 1;
    },
    selected(crumb) {
      this.$emit('selected', crumb);
    },
  },
};
</script>

<style scoped>
.wc-breadcrumb {
  background-color: white;
}

.wc-breadcrumb-item {
  display: flex;
  align-items: center;
}
</style>
