<template>
  <div class="wc-price-box">
    <template v-if="!hasPercentDiscount">
      <div v-if="isOnSale">
        <span class="price-old wc-suggested-price">
          {{ suggestedExtendedPrice | currency }}
        </span>
      </div>
      <div v-if="hasPrice">
        <span :class="isOnSale ? 'price-new' : 'price'">
          <template>
            {{ extendedPrice | currency }}
          </template>
        </span>
      </div>
    </template>
    <template v-else>{{ $t('seePriceAtCheckout') }}</template>
  </div>
</template>

<script>
export default {
  name: 'WCCartDrawerItemPricing',
  props: {
    lineItem: {
      type: Object,
      required: true,
    },
  },
  computed: {
    item() {
      return this.lineItem.item;
    },
    isOnSale() {
      return (
        this.item.suggestedPrice != null &&
        this.item.actualPrice / this.item.actualPriceDivider < this.item.suggestedPrice
      );
    },
    hasPercentDiscount() {
      return this.item.discountType === 'percent';
    },
    hasPrice() {
      return this.item.actualPrice >= 0;
    },

    extendedPrice() {
      if (this.lineItem.amount && this.lineItem.extended) {
        return this.lineItem.extended;
      }

      return this.lineItem.quantity * (this.item.actualPrice / this.item.actualPriceDivider);
    },

    suggestedExtendedPrice() {
      return this.lineItem.quantity * this.item.suggestedPrice;
    },

    isSoldByAvgWeight() {
      return this.item.averageWeight != null && this.item.averageWeight >= 0;
    },

    isDividedPrice() {
      return this.item.actualPriceDivider && this.item.actualPriceDivider > 1;
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';
.wc-price-box {
  display: flex;
  flex-direction: column;
  justify-content: center;

  line-height: 1;
  white-space: nowrap;

  text-align: right;

  .price {
    font-weight: bold;
  }
  .price-old {
    color: var(--gray);
    text-decoration: line-through;
  }
  .price-new {
    color: var(--info, $info);
    font-weight: bold;
  }
}
</style>
