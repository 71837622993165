<template>
  <li
    class="nav-item dropdown"
    v-if="$configuration.i18nEnabled"
    :class="{ show }"
    v-click-outside="hide"
    @keydown.esc="hide"
  >
    <a
      class="nav-link dropdown-toggle pr-0"
      href="#"
      @click.prevent="toggle"
      role="button"
      aria-haspopup="true"
      :aria-expanded="show"
      id="languages-dropdown"
      data-toggle="dropdown"
    >
      {{ $t('language') }}
    </a>
    <div class="dropdown-menu" role="menu" :class="{ show }" aria-labelledby="languages-dropdown">
      <a
        class="dropdown-item"
        v-for="locale in activeLocales"
        :key="locale.code"
        href="#"
        @click.prevent="onSwitchLocale(locale)"
      >
        {{ locale.name }}
      </a>
    </div>
  </li>
</template>

<script>
import ClickOutside from 'vue-click-outside';
import LanguagesMixin from '../../modules/platform/mixins/LanguagesMixin';

export default {
  directives: {
    ClickOutside,
  },
  data() {
    return {
      show: false,
    };
  },
  /**
   * mixin for Locales and switchLocale
   */
  mixins: [LanguagesMixin],
  methods: {
    toggle() {
      this.show = !this.show;
    },
    hide() {
      this.show = false;
    },
    onSwitchLocale(locale) {
      this.hide();
      this.switchLocale(locale);
    },
  },
};
</script>
