<template>
  <!-- START : Product Row Wrap -->
  <div class="item-row" v-if="!hideIfEmpty || products.length > 0">
    <div v-if="loadFailedError">{{ loadFailedError }}</div>
    <!-- START : Product Row Header -->
    <div v-if="!loadFailedError" class="navbar navbar-light item-row-heading">
      <h2 class="content-left mb-0">{{ rowName }}</h2>
      <PlatformRenderer>
        <!-- View more option for mobile if totalCount greater than item count(7 by default) -->
        <template v-slot:onMobile>
          <router-link
            v-if="totalCount > products.length"
            :to="viewMoreLink"
            class="content-right underline-link-from-center"
          >
            {{ $t('viewMore') }}
          </router-link>
        </template>
        <!-- View more option for desktop if totalCount greater than item count(4 by default) -->
        <template v-slot:onDesktop>
          <router-link
            v-if="totalCount > desktopProducts.length"
            :to="viewMoreLink"
            class="content-right underline-link-from-center"
          >
            {{ $t('viewMore') }}
          </router-link>
        </template>
      </PlatformRenderer>
    </div>
    <!-- END : Product Row Header -->
    <!-- START : Product Row Content Wrap -->
    <template v-if="!loadFailedError">
      <div v-if="loading" class="wc-item-row__spinner-wrapper d-flex">
        <WCSpinner class="mx-auto align-self-center" size="xl" />
      </div>
      <template v-if="!loading">
        <PlatformRenderer>
          <template v-slot:onMobile>
            <WCCarousel class="wc-carousel--negate-container py-2 d-flex" :isSlider="true">
              <Splide
                :options="{
                  perPage: itemPerPage,
                  arrows: false,
                  gap: '2rem',
                }"
                class="container-fluid pb-4"
                role="list"
              >
                <SplideSlide v-for="product in products" :key="product.id">
                  <WCMobileProductCard
                    :item="product"
                    class="wc-card__wrapper--fixed-size mr-2"
                    role="listitem"
                  ></WCMobileProductCard>
                </SplideSlide>
              </Splide>
            </WCCarousel>
          </template>

          <template v-slot:onDesktop>
            <div class="item-row-wrapper" role="list">
              <WCProductCard
                v-for="product in desktopProducts"
                :key="product.id"
                :item="product"
                role="listitem"
              ></WCProductCard>
            </div>
          </template>
        </PlatformRenderer>
      </template>
    </template>
    <!-- END : Product Row Content Wrap -->
  </div>
  <!-- END : Product Row Wrap -->
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import PlatformRenderer from '@/modules/platform/components/PlatformRenderer';
import WCProductCard from '@/components/WCProductCard/WCProductCard.vue';
import WCMobileProductCard from '@/components/WCMobileProductCard/WCMobileProductCard.vue';
import WCCarousel from '@/components/WCCarousel/WCCarousel.vue';
import WCSpinner from '@/components/WCSpinner/WCSpinner.vue';
import { PlatformMixin } from '@/modules/platform';
import AnalyticsService from '@/services/analytics.service';
import TrackableEventConstants from '@/constants/TrackableEventConstants';

export default {
  components: {
    WCProductCard,
    WCSpinner,
    WCMobileProductCard,
    WCCarousel,
    PlatformRenderer,
    Splide,
    SplideSlide,
  },
  props: {
    rowId: {
      type: String,
      required: true,
    },
    rowName: {
      type: String,
      required: true,
    },
    rowType: {
      type: String,
    },
    rowSizeLimit: {
      type: Number,
      default: 7 /* Fetch 7 products - show 4 in desktop and 7 in tablet/mobile */,
    },
    /* Property to check if the Product Row has to be hidden if the products row list  is empty  */
    hideIfEmpty: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      loading: false,
      loadFailedError: null,
      totalCount: -1,
      products: [],
    };
  },
  mixins: [PlatformMixin],
  created() {
    this.updateData();
  },
  watch: {
    rowId() {
      this.updateData();
    },
  },
  methods: {
    updateData() {
      this.totalCount = 0;
      this.products = [];
      this.loading = true;
      this.loadFailedError = null;

      this.fetchData();
      this.loading = false;
    },
    async fetchData() {
      // Fetch 7 products - show 4 in desktop and 7 in tablet/mobile
      const req = {
        ps: this.rowSizeLimit,
        pn: 1,
      };

      if (this.rowType === 'coupon') {
        req.facets = { cp: [this.rowId] };
      } else {
        req.g = [this.rowId];
      }

      try {
        const response = await axios.post('api/b/', req);
        this.products = response.data.items;
        this.totalCount = response.data.totalCount
          ? response.data.totalCount
          : response.data.items.length;

        AnalyticsService.track(TrackableEventConstants.ItemImpression, {
          items: this.products,
          customerId: this.getUser?.sub,
          listId: this.rowId,
          listName: this.rowName,
        });
      } catch (error) {
        this.loading = false;
        this.loadFailedError = error;
        console.error(error);
      }
    },
  },
  computed: {
    ...mapGetters({
      getUser: 'user/getUser',
    }),
    /**
     * Displaying only the first 4 products out of 7 products for desktop
     * @returns {Array} - Array of products
     */
    desktopProducts() {
      return this.products.slice(0, 4);
    },
    /**
     * Method to get view more link
     * @returns {String} - view more link by rowId
     */
    viewMoreLink() {
      return `/b?g=${this.rowId}`;
    },
    itemPerPage() {
      return this.isMediumDevice ? 4 : 2;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

.wc-item-row__spinner-wrapper {
  height: rem(200px);
}

.wc-carousel {
  margin-left: -$container-gutter;
  margin-right: -$container-gutter;
}

.item-row {
  .item-row-heading {
    width: 100%;
    margin-bottom: $margin-2;
    padding: $pad-2 * 1.25 0 $pad-2 * 1.25;
    border-radius: 4px;
  }
  .item-row-wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: rem(10px);
  }
}
</style>
