<!-- WCSideBar component - sidebar panel with transition animation and backdrop opacity animation -->
<template>
  <div class="sidebar">
    <transition name="wc-fade">
      <div class="sidebar-backdrop" @click="close" v-if="isMenuOpen"></div>
    </transition>
    <transition name="wc-slide">
      <div v-if="isMenuOpen" class="sidebar-panel">
        <font-awesome-icon
          class="d-block d-sm-none close-icon"
          icon="xmark"
          size="lg"
          @click="close"
        />
        <slot></slot>
      </div>
    </transition>
  </div>
</template>
<script>
import { SIDE_MENU_EVENTS } from '../../../../constants/EventConstants';
import SideMenuService from '../../services/SideMenuService';

export default {
  /**
   * current status open/close of sidebar
   */
  data() {
    return {
      isMenuOpen: false,
    };
  },
  /**
   * To listen for side bar events
   */
  created() {
    SideMenuService.$on(SIDE_MENU_EVENTS.OPEN, () => {
      this.open();
    });

    SideMenuService.$on(SIDE_MENU_EVENTS.CLOSE, () => {
      this.close();
    });

    SideMenuService.$on(SIDE_MENU_EVENTS.TOGGLE, () => {
      this.toggle();
    });
  },
  /**
   * Methods to change the sidebar status
   */
  methods: {
    open() {
      this.isMenuOpen = true;
    },
    close() {
      this.isMenuOpen = false;
    },
    toggle() {
      this.isMenuOpen = !this.show;
    },
  },
  /**
   * watcher to close sidebar if route changes
   */
  watch: {
    $route(to, from) {
      if (this.isMenuOpen && to !== from) {
        this.close();
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

.wc-slide-enter-active,
.wc-slide-leave-active {
  transition: transform 0.3s ease;
}

.wc-slide-enter,
.wc-slide-leave-to {
  transform: translateX(-100%);
  transition: all 150ms ease-in 0s;
}

.wc-fade-enter-active,
.wc-fade-leave-active {
  transition: opacity 0.3s;
}
.wc-fade-enter,
.wc-fade-leave-to {
  opacity: 0;
}

.close-icon {
  position: absolute;
  right: 10px;
  top: 10px;
  color: var(--primary, $primary);
}

.sidebar-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  cursor: pointer;
  z-index: 9999;
}

.sidebar-panel {
  overflow-y: auto;
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: rem(300px);
  background-color: var(--white);
  z-index: 9999;
}
</style>
