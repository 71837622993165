<template>
  <div v-if="$scopedSlots.native" class="wc-sidebar__link" role="listitem">
    <a v-bind="$attrs" class="underline-link-from-center">
      <slot name="native">
        <!-- Pass to the native slot if you want a native <a> rather than a router-link -->
      </slot>
    </a>
  </div>
  <div
    v-else
    class="wc-sidebar__link"
    :class="{ 'wc-sidebar__link--active': isActive }"
    role="listitem"
  >
    <router-link :to="to" class="underline-link-from-center">
      <slot>
        <!-- Pass the internationalized link name -->
      </slot>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'DashboardSidebarLink',
  props: {
    /**
     * The route location
     */
    to: {
      type: String || Object,
    },
  },
  computed: {
    /**
     * Whether or not this route is the current route.
     * @return {boolean}
     */
    isActive() {
      // Resolve 'to' to a route object and compare its path to the current route's path
      return this.$router.resolve(this.to).route.path === this.$route.path;
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

.wc-sidebar__link {
  padding: $pad-2 0;
  &--active {
    background-color: var(--light);
    a {
      font-weight: bold;
      background-size: 100% 1px;
    }
  }
}
</style>
