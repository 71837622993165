<template>
  <div class="mt-3 w-100">
    <span>{{ $t('yourEbtBalance', { maskedCardNum: maskedCardNum }) }}</span>

    <div class="my-3">
      <h1 v-if="snapBalance" class="text-primary text-center">
        {{ $t('ebtSnap') }}: {{ snapBalance | currency }}
      </h1>
      <h1 v-if="cashBalance" class="text-primary text-center">
        {{ $t('ebtCash') }}: {{ cashBalance | currency }}
      </h1>
    </div>

    <button @click="viewEbtItems" v-if="showViewEbtItems" class="btn btn-primary w-100 mt-3">
      {{ $t('shopEbtItems') }}
    </button>

    <button v-if="token" @click="done" class="btn btn-primary w-100 mt-3">
      {{ $t('done') }}
    </button>
    <button v-else @click="addToWallet" class="btn btn-outline-primary w-100 mt-3">
      {{ $t('addToWallet') }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'EbtBalanceDisplay',
  props: {
    token: {
      type: Object,
    },
    snapBalance: {
      type: Number,
      required: true,
    },
    cashBalance: {
      type: Number,
      required: true,
    },
    maskedCardNum: {
      type: String,
      required: true,
    },
    paymentMethod: {
      type: Object,
      required: true,
    },
    showViewEbtItems: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    async addToWallet() {
      this.$emit('addToWallet');
    },
    done() {
      this.$emit('done');
    },
    viewEbtItems() {
      this.$emit('viewEbtItems');
    },
  },
};
</script>
