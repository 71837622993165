import Vue from 'vue';
import Vuelidate from 'vuelidate';
import VueHead from 'vue-head';
import VCalendar from 'v-calendar';
import icons from './modules/icons';
import filters from './utils/filters';
import configureMailgo from './utils/mailgo';

export default () => {
  Vue.use(Vuelidate);
  Vue.use(VueHead);
  Vue.config.productionTip = false;
  Vue.use(VCalendar);
  icons();
  filters();
  configureMailgo();
};
