import Vue from 'vue';

export default new Vue({
  methods: {
    open() {
      this.$emit('cartDrawer:open');
    },
    close() {
      this.$emit('cartDrawer:close');
    },
    toggle() {
      this.$emit('cartDrawer:toggle');
    },
  },
});
