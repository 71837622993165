<template>
  <WCOrderTypeCollapseCard
    headerBackground="var(--secondary-lighter-7)"
    class="border-0 px-2"
    :isCollapsedOnLoad="isCollapsedOnLoad"
    :isCollapsed="isCollapsed && isCollapsedOnLoad"
    :bodyClass="'mb-3'"
    headerClass="row no-gutters text-primary w-100 mb-2"
    openIcon="chevron-down"
    closeIcon="chevron-up"
    @onCollapseOrExpand="$emit('onCollapseOrExpand', $event)"
  >
    <template v-slot:headerTitle>
      <div class="wc-cartdrawer--ordertype p-2 align-items-center w-100">
        <span>
          <strong>{{ orderTypeObjectFromId(cart.orderType).name }} </strong>
        </span>
        <span>
          {{ lineItemCount }}
          {{ lineItemCount === 1 ? $t('item') : $t('items') }}
        </span>

        <WCCartDrawerSubtotal
          :cart="cart"
          :walletEbtTokens="walletEbtTokens"
          :loadingWallet="loadingWallet"
        />

        <router-link
          class="btn btn-primary btn-sm ml-auto wc-cartdrawer--ordertype__checkout"
          to="/co"
          @click.native="checkout"
        >
          {{ $t('checkout') }}
        </router-link>
      </div>
    </template>
    <template v-slot:cardBody>
      <WCOrderTypeCollapseCardBody :cart="cart" />
    </template>
  </WCOrderTypeCollapseCard>
</template>

<script>
import WCOrderTypeCollapseCard from '@/components/WCOrderTypeCollapseCard/WCOrderTypeCollapseCard.vue';
import OrderTypesMixin from '@/modules/orderTypes/mixins/OrderTypesMixin';
import { mapActions, mapGetters } from 'vuex';
import WCOrderTypeCollapseCardBody from '../../../../../components/WCOrderTypeCollapseCard/WCOrderTypeCollapseCardBody/WCOrderTypeCollapseCardBody.vue';
import WCCartDrawerSubtotal from './WCCartDrawerSubtotal.vue';

export default {
  components: {
    WCOrderTypeCollapseCard,
    WCOrderTypeCollapseCardBody,
    WCCartDrawerSubtotal,
  },
  mixins: [OrderTypesMixin],
  props: {
    cart: {
      type: Object,
    },
    isCollapsed: {
      type: Boolean,
      default: true,
    },
    walletEbtTokens: {
      type: Array,
    },
    loadingWallet: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      isSyncing: 'cart/isSyncing',
      isLoading: 'cart/isLoading',
      isGuest: 'user/isGuest',
      isGiftCard: 'cart/isGiftCard',
    }),
    displayLineItems() {
      if (this.cart.giftCardItems?.length) {
        return this.cart.giftCardItems;
      }

      return this.cart.lineItems.filter(
        lineItem => (lineItem.type === 1 || lineItem.type === 60) && lineItem.quantity > 0,
      );
    },
    lineItemCount() {
      return this.displayLineItems.length;
    },
    isCollapsedOnLoad() {
      return this.cart.orderType !== this.getActiveOrderTypeId;
    },
  },
  methods: {
    ...mapActions({
      cartDrawerCheckout: 'cart/cartDrawerCheckout',
    }),
    async checkout() {
      if (this.cart.orderType !== this.getActiveOrderTypeId) {
        await this.setActiveOrderType(this.orderTypeObjectFromId(this.cart.orderType));
      }
      this.cartDrawerCheckout();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/tools';
@import '~@/assets/styles/settings';

.wc-wrapper-scroll {
  height: rem(250px);
}

.wc-cartdrawer--sale {
  color: var(--info, $info);
}

.wc-cartdrawer--ordertype {
  display: grid;
  grid-template-columns: 2fr 1fr 3fr 1fr;
}

@include media-breakpoint-down(md) {
  .wc-cartdrawer--ordertype {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 0.5rem;
  }

  .wc-cartdrawer--ordertype__checkout {
    grid-column: span 2;
    width: 100%;
  }
}
</style>
