import { store } from '@/store';
import ToastService from '@/modules/toasts/services/ToastService';
import WCSimpleToast from '@/modules/toasts/components/WCSimpleToast/WCSimpleToast.vue';

export default async function scanAndGoAccess(to, from, next) {
  const isScanAndGoEnabled = store.getters.getConfiguration.scanAndGoEnabled;
  const isAuthenticated = store.getters['user/isCustomer'];
  const isCustomerModeScan = store.getters['user/isCustomerModeScan'];
  // TODO: Change 'next(...)' to 'return {...}' after Vue Router 4 upgrade
  if (!isScanAndGoEnabled) {
    next({ name: 'Accelerated Check Out Unavailable' });
  } else if (isAuthenticated && (isCustomerModeScan || to.meta.scanHomePage)) {
    next();
  } else if (!isAuthenticated) {
    // Not logged in
    ToastService.open(WCSimpleToast, {
      props: {
        variant: 'warning',
        title: 'Authentication',
        message: "You'll need to login first for Accelerated Check Out",
      },
      timeout: 7000,
    });
    next({ name: 'Login', query: { redirectTo: to.fullPath, scan: 'true' } });
  } else {
    next({ name: 'NotFound' });
  }
}
